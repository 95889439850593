import React, { createContext, useContext, useState } from "react";

const DataContext = createContext();

export const ProvisioningDataProvider = ({ children }) => {
  const [ProvisioningData, setProvisioningData] = useState();

  return (
    <DataContext.Provider value={{ ProvisioningData, setProvisioningData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useProvisioningData = () => useContext(DataContext);
