import React, { useState, useEffect } from "react";
import api from "../utils/useAxios";
import "../App.css";
import {
  Button,
  Table,
  Space,
  Form,
  Spin,
  message,
  Select,
  Modal,
  Typography,
  Popconfirm,
  DatePicker,
  Popover,
  Divider,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { useAuthContext } from "../auth/AuthContext";
import { useLocation } from "react-router-dom";
import { labels } from "../static/labels";
import StorageIcon from "@mui/icons-material/Storage";
import CloudIcon from "@mui/icons-material/Cloud";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import useAuthorized from "../auth/useAuthorized";
import dayjs from "dayjs";
import "dayjs/locale/de";
import locale from "antd/es/date-picker/locale/de_DE";
import ReportIcon from "@mui/icons-material/Report";
import WarningIcon from "@mui/icons-material/Warning";
import { CheckCircle, InfoOutlined } from "@mui/icons-material";
import SyncIcon from "@mui/icons-material/Sync";
const { Text, Title } = Typography;

const ActiveDirectoryCheck = () => {
  const [rows, setRows] = useState([]);
  const [company, setCompany] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [retrievingData, setRetrievingData] = useState(false);
  const { currentTenant } = useAuthContext();
  const [companies, setCompanies] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scope = searchParams.get("scope");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubTitle, setModalSubTitle] = useState("");
  const [customerQuantity, setCustomerQuantity] = useState(0);
  const [errorQuantity, setErrorQuantity] = useState(0);

  const determineErrors = (data) => {
    const totalQuantity = data.length;
    const errors = data.filter((item) => {
      if (!item.ad_data) {
        return true;
      } else if (item.ad_data.disabled_and_included_users.length > 0) {
        return true;
      } else if (item.ad_data.deprecated_and_included_users.length > 0) {
        return true;
      } else if (item.ad_data.assigned_to_both_groups.length > 0) {
        return true;
      } else if (item.ad_data.assigned_to_no_group.length > 0) {
        return true;
      }
      return false;
    }).length;
    setCustomerQuantity(totalQuantity);
    setErrorQuantity(errors);
  };

  const getData = async () => {
    setRetrievingData(true);
    try {
      const response = await api.get("customers/data/active-directory", {
        params: {
          msp: company.own_msp.id,
          date: selectedDate.format("YYYY-MM-DD"),
        },
      });
      determineErrors(response.data);
      setRows(response.data);
    } catch (error) {
      console.error(error);
      messageApi.error("Fehler beim Laden der Daten");
    }
    setRetrievingData(false);
  };

  useEffect(() => {
    if (currentTenant) {
      if (scope == "customers") {
        setCompany(currentTenant.own_company);
      } else if (scope == "msps") {
        const options = currentTenant?.managed_msps;
        setCompanies(options);
      }
    }
  }, [currentTenant, scope]);

  useEffect(() => {
    if (company && selectedDate) {
      getData();
    }
  }, [company, selectedDate]);

  const modalColumns = [
    {
      title: "Benutzer",
      dataIndex: "value",
    },
  ];

  const showModalData = (value, title, subTitle) => {
    const data = value.map((item) => {
      return {
        key: item,
        value: item,
        label: item,
        title: item,
      };
    });
    setModalSubTitle(subTitle);
    setModalTitle(title);
    setModalData(data);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData([]);
    setModalTitle("");
    setModalSubTitle("");
  };

  const columns = [
    {
      title: "Kunde",
      dataIndex: "company",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.company?.localeCompare(b.company),
    },
    {
      title: "Domäne",
      dataIndex: ["ad_data", "domain"],
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.domain?.localeCompare(b.domain),
      render: (value) => {
        return value ? (
          value
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>
              <span style={{ color: "red" }}>Unbekannt</span>
            </strong>
            <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
          </div>
        );
      },
    },
    {
      title: "Quelle",
      dataIndex: ["ad_data", "source"],
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.ad_data?.source.localeCompare(b.ad_data?.source),
      render: (value) => {
        return value ? (
          value
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>
              <span style={{ color: "red" }}>Unbekannt</span>
            </strong>
            <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
          </div>
        );
      },
    },
    {
      title: "Abzurechnende Benutzer",
      dataIndex: ["ad_data", "billable_users"],
      sorter: (a, b) => a.contract_quantity - b.contract_quantity,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return value ? (
          value
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>
              <span style={{ color: "red" }}>Unbekannt</span>
            </strong>
            <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
          </div>
        );
      },
    },
    {
      title: "Inkludiert und deaktiviert",
      dataIndex: ["ad_data", "disabled_and_included_users"],
      render: (value) => {
        return value ? (
          value?.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <strong>
                <a
                  onClick={() =>
                    showModalData(
                      value,
                      "Deaktivierte Benutzer in 'IT.Laeuft. - abrechenbar'",
                      "Deaktivierte Benutzer, die der 'IT.Laeuft. - abrechenbar' Gruppe zugewiesen sind"
                    )
                  }
                  style={{ color: "red" }}
                >{`${value?.length} Benutzer`}</a>
              </strong>
              <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "green", // Apply green color to the text
              }}
            >
              {`${value?.length} Benutzer`}
              <CheckCircle style={{ color: "green", marginLeft: "8px" }} />
            </div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>
              <span style={{ color: "red" }}>Unbekannt</span>
            </strong>
            <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
          </div>
        );
      },
    },
    {
      title: "Inkludiert und veraltet",
      dataIndex: ["ad_data", "deprecated_and_included_users"],
      render: (value) => {
        return value ? (
          value?.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <strong>
                <a
                  onClick={() =>
                    showModalData(
                      value,
                      "Veraltete Benutzer in 'IT.Laeuft. - abrechenbar'",
                      "Benutzer, die der 'IT.Laeuft. - abrechenbar' Gruppe zugewiesen sind und sich seit mehr als 90 Tagen nicht angemeldet haben"
                    )
                  }
                  style={{ color: "orange" }}
                >{`${value?.length} Benutzer`}</a>
              </strong>
              <WarningIcon style={{ color: "orange", marginLeft: "8px" }} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "green", // Apply green color to the text
              }}
            >
              {`${value?.length} Benutzer`}
              <CheckCircle style={{ color: "green", marginLeft: "8px" }} />
            </div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>
              <span style={{ color: "red" }}>Unbekannt</span>
            </strong>
            <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
          </div>
        );
      },
    },
    {
      title: "Beide Gruppen zugewiesen",
      dataIndex: ["ad_data", "assigned_to_both_groups"],
      render: (value) => {
        return value ? (
          value?.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <strong>
                <a
                  onClick={() =>
                    showModalData(
                      value,
                      "Zu beiden Gruppen zugewiesene Benutzer",
                      "Benutzer, die sowohl der 'IT.Laeuft. - abrechenbar' als auch der 'IT.Laeuft. - exkludiert' Gruppe zugewiesen sind"
                    )
                  }
                  style={{ color: "red" }}
                >{`${value?.length} Benutzer`}</a>
              </strong>
              <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "green", // Apply green color to the text
              }}
            >
              {`${value?.length} Benutzer`}
              <CheckCircle style={{ color: "green", marginLeft: "8px" }} />
            </div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>
              <span style={{ color: "red" }}>Unbekannt</span>
            </strong>
            <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
          </div>
        );
      },
    },
    {
      title: "Keine Gruppe zugewiesen",
      dataIndex: ["ad_data", "assigned_to_no_group"],
      render: (value) => {
        return value ? (
          value?.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <strong>
                <a
                  onClick={() =>
                    showModalData(
                      value,
                      "Zu keiner Gruppe zugewiesene Benutzer",
                      "Benutzer, die weder der 'IT.Laeuft. - abrechenbar' noch der 'IT.Laeuft. - exkludiert' Gruppe zugewiesen sind"
                    )
                  }
                  style={{ color: "red" }}
                >{`${value?.length} Benutzer`}</a>
              </strong>
              <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "green", // Apply green color to the text
              }}
            >
              {`${value?.length} Benutzer`}
              <CheckCircle style={{ color: "green", marginLeft: "8px" }} />
            </div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>
              <span style={{ color: "red" }}>Unbekannt</span>
            </strong>
            <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
          </div>
        );
      },
    },
    {
      title: "Aktualisiert",
      dataIndex: ["ad_data", "updated"],
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.ad_data?.updated.localeCompare(b.ad_data?.updated),
      defaultSortOrder: "ascend",
      render: (value, record) => {
        return value
          ? dayjs(value).format("DD.MM.YYYY HH:mm")
          : record.ad_data
            ? dayjs(record.ad_data.date).format("DD.MM.YYYY HH:mm")
            : "N/A";
      },
    },
  ];

  const updateData = async () => {
    setRetrievingData(true);
    try {
      const response = await api.post("contracts/check", {
        msp: company.own_msp.id,
        contractType: "ad_data",
        date: selectedDate.format("YYYY-MM-DD"),
      });
    } catch (error) {
      console.log(error);
      messageApi.error(error.response.data.message);
    }
    setRetrievingData(false);
  };

  const handleMSPChange = (key, value) => {
    setCompany(value);
  };

  const onDateChange = (date, dateString) => {
    setSelectedDate(date);
  };

  return (
    <>
      <div className="table">
        <Table
          columns={columns}
          rowKey="id"
          dataSource={rows}
          scroll={{
            y: 500,
          }}
          loading={{
            spinning: retrievingData,
            indicator: <LoadingOutlined className="spinner-style" />,
          }}
          bordered
          pagination={false}
          title={() => (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Title
                  level={3}
                  style={{
                    marginTop: -10,
                    marginBottom: 10,
                  }}
                >
                  {labels.reports[scope].activeDirectoryData.title}
                </Title>
                <Form
                  size="large"
                  style={{
                    margin: 10,
                  }}
                >
                  <Form.Item>
                    <Space direction="horizontal">
                      <Popconfirm
                        title="Daten synchronisieren?"
                        description={`Das Synchronisieren kann, je nach Anzahl der Verträge und Kunden, einige Minuten in Anspruch nehmen!`}
                        okText="Ja, jetzt synchronisieren"
                        cancelText="Abbrechen"
                        onConfirm={async () => updateData()}
                      >
                        <Button
                          type="primary"
                          icon={<SyncIcon />}
                          className="btn-primary"
                        />
                      </Popconfirm>
                      {scope !== "customers" && (
                        <Select
                          autoFocus={true}
                          showSearch
                          placeholder={
                            labels.reports[scope].contractChecks.placeholder
                              .companies
                          }
                          optionFilterProp="children"
                          onChange={handleMSPChange}
                          style={{
                            width: 320,
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={companies}
                        />
                      )}
                      <DatePicker
                        onChange={onDateChange}
                        picker="month"
                        locale={locale}
                        format={"MMMM YYYY"}
                        style={{ width: 200 }}
                        value={selectedDate}
                      />
                      {contextHolder}
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
        />
      </div>

      <Modal
        title={modalTitle}
        open={showModal}
        onCancel={closeModal}
        width={500}
        footer={[
          <Button
            key="back"
            onClick={() => closeModal()}
            className="btn-primary"
          >
            Schließen
          </Button>,
        ]}
      >
        <div style={{ padding: 10 }}>
          <p>{modalSubTitle} </p>
          <Divider />
          <Table
            columns={modalColumns}
            dataSource={modalData}
            scroll={{
              y: 400,
            }}
            bordered
            virtual
            pagination={false}
          />
        </div>
      </Modal>
    </>
  );
};

export default ActiveDirectoryCheck;
