import React from "react";
import ButtonWithModal from "./ButtonWithModal";
import { useProvisioningData } from "../store/context";
import { Table } from "antd";
import UserProvisioningProgress from "./UserProvisioningProgress";

const mapping = {
  first_name: "Vorname",
  last_name: "Nachname",
  preferred_mail: "E-Mail",
  manager: "Vorgesetzter",
  ref_employee: "Referenzmitarbeiter",
  employment_date: "Eintrittsdatum",
  manager: "Direkter Vorgesetzter",
  company: "Firma",
  department: "Abteilung",
  systems: "Systemzugriffe",
  keeper_teams: "Keeper Teams",
  m365_licenses: "M365 Lizenz(en)",
  shared_mailboxes: "Geteilte Postfächer",
  m365_groups: "M365 Gruppen",
};
const UserProvisioning = () => {
  const { ProvisioningData } = useProvisioningData();
  const inputs = Object.keys(ProvisioningData.employeeAttributes).map(
    (key, index) => {
      let input = null;
      let value = ProvisioningData.employeeAttributes[key];
      if (value === undefined) {
        return {
          key: index,
          attribute: mapping[key],
          input: "Keine Angabe",
        };
      } else {
        try {
          value = JSON.parse(value);
        } catch (error) {}
        if (key === "employment_date") {
          input = new Date(value).toLocaleDateString("de-DE", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
        } else {
          input = Array.isArray(value)
            ? Array.isArray(value)
              ? value.join("\n")
              : value
            : value;
        }
        return {
          key: index,
          attribute: mapping[key],
          input: input,
        };
      }
    }
  );
  const columns = [
    {
      title: "Attribut",
      dataIndex: "attribute",
      key: "attribute",
    },
    {
      title: "Ausgewählte Werte",
      dataIndex: "input",
      key: "input",
      render: (text) => <div style={{ whiteSpace: "pre-line" }}>{text}</div>,
    },
  ];

  return (
    <>
      {ProvisioningData.status === "pending" &&
        ProvisioningData.action === "provisioning" && (
          <ButtonWithModal
            content={<Table columns={columns} dataSource={inputs} />}
          >
            Eingliederung starten
          </ButtonWithModal>
        )}

      <UserProvisioningProgress />
    </>
  );
};

export default UserProvisioning;
