import axios from "axios";
import AuthenticationService from "../auth/AuthenticationService";

export const baseURL = "https://hub.api.einsnulleins.de/api/";

//export const baseURL = "http://localhost:8000/api/";

const api = axios.create({
  baseURL: baseURL,
  timeout: 2000000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

api.interceptors.request.use(
  async function (config) {
    await AuthenticationService.updateToken(5);
    const token = AuthenticationService.getToken();
    config.headers.Authorization = `Bearer ${token}`;
    return Promise.resolve(config);
  },
  function (error) {
    return Promise.reject(error);
  }
);

const adminAPI = axios.create({
  baseURL: "https://auth.einsnulleins.de/realms/einsnulleins-hub/",
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

adminAPI.interceptors.request.use(
  async function (config) {
    await AuthenticationService.updateToken(5);
    const token = AuthenticationService.getToken();
    config.headers.Authorization = `Bearer ${token}`;
    return Promise.resolve(config);
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { adminAPI };

export default api;
