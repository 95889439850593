import React, { useEffect } from "react";
import ButtonWithModal from "./ButtonWithModal";
import { useProvisioningData } from "../store/context";
import { Table } from "antd";
import ChangeDepartmentProgress from "./ChangeDepartmentProgresss";

const mapping = {
  first_name: "Vorname",
  last_name: "Nachname",
  email: "E-Mail",
  employer: "Firma",
  current_department: "Aktuelle Abteilung",
  future_department: "Zukünftige Abteilung",
  manager: "Vorgesetzter",
  keeper_teams: "Zuzuweisende Keeper Teams",
  shared_mailboxes: "Zuzuweisende Geteilte Postfächer",
  m365_groups: "Zuzuweisende M365 Gruppen",
  to_be_removed_m365_groups: "Zu entfernende M365 Gruppen",
  to_be_removed_keeper_teams: "Zu entfernende Keeper Teams",
  to_be_removed_shared_mailboxes: "Zu entfernende geteilte Postfächer",
  systems: "User erstellen/anfragen für",
  to_be_removed_systems: "Zu entfernende Systemzugriffe",
};
const ChangeDepartment = () => {
  const { ProvisioningData } = useProvisioningData();

  const inputs = Object.keys(ProvisioningData.employeeAttributes).map(
    (key, index) => {
      let input = null;
      let value = ProvisioningData.employeeAttributes[key];
      if (value === undefined) {
        return {
          key: index,
          attribute: mapping[key],
          input: "Keine Angabe",
        };
      } else {
        try {
          value = JSON.parse(value);
        } catch (error) {}
          input = Array.isArray(value)
            ? Array.isArray(value)
              ? value.join("\n")
              : value
            : value;
      
        return {
          key: index,
          attribute: mapping[key],
          input: input,
        };
      }
    }
  );
  const columns = [
    {
      title: "Attribut",
      dataIndex: "attribute",
      key: "attribute",
    },
    {
      title: "Ausgewählte Werte",
      dataIndex: "input",
      key: "input",
      render: (text) => <div style={{ whiteSpace: "pre-line" }}>{text}</div>,
    },
  ];

  return (
    <>
      {ProvisioningData.status === "pending" &&
        ProvisioningData.action === "changeDepartment" && (
          <ButtonWithModal
            content={<Table columns={columns} dataSource={inputs} />}
          >
            Abteilungswechsel starten
          </ButtonWithModal>
        )}

      <ChangeDepartmentProgress />
    </>
  );
};

export default ChangeDepartment;
