import "../App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import DummyPage from "../components/DummyPage";
import NewHomePage from "../pages/HomePage";
import RenderByRole from "./RenderByRole";
import TestPage from "../pages/TestPage";
import DepartmentsPage from "../pages/DepartmentsPage";
import SystemEntitiesPage from "../pages/SystemEntitiesPage";
import CompanyLicensesPage from "../pages/CompanyLicensesPage";
import AccountLicensesPage from "../pages/AccountLicensesPage";
import SoftwareAccountsPage from "../pages/SoftwareAccountsPage";
import PowerBIDashboardsPage from "../pages/PowerBIDashboardsPage";
import PowerBIAdmin from "../components/PowerBIAdmin";
import ContractCheckPage from "../pages/ContractCheckPage";
import SAPReports from "../pages/SAPReports";
import KeeperMSPLicensesPage from "../pages/KeeperMSPLicensesPage";
import LogsUserAdministration from "../pages/LogsUserAdministration";
import ProvisionUserPage from "../pages/ProvisionUserPage";
import ChangeDepartmentPage from "../pages/ChangeDepartmentPage";
import DeprovisionUserPage from "../pages/DeprovisionUserPage";
import ConnectionsPage from "../pages/ConnectionsPage";
import ActiveDirectoryPage from "../pages/ActiveDirectoryPage";
import DeviceReportsPage from "../pages/DeviceReportsPage";
import ActiveDirectoryCheckPage from "../pages/ActiveDirectoryCheckPage";
import ActiveDirectoryData from "../components/ActiveDirectoryData";

const MyRoutes = () => {
  return (
    <Routes>
      {/* ------------------------------ HOME -------------------------------- */}
      <Route index path="/" element={<NewHomePage />} />
      <Route element={<RenderByRole authorizedRoles={["hub-admin"]} />}>
        <Route path="/test" element={<TestPage />} />
      </Route>
      <Route
        element={
          <RenderByRole
            authorizedRoles={[
              "customersData.Read",
              "customersData.ReadWrite",
              "mspsData.Read",
              "mspsData.ReadWrite",
              "ownDashboards.Read",
              "ownData.Read",
              "ownData.ReadWrite",
            ]}
          />
        }
      >
        <Route path="/connections" element={<ConnectionsPage />} />
        <Route path="/ad-data/connections" element={<ActiveDirectoryPage />} />
        <Route path="departments" element={<DepartmentsPage />} />
        <Route path="system-entities" element={<SystemEntitiesPage />} />
        <Route
          element={<RenderByRole authorizedRoles={["ownDashboards.Read"]} />}
        >
          <Route
            path="powerbi/dashboards"
            element={<PowerBIDashboardsPage />}
          />
        </Route>
      </Route>
      <Route element={<RenderByRole authorizedRoles={["licenseData.Read"]} />}>
        <Route path="licenses/fees" element={<CompanyLicensesPage />} />
        <Route path="licenses/accounts" element={<AccountLicensesPage />} />
        <Route path="licenses/software" element={<SoftwareAccountsPage />} />
      </Route>

      <Route
        element={<RenderByRole authorizedRoles={["controllingData.Read"]} />}
      >
        <Route path="controlling/sap/reports" element={<SAPReports />} />
        <Route
          element={
            <RenderByRole authorizedRoles={["controllingPowerBI.Read"]} />
          }
        >
          <Route
            path="controlling/powerbi/dashboards"
            element={<PowerBIDashboardsPage />}
          />
        </Route>
        <Route
          element={
            <RenderByRole authorizedRoles={["controllingPowerBI.ReadWrite"]} />
          }
        >
          <Route
            path="controlling/powerbi/manage-reports"
            element={<PowerBIAdmin />}
          />
        </Route>
      </Route>

      <Route
        element={<RenderByRole authorizedRoles={["customersReports.Read"]} />}
      >
        <Route
          path="customers/reports/contracts"
          element={<ContractCheckPage />}
        />

        <Route
          path="customers/reports/devices"
          element={<DeviceReportsPage />}
        />
        <Route
          path="customers/reports/active-directory-data"
          element={<ActiveDirectoryPage />}
        />
      </Route>
      <Route
        element={
          <RenderByRole authorizedRoles={["mspsAdministration.ReadWrite"]} />
        }
      >
        <Route
          path="/msps/employees/provision"
          element={<ProvisionUserPage />}
        />
        <Route
          path="/msps/employees/change-department"
          element={<ChangeDepartmentPage />}
        />
        <Route
          path="/msps/employees/deprovision"
          element={<DeprovisionUserPage />}
        />
      </Route>
      <Route element={<RenderByRole authorizedRoles={["keeperData.Read"]} />}>
        <Route
          path="keeper/msp/customers"
          element={<KeeperMSPLicensesPage />}
        />
      </Route>

      <Route
        element={
          <RenderByRole authorizedRoles={["userAdministrationLogs.Read"]} />
        }
      >
        <Route
          path="/logs/useradministration"
          element={<LogsUserAdministration />}
        />
      </Route>

      <Route path="*" element={<DummyPage />} />
    </Routes>
  );
};

export default MyRoutes;
