import { useEffect, useState } from "react";
import { useAuthContext } from "./AuthContext";

const useAuthorized = (authorizedPermissions) => {
  const { currentAccount } = useAuthContext();
  const userPermissions = currentAccount?.roles;
  const [authorizationState, setAuthorizationState] = useState(false);

  useEffect(() => {
    const determineAuthorization = () => {
      if (userPermissions) {
        let authorized = userPermissions.some((userPermission) =>
          authorizedPermissions.includes(userPermission)
        );
        setAuthorizationState(authorized);
      }
    };

    determineAuthorization();
  }, [userPermissions]);

  return authorizationState;
};

export default useAuthorized;
