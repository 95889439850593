import "../App.css";
import axiosInstance from "../utils/useAxios";
import React, { useEffect, useState } from "react";
import { useProvisioningData } from "../store/context";
import {
  Form,
  Input,
  Typography,
  Col,
  Row,
  DatePicker,
  Button,
  Radio,
  Select,
} from "antd";
const { Title } = Typography;

const ChangeDepartmentUserForm = () => {
  const { ProvisioningData, setProvisioningData } = useProvisioningData();
  const [formOptions, setFormOptions] = useState([]);
  const [userOptions, setUserOptions] = useState("");
  const [form] = Form.useForm();

  const getInitialValues = () => {
    let initialValues = {};
    if (ProvisioningData.employeeAttributes) {
      let user_options = ProvisioningData.employeeAttributes.user_options;
      const current_department =
        ProvisioningData.employeeAttributes.current_department;
      const future_department =
        ProvisioningData.employeeAttributes.future_department;
      const azubi_change = ProvisioningData.employeeAttributes.azubi_change;
      if (ProvisioningData.employeeAttributes.employer === "einsnulleins") {
        if (azubi_change === true && future_department.includes("Synaxon")) {
          user_options = "changeOnly";
        } else if (
          azubi_change === false &&
          future_department.includes("Synaxon")
        ) {
          user_options = "deprovision_einsnulleins";
        } else {
          user_options = "changeOnly";
        }
      } else {
        if (current_department.includes("Synaxon")) {
          user_options = "provision_einsnulleins";
        } else if (future_department.includes("Synaxon")) {
          user_options = "deprovision_einsnulleins";
        }
      }
      setUserOptions(user_options);
      initialValues = {
        first_name: ProvisioningData.employeeAttributes.first_name,
        last_name: ProvisioningData.employeeAttributes.last_name,
        email: ProvisioningData.employeeAttributes.email,
        employer: ProvisioningData.employeeAttributes.employer,
        user_options: user_options,
        current_department:
          ProvisioningData.employeeAttributes.current_department,
        future_department:
          ProvisioningData.employeeAttributes.future_department,
        manager: ProvisioningData.employeeAttributes.manager,
        systems: ProvisioningData.employeeAttributes.systems,
        keeper_teams: ProvisioningData.employeeAttributes.keeper_teams,
        m365_licenses: ProvisioningData.employeeAttributes.m365_licenses,
        shared_mailboxes: ProvisioningData.employeeAttributes.shared_mailboxes,
        m365_groups: ProvisioningData.employeeAttributes.m365_groups,
        to_be_removed_systems:
          ProvisioningData.employeeAttributes.to_be_removed_systems,
        to_be_removed_keeper_teams:
          ProvisioningData.employeeAttributes.to_be_removed_keeper_teams,
        to_be_removed_shared_mailboxes:
          ProvisioningData.employeeAttributes.to_be_removed_shared_mailboxes,
        to_be_removed_m365_groups:
          ProvisioningData.employeeAttributes.to_be_removed_m365_groups,
      };
      form.setFieldsValue(initialValues);
    }
    return initialValues;
  };

  const onFormFinish = (values) => {
    try {
 
      setProvisioningData({
        ...ProvisioningData,
        employeeAttributes: values,
        step: 3,
        status: "pending",
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getFormOptions = async () => {
    const response = await axiosInstance.get("formoptions", {
      params: {
        company: "einsnulleins Schoß Holte-Stukenbrock",
        formType: "changeDepartment",
      },
    });
    let options = {
      user_options: [
        {
          value: "provision_einsnulleins",
          label: "Neuen Benutzer bei einsnulleins anlegen",
        },
        {
          value: "deprovision_einsnulleins",
          label: "Benutzer bei einsnulleins permanent löschen",
        },
        {
          value: "changeOnly",
          label: "Nur Zuweisungen ändern",
        },
      ],
    };
    let data = await response.data;
    data = { ...data, ...options };
    console.log("data", data);
    setFormOptions(data);
  };

  useEffect(() => {
    form.resetFields();
    getFormOptions();
    getInitialValues();
  }, []);

  return (
    <Form form={form} layout="horizontal" onFinish={onFormFinish}>
      <div className="form">
        <Title level={3}>Persönliche Daten</Title>
        <Row justify="center" align="top">
          <Col span={12} style={{ padding: 10 }}>
            <Form.Item
              label="Vorname"
              name="first_name"
              style={{ fontWeight: "bold" }}
              autoComplete="off"
              rules={[
                { required: true, message: "Bitte gib einen Vornamen an!" },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ padding: 10 }}>
            <Form.Item
              label="Nachname"
              name="last_name"
              style={{ fontWeight: "bold" }}
              rules={[
                { required: true, message: "Bitte gib einen Nachnamen an!" },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="top">
          <Col span={12} style={{ padding: 10 }}>
            <Form.Item label="E-Mail" name="email" labelCol={{ span: 24 }}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ padding: 10 }}>
            <Form.Item
              label="Direkter Vorgesetzter"
              name="manager"
              autoComplete="off"
              style={{ fontWeight: "bold" }}
              labelCol={{ span: 24 }}
              rules={[
                { required: true, message: "Bitte gib einen Vorgesetzten an!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-evenly" align="top">
          <Col span={12} style={{ padding: 10 }}>
            <Form.Item
              name="employer"
              label="Angestellt bei"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              rules={[
                { required: true, message: "Bitte Unternehmen angeben!" },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                disabled={true}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.companies}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ padding: 10 }}>
            <Form.Item
              name="user_options"
              label="Vorgehen für den Benutzer"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              rules={[{ required: true, message: "Bitte Vorgehen angeben!" }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) => {
                  setUserOptions(value);
                }}
                options={formOptions?.user_options}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-evenly" align="top">
          <Col span={12} style={{ padding: 10 }}>
            <Form.Item
              label="Aktuelle Abteilung"
              name="current_department"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              rules={[
                {
                  required: true,
                  message: "Bitte aktuelle Abteilung angeben!",
                },
              ]}
            >
              <Select
                showSearch
                disabled={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.departments}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ padding: 10 }}>
            <Form.Item
              label="Zukünftige Abteilung"
              name="future_department"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              rules={[
                {
                  required: true,
                  message: "Bitte zukünftige Abteilung angeben!",
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                disabled={true}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.systems}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="form">
        <Title level={3}>Zu erteilende Zugriffe</Title>

        <Row justify="center" align="top">
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="Keeper Teams"
              name="keeper_teams"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              
            >
              <Select
                mode="multiple"
                showSearch
                disabled={userOptions === "deprovision_einsnulleins"}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.keeper_teams}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="Systemzugriffe"
              name="systems"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              
            >
              <Select
                mode="multiple"
                showSearch
                disabled={userOptions === "deprovision_einsnulleins"}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.systems}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="Freigegebene Postfächer"
              name="shared_mailboxes"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
            
            >
              <Select
                mode="multiple"
                showSearch
                disabled={userOptions === "deprovision_einsnulleins"}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.shared_mailboxes}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="top">
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="M365 Gruppen"
              name="m365_groups"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
             
            >
              <Select
                mode="multiple"
                showSearch
                disabled={userOptions === "deprovision_einsnulleins"}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.m365_groups}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: 10 }}></Col>
          <Col span={8} style={{ padding: 10 }}></Col>
        </Row>
      </div>
      <div className="form">
        <Title level={3}>Zu entfernende Zugriffe</Title>

        <Row justify="center" align="top">
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="Keeper Teams"
              name="to_be_removed_keeper_teams"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              
            >
              <Select
                mode="multiple"
                showSearch
                disabled={userOptions === "provision_einsnulleins"}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.keeper_teams}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="Systemzugriffe"
              name="to_be_removed_systems"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
             
            >
              <Select
                mode="multiple"
                showSearch
                disabled={userOptions === "provision_einsnulleins"}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.systems}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="Freigegebene Postfächer"
              name="to_be_removed_shared_mailboxes"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
             
            >
              <Select
                mode="multiple"
                showSearch
                disabled={userOptions === "provision_einsnulleins"}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.shared_mailboxes}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="top">
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="M365 Gruppen"
              name="to_be_removed_m365_groups"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              
            
            >
              <Select
                mode="multiple"
                showSearch
                disabled={userOptions === "provision_einsnulleins"}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.m365_groups}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: 10 }}></Col>
          <Col span={8} style={{ padding: 10 }}></Col>
        </Row>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-primary"
          size="large"
        >
          Weiter
        </Button>
      </div>
    </Form>
  );
};

export default ChangeDepartmentUserForm;
