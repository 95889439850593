export const paths = {
  data: {
    m365Groups: {
      msps: "/system-entities?type=m365_group&scope=msps",
      customers: "/system-entities?type=m365_group&scope=customers",
      own: "/system-entities?type=m365_group&scope=own",
    },
    sharedMailboxes: {
      msps: "/system-entities?type=shared_mailbox&scope=msps",
      customers: "/system-entities?type=shared_mailbox&scope=customers",
      own: "/system-entities?type=shared_mailbox&scope=own",
    },
    keeperTeams: {
      msps: "/system-entities?type=keeper_team&scope=msps",
      customers: "/system-entities?type=keeper_team&scope=customers",
      own: "/system-entities?type=keeper_team&scope=own",
    },
    departments: {
      msps: "/departments?scope=msps",
      customers: "/departments?scope=customers",
      own: "/departments?scope=own",
    },
    employees: {
      customers: "employees/overview?scope=customers",
      msps: "employees/overview?scope=msps",
      own: "employees/overview?scope=own",
    },
    
  },
  overviews: {
    customers: "/customers/overview",
    msps: "/msps/overview",
    keeperMSPCustomers: "/keeper/msp/customers/overview",
  },
  reports: {
    customers: {
      contracts: "customers/reports/contracts?scope=customers",
      activeDirectoryData: "customers/reports/active-directory-data?scope=customers",
      devices: "customers/reports/devices?scope=customers",
    },
    msps: {
      contracts: "customers/reports/contracts?scope=msps",
      activeDirectoryData: "customers/reports/active-directory-data?scope=msps",
      devices: "customers/reports/devices?scope=msps",
    },
  },
  administration: {
    customers: "/customers/administration",
    msps: "/msps/administration",
    connections: {
      customers: "/connections?scope=customers",
      msps: "/connections?scope=msps",
    },
    adData: {
      customers: "ad-data/connections?scope=customers",
      msps: "ad-data/connections?scope=msps",
    },
    provision: {
      customers: {
        customer: "/customers/provision",
        employees: "/customers/employees/provision",
      },
      msps: {
        msp: "/msps/provision",
        employees: "/msps/employees/provision",
      },
    },
    deprovision: {
      customers: {
        customer: "/customers/deprovision",
        employees: "/customers/employees/deprovision",
      },
      msps: {
        msp: "/msps/deprovision",
        employees: "/msps/employees/deprovision",
      },
    },
    changeDepartment: {
      customers: "/customers/employees/change-department",
      msps: "/msps/employees/change-department",
    },
  },
  licenses: {
    msps: {
      fees: "licenses/fees?scope=msps",
      employeeLicenses: "licenses/accounts?scope=msps",
      softwareAccounts: "/licenses/software?scope=msps",
    },
    own: {
      fees: "licenses/fees?scope=own",
      employeeLicenses: "licenses/accounts?scope=own",
      softwareAccounts: "/licenses/software?scope=own",
    },
  },
  keeperMSP: {
    msps: {
      overview:
        "/keeper/msp/customers/overview?type=keeperMSPCustomers&scope=msps",
      provision: "/keeper/msp/customers/provision?scope=msps",
      deprovision: "/keeper/msp/customers/deprovision?scope=msps",
      customers: "/keeper/msp/customers?scope=msps",
    },
    own: {
      overview:
        "/keeper/msp/customers/overview?type=keeperMSPCustomers&scope=own",
      provision: "/keeper/msp/customers/provision?scope=own",
      deprovision: "/keeper/msp/customers/deprovision?scope=own",
      customers: "/keeper/msp/customers?scope=own",
    },
  },
  sales: {
    deals: "/sales/deals",
    visitreports: {
      create: "/sales/visitreports/create",
      overview: "/sales/visitreports/overview",
    },

    tickets: {
      create: "/sales/tickets/create",
      open: "/sales/tickets/overview",
    },
    customers: "/sales/customers",
    calculator: "/sales/calculator",
  },
  logs: {
    activity: "/logs/activity",
    administration: "logs/useradministration",
    backgroundjobs: "/logs/backgroundjobs",
  },
  controlling: {
    msps: {
      powerBI: {
        dashboards: "/controlling/powerbi/dashboards",
        manageReports: "/controlling/powerbi/manage-reports",
      },
      contracts: {
        itlaeuftReports: "/controlling/contracts/check?scope=msps&contractType=itlaeuft",
        legacyReports: "/controlling/contracts/check?scope=msps&contractType=legacy",
        keeperReports: "/controlling/contracts/check?scope=msps&contractType=keeper",
      },
      sapReports: "/controlling/sap/reports",
    },
    own: {
      contracts: {
        itlaeuftReports: "/controlling/contracts/check?scope=own&contractType=itlaeuft",
        legacyReports: "/controlling/contracts/check?scope=own&contractType=legacy",
        keeperReports: "/controlling/contracts/check?scope=own&contractType=keeper",
      },
      powerBI: {
        dashboards: "/powerbi/dashboards",
      },
    },
  },
};
