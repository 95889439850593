import React, { useRef, useEffect, useState } from "react";
import "../App.css";
import {
  Button,
  Space,
  Form,
  Spin,
  Table,
  Select,
  Typography,
  Modal,
  Input,
  message,
  Popconfirm,
  Divider,
  Transfer,
  Pagination,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import api from "../utils/useAxios";
import AddIcon from "@mui/icons-material/Add";
import { useAuthContext } from "../auth/AuthContext";
import { useLocation } from "react-router-dom";
import { labels } from "../static/labels";
import useAuthorized from "../auth/useAuthorized";
const { Title } = Typography;

const SystemEntities = () => {
  const [retrievingData, setRetrievingData] = useState(false);
  const [company, setCompany] = useState("");
  const [retrievingTransferData, setRetrievingTransferData] = useState(false);
  const [companies, setCompanies] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [rows, setRows] = useState([]);
  const [systemEntities, setSystemEntities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openPatchModal, setOpenPatchModal] = useState(false);
  const [tobePatched, setTobePatched] = useState({ id: null, name: null });
  const [form] = Form.useForm();
  const [patchForm] = Form.useForm();
  const [companyForm] = Form.useForm();
  const { currentTenant } = useAuthContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scope = searchParams.get("scope");
  const systemEntityType = searchParams.get("type");
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [componentLabels, setComponentLabels] = useState(
    labels.data[systemEntityType]
  );
  const canWrite = useAuthorized([
    "customersData.ReadWrite",
    "mspsData.ReadWrite",
  ]);

  const getSystemEntities = async () => {
    try {
      let response = await api.get("system_entities", {
        params: {
          company: company.id,
          type: systemEntityType,
        },
      });
      let systemEntities = await response.data;
      setRows(systemEntities);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
  };

  const getDepartments = async () => {
    try {
      let response = await api.get("departments", {
        params: {
          company: company.id,
        },
      });
      let departments = await response.data;
      setDepartments(departments);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
  };

  const getTransferData = async (entityID) => {
    setRetrievingTransferData(true);
    try {
      let response = await api.get("system_entities/associations", {
        params: {
          entity_id: entityID,
        },
      });
      let associations = await response.data;
      setTargetKeys(associations.map((association) => association.id));
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
    setRetrievingTransferData(false);
  };

  const determineCompanies = () => {
    if (scope === "customers") {
      setCompanies(currentTenant.managed_companies);
      setCompany(null);
    } else if (scope === "msps") {
      setCompanies(
        currentTenant.managed_msps.filter(
          (msp) => msp.id !== currentTenant.own_company.id
        )
      );
      setCompany(null);
    } else {
      setCompany(currentTenant.own_company);
    }
  };

  const getData = async () => {
    if (!company) {
      setRows([]);
      return;
    }
    setRetrievingData(true);
    try {
      await getDepartments();
      await getSystemEntities();
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
    setRetrievingData(false);
  };

  const openEntityModal = (entity) => {
    getTransferData(entity.id);
    setTobePatched(entity);
    setOpenPatchModal(true);
    patchForm.setFieldsValue({
      name: entity.name,
      company: entity.company.id,
      system_id: entity.system_id,
    });
  };

  const resetData = () => {
    setRows([]);
    setSystemEntities([]);
    setDepartments([]);
  };

  useEffect(() => {
    getData();
  }, [company]);

  useEffect(() => {
    if (currentTenant) {
      companyForm.resetFields();
      determineCompanies();
      resetData();
    }
  }, [scope, currentTenant]);

  useEffect(() => {
    if (currentTenant) {
      determineCompanies();
      setComponentLabels(labels.data[systemEntityType]);
      resetData();
      if (company && scope === "own") {
        getData();
      }
    }
  }, [systemEntityType, currentTenant]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.name.includes(value),
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <a onClick={() => openEntityModal(record)}>{text}</a>
      ),
    },
    {
      title: "System ID",
      dataIndex: "system_id",
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.employees.length === parseInt(value),
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.employees.length - b.employees.length,
    },
  ];

  const handleCompanyChange = (value) => {
    const selectedCompany = companies.find((company) => company.id === value);
    setCompany(selectedCompany);
  };

  const handleCreateClick = () => {
    form.resetFields();
    form.setFieldValue("company", company);
    setOpenCreateModal(true);
  };

  const handleCancel = () => {
    form.resetFields();
    patchForm.resetFields();
    setOpenCreateModal(false);
    setOpenPatchModal(false);
  };

  const onCreateFinish = async (values) => {
    const payload = {
      name: values.name,
      company_id: values.company.id,
      type: systemEntityType,
      system_id: values.system_id,
      departments: values.departments,
    };
    try {
      const result = await api.post("system_entities", payload);
      messageApi.success(result.data.message);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
    setOpenCreateModal(false);
    form.resetFields();
    setTargetKeys([]);
    getData();
  };

  const onPatchFinish = async (values) => {
    const payload = {
      entity_id: tobePatched.id,
      name: values.name,
      system_id: values.system_id,
      departments: values.departments ?? [],
    };
    try {
      const result = await api.patch("system_entities", payload);
      messageApi.success(result.data.message);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
    setOpenPatchModal(false);
    patchForm.resetFields();
    setTargetKeys([]);
    getData();
  };

  const onDelete = async () => {
    try {
      const result = await api.delete("system_entities", {
        params: {
          entity_id: tobePatched.id,
        },
      });
      messageApi.success(result.data.message);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
    setOpenPatchModal(false);
    patchForm.resetFields();
    getData();
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys);
  };
  const filterOption = (input, option) => {
    return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <div>
      {contextHolder}
      <div className="table">
        <Table
          columns={columns}
          dataSource={rows}
          loading={{
            spinning: retrievingData,
            indicator: <LoadingOutlined className="spinner-style" />,
          }}
          pagination={{
            total: rows.length || 0,
            showTotal: (total) => `${total} Objekte`,
            defaultPageSize: 10,
            defaultCurrent: 1,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          bordered
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title
                level={3}
                style={{
                  marginTop: -10,
                  marginBottom: 10,
                }}
              >
                {componentLabels[scope].title}
              </Title>
              <Form
                size="large"
                form={companyForm}
                style={{
                  margin: 10,
                }}
              >
                <Form.Item name="company">
                  <Space direction="horizontal">
                    {scope !== "own" && (
                      <Select
                        autoFocus={true}
                        showSearch
                        placeholder={componentLabels[scope].placeholder}
                        optionFilterProp="children"
                        onChange={handleCompanyChange}
                        style={{
                          width: 320,
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={companies}
                      />
                    )}

                    {/* <Button
                      className="btn-primary"
                      onClick={handleCreateClick}
                      icon={<AddIcon />}
                      disabled={!canWrite}
                    >
                      Neu erstellen
                    </Button> */}
                  </Space>
                </Form.Item>
              </Form>
            </div>
          )}
        />
      </div>
      <Modal
        title={componentLabels.formTitleCreate}
        key={componentLabels.formTitleCreate}
        open={openCreateModal}
        onCancel={handleCancel}
        width={1200}
        maskClosable={false}
        footer={
          <>
            <Button danger onClick={handleCancel}>
              Abbrechen
            </Button>
            <Button form="myForm" key="submit" htmlType="submit">
              Speichern
            </Button>
          </>
        }
      >
        <Form
          id="myForm"
          form={form}
          autoComplete="off"
          layout="vertical"
          onFinish={onCreateFinish}
        >
          <Form.Item
            label="Name"
            name="name" // Set the name attribute for the first input
            rules={[
              {
                required: true,
                message: "Bitte gib einen Namen an!",
              },
            ]}
          >
            <Input autoComplete="Off" />
          </Form.Item>
          {scope !== "own" && (
            <Form.Item
              label="Firma"
              name="company" // Set the name attribute for the second input
              rules={[
                {
                  required: true,
                  message: "Du musst ein Unternehmen angeben!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Wähle Unternehmen aus"
                optionFilterProp="children"
                value={company}
                onChange={handleCompanyChange}
                style={{
                  width: 320,
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={companies}
              />
            </Form.Item>
          )}
          <Divider />
          <Form.Item label="Abteilungen zuordnen" name="departments">
            <Transfer
              dataSource={departments}
              showSearch
              onChange={onChange}
              selectedKeys={selectedKeys}
              onSelectChange={onSelectChange}
              targetKeys={targetKeys}
              pagination={true}
              titles={["Verfügbare Abteilungen", "Zugeordnete Abteilungen"]}
              listStyle={{
                width: 1000,
                height: 300,
              }}
              filterOption={filterOption}
              style={{ width: "100%", height: 300 }}
              render={(item) => item.title}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={componentLabels.formTitlePatch}
        key={componentLabels.formTitlePatch}
        open={openPatchModal}
        onCancel={handleCancel}
        width={1200}
        maskClosable={false}
        footer={
          <>
            <Popconfirm
              title={componentLabels.confirmContent.title}
              description={`Bist du sicher, dass du ${componentLabels.confirmContent.content} ${tobePatched.name} löschen möchtest?`}
              okText="Ja, endgültig löschen"
              cancelText="Nicht löschen"
              onConfirm={() => onDelete()}
            >
              <Button type="primary" danger disabled={!canWrite}>
                Löschen
              </Button>
            </Popconfirm>

            <Button danger onClick={handleCancel}>
              Abbrechen
            </Button>
            <Button
              form="patchForm"
              key="submit"
              htmlType="submit"
              disabled={!canWrite}
            >
              Speichern
            </Button>
          </>
        }
      >
        <Spin
          size="large"
          indicator={<LoadingOutlined className="spinner-style" />}
          spinning={retrievingTransferData}
        >
          <Form
            id="patchForm"
            form={patchForm}
            autoComplete="off"
            layout="vertical"
            disabled={!canWrite}
            onFinish={onPatchFinish}
          >
            <Form.Item
              label="Name"
              name="name" // Set the name attribute for the first input
              rules={[
                {
                  required: true,
                  message: "Bitte gib einen Namen an!",
                },
              ]}
            >
              <Input autoComplete="Off" />
            </Form.Item>
            {scope !== "own" && (
              <Form.Item
                label="Firma"
                name="company" // Set the name attribute for the second input
                rules={[
                  {
                    required: true,
                    message: "Du musst ein Unternehmen angeben!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Wähle Unternehmen aus"
                  optionFilterProp="children"
                  disabled={!canWrite}
                  onChange={handleCompanyChange}
                  value={company}
                  style={{
                    width: 320,
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={companies}
                />
              </Form.Item>
            )}
            <Form.Item
              label="System ID"
              name="system_id" // Set the name attribute for the first input
            >
              <Input autoComplete="Off" disabled />
            </Form.Item>
            <Divider />
            <Form.Item label="Abteilungen zuordnen" name="departments">
              <Transfer
                dataSource={departments}
                showSearch
                onChange={onChange}
                selectedKeys={selectedKeys}
                onSelectChange={onSelectChange}
                targetKeys={targetKeys}
                pagination={true}
                titles={["Verfügbare Abteilungen", "Zugeordnete Abteilungen"]}
                listStyle={{
                  width: 1000,
                  height: 300,
                }}
                filterOption={filterOption}
                style={{ width: "100%", height: 300 }}
                render={(item) => item.title}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

export default SystemEntities;
