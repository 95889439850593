import "../App.css";
import axiosInstance from "../utils/useAxios";
import React, { useEffect, useState } from "react";
import { useProvisioningData } from "../store/context";
import {
  Form,
  Input,
  Typography,
  Col,
  Row,
  DatePicker,
  Button,
  Select,
} from "antd";
import locale from "antd/es/date-picker/locale/de_DE";
import dayjs from "dayjs";
const { Title } = Typography;

const ProvisionUserForm = () => {
  const { ProvisioningData, setProvisioningData } = useProvisioningData();
  const [formOptions, setFormOptions] = useState([]);
  const [form] = Form.useForm();

  const getInitialValues = () => {
    let initialValues = {};
    if (ProvisioningData.employeeAttributes) {
      let systems = ProvisioningData.employeeAttributes.systems.map(
        (system) => system.label || system
      );
      const employment_date = dayjs(
        ProvisioningData.employeeAttributes.employment_date
      );
      const keeperTeams =
        ProvisioningData.employeeAttributes.system_entities
          ?.filter((entity) => entity.type.name === "keeper_team")
          .map((entity) => entity.label) ||
        ProvisioningData.employeeAttributes.keeper_teams;
      const m365Groups =
        ProvisioningData.employeeAttributes.system_entities
          ?.filter((entity) => entity.type.name === "m365_group")
          .map((entity) => entity.label) ||
        ProvisioningData.employeeAttributes.m365_groups;
      const sharedMailboxes =
        ProvisioningData.employeeAttributes.system_entities
          ?.filter((entity) => entity.type.name === "shared_mailbox")
          .map((entity) => entity.label) ||
        ProvisioningData.employeeAttributes.shared_mailboxes;
      initialValues = {
        first_name: ProvisioningData.employeeAttributes.first_name,
        last_name: ProvisioningData.employeeAttributes.last_name,
        preferred_mail: ProvisioningData.employeeAttributes.preferred_mail,
        ref_employee: ProvisioningData.employeeAttributes.ref_employee,
        company: ProvisioningData.employeeAttributes.company,
        department:
          ProvisioningData.employeeAttributes.department.value ||
          ProvisioningData.employeeAttributes.department,
        employment_date: employment_date,
        manager: ProvisioningData.employeeAttributes.manager,
        systems: systems,
        keeper_teams: keeperTeams,
        m365_licenses: ProvisioningData.employeeAttributes.m365_licenses,
        shared_mailboxes: sharedMailboxes,
        m365_groups: m365Groups,
      };
      form.setFieldsValue(initialValues);
    }
    return initialValues;
  };

  const onFormFinish = (values) => {
    setProvisioningData({
      ...ProvisioningData,
      employeeAttributes: values,
      step: 3,
      status: "pending",
    });
  };

  const getFormOptions = async () => {
    const response = await axiosInstance.get("formoptions", {
      params: {
        company: ProvisioningData.employeeAttributes.company,
        formType: "provisioning",
      },
    });
    let data = await response.data;
    setFormOptions(data);
  };

  useEffect(() => {
    form.resetFields();
    getFormOptions();
    getInitialValues();
  }, []);

  return (
    <Form form={form} layout="horizontal" onFinish={onFormFinish}>
      <div className="form">
        <Title level={3}>Persönliche Daten</Title>
        <Row justify="center" align="top">
          <Col flex="auto" style={{ padding: 10 }}>
            <Form.Item
              label="Vorname"
              name="first_name"
              autoComplete="off"
              rules={[
                { required: true, message: "Bitte gib einen Vornamen an!" },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto" style={{ padding: 10 }}>
            <Form.Item
              label="Nachname"
              name="last_name"
              rules={[
                { required: true, message: "Bitte gib einen Nachnamen an!" },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto" style={{ padding: 10 }}>
            <Form.Item
              label="Wunsch-E-Mail"
              name="preferred_mail"
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="top">
          <Col flex="auto" style={{ padding: 10 }}>
            <Form.Item
              label="Direkter Vorgesetzter"
              name="manager"
              autoComplete="off"
              labelCol={{ span: 24 }}
              rules={[
                { required: true, message: "Bitte gib einen Vorgesetzten an!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto" style={{ padding: 10 }}>
            <Form.Item
              label="Referenzmitarbeiter"
              name="ref_employee"
              rules={[
                {
                  required: true,
                  message: "Bitte gib einen Referenzmitarbeiter an!",
                },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto" style={{ padding: 10 }}>
            <Form.Item
              label="Eintrittsdatum"
              name="employment_date"
              labelCol={{ span: 24 }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                fontWeight: "normal",
              }}
              rules={[
                { required: true, message: "Bitte Eintrittsdatum angeben!" },
              ]}
            >
              <DatePicker locale={locale} format="DD.MM.YYYY" />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="form">
        <Title level={3}>Zugriff</Title>
        <Row justify="space-evenly" align="top">
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              name="company"
              label="Standort"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              rules={[{ required: true, message: "Bitte Standort angeben!" }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                disabled={true}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.companies}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="Abteilung"
              name="department"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              rules={[{ required: true, message: "Bitte Abteilung angeben!" }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.departments}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="Systemzugriffe"
              name="systems"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              rules={[
                {
                  required: true,
                  message: "Bitte mindestens einen Systemzugriff angeben!",
                },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.systems}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="top">
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="Keeper Teams"
              name="keeper_teams"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              rules={[
                {
                  required: true,
                  message: "Bitte mindestens ein Keeper Team angeben!",
                },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.keeper_teams}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="M365 Lizenzen"
              name="m365_licenses"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              rules={[
                {
                  required: true,
                  message: "Bitte mindestens eine M365 Lizenz angeben!",
                },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.m365_licenses}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="Freigegebene Postfächer"
              name="shared_mailboxes"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
            >
              <Select
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.shared_mailboxes}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="top">
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="M365 Gruppen"
              name="m365_groups"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              rules={[
                {
                  required: true,
                  message: "Bitte mindestens eine M365 Gruppe angeben!",
                },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.m365_groups}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: 10 }}></Col>
          <Col span={8} style={{ padding: 10 }}></Col>
        </Row>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-primary"
          size="large"
        >
          Weiter
        </Button>
      </div>
    </Form>
  );
};

export default ProvisionUserForm;
