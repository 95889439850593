import React from "react";
import ButtonWithModal from "./ButtonWithModal";
import { useProvisioningData } from "../store/context";
import { Table } from "antd";
import UserDeprovisioningProgress from "./UserDeprovisioningProgress";

const mapping = {
  first_name: "Vorname",
  last_name: "Nachname",
  email: "E-Mail",
  termination_date: "Austrittsdatum",
  company: "Firma",
  mailBoxAccess: "Umwandlung in geteiltes Postfach",
  transferMailBoxAccessUser: "Zugriff auf Postfach übertragen an",
  deprovisioningAccounts: "Systemzugriffe",
};
const UserDeprovisioning = () => {
  const { ProvisioningData } = useProvisioningData();
  const inputs = Object.keys(ProvisioningData.employeeAttributes).map(
    (key, index) => {
      let value = ProvisioningData.employeeAttributes[key];
      if (value === undefined) {
        return {
          key: index,
          attribute: mapping[key],
          input: "Keine Angabe",
        };
      } else {
        try {
          value = JSON.parse(value);
        } catch (error) {}
        let input = null;

        if (typeof value === "boolean") {
          if (value === true) {
            input = "Ja";
          } else {
            input = "Nein";
          }
        } else if (key === "termination_date") {
          input = new Date(value).toLocaleDateString();
        } else {
          input = Array.isArray(value)
            ? Array.isArray(value)
              ? value.join("\n")
              : value
            : value;
        }

        return {
          key: index,
          attribute: mapping[key],
          input: input,
        };
      }
    }
  );
  const columns = [
    {
      title: "Attribut",
      dataIndex: "attribute",
      key: "attribute",
    },
    {
      title: "Ausgewählte Werte",
      dataIndex: "input",
      key: "input",
      render: (text) => <div style={{ whiteSpace: "pre-line" }}>{text}</div>,
    },
  ];

  return (
    <>
      {ProvisioningData.status === "pending" &&
        ProvisioningData.action === "deprovisioning" && (
          <ButtonWithModal
            content={<Table columns={columns} dataSource={inputs} />}
          >
            Ausgliederung starten
          </ButtonWithModal>
        )}

      <UserDeprovisioningProgress />
    </>
  );
};

export default UserDeprovisioning;
