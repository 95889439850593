import React from "react";
import Connections from "../components/Connections";

const ConnectionsPage = () => {
  return (
    <div>
      <Connections />
    </div>
  );
};

export default ConnectionsPage;
