import { paths } from "./paths";
import { globalStyles } from "./styles";
import React from "react";
import BusinessIcon from "@mui/icons-material/Business";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import StorageIcon from "@mui/icons-material/Storage";
import { FaGears, } from "react-icons/fa6";
import { TfiWrite } from "react-icons/tfi";
import { BsFire } from "react-icons/bs";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SummarizeIcon from "@mui/icons-material/Summarize";
import TerminalIcon from "@mui/icons-material/Terminal";
import EngineeringIcon from "@mui/icons-material/Engineering";
import GroupsIcon from "@mui/icons-material/Groups";
import EmailIcon from "@mui/icons-material/Email";
import { LuFileSearch } from "react-icons/lu";
import { MdOutlineBusinessCenter} from "react-icons/md";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CalculateIcon from "@mui/icons-material/Calculate";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DevicesIcon from '@mui/icons-material/Devices';
import { LiaFileContractSolid } from "react-icons/lia";
import {
  UserOutlined,
  AuditOutlined,
    UserAddOutlined,
  UserDeleteOutlined,
  UserSwitchOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import BarChartIcon from "@mui/icons-material/BarChart";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ConfirmationNumberOutlined from "@mui/icons-material/ConfirmationNumberOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Tooltip } from "@mui/material";
import { SiPowerbi } from "react-icons/si";
import { AiOutlineDashboard } from "react-icons/ai";

export const navObjects = [
  {
    label: "Verwaltete Kunden",
    path: "/customers",
    menuIcon: (
      <Tooltip title="Verwaltete Kunden" placement="right">
        <div>
          <MdOutlineBusinessCenter
            style={globalStyles.menu.iconstyleParentItem}
          />
        </div>
      </Tooltip>
    ),
    distributor_only: false,
    permissions: [
      "customersData.Read",
      "customersData.ReadWrite",
      "customersAdministration.ReadWrite",
    ],
    renderOnHomePage: true,
    renderInMenu: true,
    children: [
     
      {
        label: "Administration",
        path: "customers/administration",
        menuIcon: <FaGears style={globalStyles.menu.iconstyleChildItem} />,
        cardIcon: <FaGears style={globalStyles.card.iconLarge} />,
        permissions: [
            "customersConnections.Read",
            "customersAdministration.ReadWrite",
            "customersAdministration.ReadWrite"
          ],
        renderOnHomePage: true,
        renderInMenu: true,
        children: [
           {
        label: "System-Verbindungen",
        path: paths.administration.connections.customers,
        menuIcon: <LinkOutlined style={globalStyles.menu.iconstyleChildItem} />,
        permissions: 
          [
            "customersConnections.Read",
            "customersConnections.ReadWrite",
            "customersAdministration.ReadWrite",
          ],
        renderOnHomePage: true,
        renderInMenu: true,
      },
     
          {
            label: "Mitarbeiterverwaltung",
            menuIcon: (
              <ManageAccountsIcon
                style={globalStyles.menu.iconstyleChildItem}
              />
            ),
            cardIcon: (
              <ManageAccountsIcon
                style={globalStyles.menu.iconstyleChildItem}
              />
            ),
            path: "manageUsers",
            permissions: ["customersAdministration.ReadWrite"],
            renderOnHomePage: true,
            renderInMenu: true,
            children: [
              {
                label: "Mitarbeiter eingliedern",
                menuIcon: (
                  <UserAddOutlined
                    style={globalStyles.menu.iconstyleChildItem}
                  />
                ),
                cardIcon: (
                  <UserAddOutlined
                    style={globalStyles.menu.iconstyleChildItem}
                  />
                ),
                path: paths.administration.provision.customers.employees,
                permissions: ["customersAdministration.ReadWrite"],
                renderOnHomePage: true,
                renderInMenu: true,
              },
              {
                label: "Mitarbeiter ausgliedern",
                menuIcon: (
                  <UserDeleteOutlined
                    style={globalStyles.menu.iconstyleChildItem}
                  />
                ),
                cardIcon: (
                  <UserDeleteOutlined
                    style={globalStyles.menu.iconstyleChildItem}
                  />
                ),
                path: paths.administration.deprovision.customers.employees,
                permissions: ["customersAdministration.ReadWrite"],
                renderOnHomePage: true,
                renderInMenu: true,
              },
              {
                label: "Abteilungswechsel",
                menuIcon: (
                  <UserSwitchOutlined
                    style={globalStyles.menu.iconstyleChildItem}
                  />
                ),
                cardIcon: (
                  <UserSwitchOutlined
                    style={globalStyles.menu.iconstyleChildItem}
                  />
                ),
                permissions: ["customersAdministration.ReadWrite"],
                path: paths.administration.changeDepartment.customers,
                renderOnHomePage: true,
                renderInMenu: true,
              },
            ],
          },
        ],
      },
      {
        label: "Zuweisungen",
        path: "customers/data",
        menuIcon: <StorageIcon style={globalStyles.menu.iconstyleChildItem} />,
        cardIcon: <StorageIcon style={globalStyles.card.iconLarge} />,
        permissions: [
          "customersData.Read",
          "customersData.ReadWrite",
        ],
        renderOnHomePage: true,
        renderInMenu: true,
        children: [
          {
            label: "Abteilungen",
            menuIcon: (
              <BusinessIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <BusinessIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            path: paths.data.departments.customers,
                permissions: [
              "customersData.Read",
              "customersData.ReadWrite",
            ],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "M365-Gruppen",
            menuIcon: (
              <GroupsIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <GroupsIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            permissions: [
              "customersData.Read",
              "customersData.ReadWrite",
            ],
            path: paths.data.m365Groups.customers,
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Geteilte Postfächer",
            menuIcon: (
              <EmailIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <EmailIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            permissions:[
              "customersData.Read",
              "customersData.ReadWrite",
            ],
            path: paths.data.sharedMailboxes.customers,
            renderOnHomePage: true,
            renderInMenu: true,
          },

          {
            label: "Keeper-Teams",
            menuIcon: (
              <GroupsIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <GroupsIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            permissions: [
              "customersData.Read",
              "customersData.ReadWrite",
            ],
            path: paths.data.keeperTeams.customers,
            renderOnHomePage: true,
            renderInMenu: true,
          },
                   
        ],
      },
      {
        label: "Berichte",
        path: "customers/reports",
        menuIcon: <LuFileSearch style={globalStyles.menu.iconstyleParentItem} />,
        cardIcon: <BarChartIcon style={globalStyles.card.icon} />,
        permissions: ["customersReports.Read", "activeDirectoryData.ReadWrite", "activeDirectoryData.Read"],
        renderOnHomePage: true,
        renderInMenu: true,
        children: [
          {
            label: "Prüfung Kundenverträge",
            path: paths.reports.customers.contracts,
            menuIcon: <LuFileSearch style={globalStyles.menu.iconstyleChildItem} />,
            cardIcon: <BarChartIcon style={globalStyles.card.icon} />,
            permissions: ["customersReports.Read"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
           {
            label: "Prüfung Active Directories",
            path: paths.reports.customers.activeDirectoryData,
            menuIcon: <MicrosoftIcon style={globalStyles.menu.iconstyleChildItem} />,
            cardIcon: <BarChartIcon style={globalStyles.card.icon} />,
            permissions: ["customersReports.Read", "activeDirectoryData.ReadWrite", "activeDirectoryData.Read"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Prüfung Geräte",
            path: paths.reports.customers.devices,
            menuIcon: <DevicesIcon style={globalStyles.menu.iconstyleChildItem} />,
            cardIcon: <BarChartIcon style={globalStyles.card.icon} />,
            permissions: ["customersReports.Read"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
        ]
      },
      {
    label: "Keeper MSP",
    path: "customers/keepermsp",
    menuIcon: (
      <Tooltip title="Keeper MSP" placement="right">
        <div>
          <LockOutlinedIcon style={globalStyles.menu.iconstyleParentItem} />
        </div>
      </Tooltip>
    ),
    distributor_only: false,
    permissions: [
      "keeperData.Read",
      "keeperAdministration.ReadWrite"
    ],
    renderOnHomePage: true,
    renderInMenu: true,
    children: [
      {
        label: "Übersicht",
        path: paths.keeperMSP.own.customers,
        menuIcon: <ListAltIcon style={globalStyles.menu.iconstyleChildItem} />,
        cardIcon: <ListAltIcon style={globalStyles.card.icon} />,
        permissions: [
          "keeperData.Read",
        ],
        renderOnHomePage: true,
        renderInMenu: true,
      },
      {
        label: "Administration",
        menuIcon: <FaGears style={globalStyles.menu.iconstyleChildItem} />,
        cardIcon: <FaGears style={globalStyles.card.iconLarge} />,
        path: "own/keepermsp/administration",
        permissions: ["keeperAdministration.ReadWrite"],
        renderOnHomePage: true,
        renderInMenu: true,
        children: [
          {
            label: "Neuen Kunden erstellen",
            menuIcon: <AddIcon style={globalStyles.menu.iconstyleChildItem} />,
            cardIcon: <AddIcon style={globalStyles.menu.iconstyleChildItem} />,
            path: paths.keeperMSP.own.provision,
            permissions: ["keeperAdministration.ReadWrite"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Kunden ausgliedern",
            menuIcon: (
              <RemoveIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <RemoveIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            path: paths.keeperMSP.own.deprovision,
            permissions: ["keeperAdministration.ReadWrite"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
        ],
      },
    ],
  },
      
      
      
    ],

  },
  {
    label: "Franchise Standorte",
    path: "/msps",
    menuIcon: (
      <Tooltip title="Franchise-Standorte" placement="right">
        <div>
          <BusinessIcon style={globalStyles.menu.iconstyleParentItem} />
        </div>
      </Tooltip>
    ),
    distributor_only: true,
    permissions: [
      "mspsData.Read",
      "mspsData.ReadWrite",
      "mspsAdministration.ReadWrite",
    ],
    renderOnHomePage: true,
    renderInMenu: true,
    children: [
      {
        label: "Zuweisungen",
        path: "msps/data",
        menuIcon: <StorageIcon style={globalStyles.menu.iconstyleChildItem} />,
        cardIcon: <StorageIcon style={globalStyles.card.icon} />,
        permissions: [
          "mspsData.Read",
          "mspsData.ReadWrite",
        ],
        renderOnHomePage: true,
        renderInMenu: true,
        children: [
          {
            label: "Abteilungen",
            menuIcon: (
              <BusinessIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <BusinessIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            path: paths.data.departments.msps,
            permissions: [
              "mspsData.Read",
              "mspsData.ReadWrite",
            ],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "M365-Gruppen",
            menuIcon: (
              <GroupsIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <GroupsIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            permissions: [
              "mspsData.Read",
              "mspsData.ReadWrite",
            ],
            path: paths.data.m365Groups.msps,
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Geteilte Postfächer",
            menuIcon: (
              <EmailIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <EmailIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            permissions: [
              "mspsData.Read",
              "mspsData.ReadWrite",
            ],
            path: paths.data.sharedMailboxes.msps,
            renderOnHomePage: true,
            renderInMenu: true,
          },

          {
            label: "Keeper-Teams",
            menuIcon: (
              <GroupsIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <GroupsIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            permissions: [
              "mspsData.Read",
              "mspsData.ReadWrite",
            ],
            path: paths.data.keeperTeams.msps,
            renderOnHomePage: true,
            renderInMenu: true,
          },
        ],
      },
      {
        label: "Lizenzen",
        path: "msps/licenses",
        menuIcon: (
          <SummarizeIcon style={globalStyles.menu.iconstyleChildItem} />
        ),
        cardIcon: <SummarizeIcon style={globalStyles.card.icon} />,
        permissions: ["licenseData.Read"],
        renderOnHomePage: true,
        renderInMenu: true,
        children: [
          {
            label: "Kumulierte Lizenzkosten",
            menuIcon: (
              <AttachMoneyIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <AttachMoneyIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            path: paths.licenses.msps.fees,
            permissions: ["licenseData.Read"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Lizenzen pro Mitarbeiter",
            menuIcon: (
              <PersonSearchIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <PersonSearchIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            path: paths.licenses.msps.employeeLicenses,
            permissions: ["licenseData.Read"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Accounts pro Software",
            menuIcon: (
              <TerminalIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <TerminalIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            permissions: ["licenseData.Read"],
            path: paths.licenses.msps.softwareAccounts,
            renderOnHomePage: true,
            renderInMenu: true,
          },
        ],
      },
      {
    label: "Keeper MSP",
    path: "msps/keepermsp",
    menuIcon: (
      <Tooltip title="Keeper MSP" placement="right">
        <div>
          <LockOutlinedIcon style={globalStyles.menu.iconstyleParentItem} />
        </div>
      </Tooltip>
    ),
    distributor_only: false,
    permissions: [
      "keeperData.Read",
      "keeperAdministration.ReadWrite"
    ],
    renderOnHomePage: true,
    renderInMenu: true,
    children: [
      {
        label: "Übersicht",
        path: paths.keeperMSP.msps.customers,
        menuIcon: <ListAltIcon style={globalStyles.menu.iconstyleChildItem} />,
        cardIcon: <ListAltIcon style={globalStyles.card.icon} />,
        permissions: [
          "keeperData.Read",
        ],
        renderOnHomePage: true,
        renderInMenu: true,
      },
      {
        label: "Administration",
        menuIcon: <FaGears style={globalStyles.menu.iconstyleChildItem} />,
        cardIcon: <FaGears style={globalStyles.card.iconLarge} />,
        path: "msps/keepermsp/administration",
        permissions: ["keeperAdministration.ReadWrite"],
        renderOnHomePage: true,
        renderInMenu: true,
        children: [
          {
            label: "Neuen Kunden erstellen",
            menuIcon: <AddIcon style={globalStyles.menu.iconstyleChildItem} />,
            cardIcon: <AddIcon style={globalStyles.menu.iconstyleChildItem} />,
            path: paths.keeperMSP.msps.provision,
            permissions: ["keeperAdministration.ReadWrite"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Kunden ausgliedern",
            menuIcon: (
              <RemoveIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <RemoveIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            path: paths.keeperMSP.msps.deprovision,
            permissions: ["keeperAdministration.ReadWrite"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
        ],
      },
    ],
  },
    ],
  },
  {
    label: "Interne Administration",
    path: paths.administration.msps,
    menuIcon: <FaGears style={globalStyles.menu.iconstyleParentItem} />,
    cardIcon: <FaGears style={globalStyles.card.iconLarge} />,
    permissions: ["mspsAdministration.ReadWrite"],
    renderOnHomePage: true,
    renderInMenu: true,
    children: [
      {
        label: "Neuen Standort eingliedern",
        menuIcon: <AddIcon style={globalStyles.menu.iconstyleChildItem} />,
        cardIcon: <AddIcon style={globalStyles.menu.iconstyleChildItem} />,
        path: paths.administration.provision.msps.msp,
        permissions: ["mspsAdministration.ReadWrite"],
        renderOnHomePage: true,
        renderInMenu: true,
      },
      {
        label: "Standort ausgliedern",
        menuIcon: <RemoveIcon style={globalStyles.menu.iconstyleChildItem} />,
        cardIcon: <RemoveIcon style={globalStyles.menu.iconstyleChildItem} />,
        path: paths.administration.deprovision.msps.msp,
        permissions: ["mspsAdministration.ReadWrite"],
        renderOnHomePage: true,
        renderInMenu: true,
      },
      {
        label: "Mitarbeiterverwaltung",
        menuIcon: (
          <ManageAccountsIcon style={globalStyles.menu.iconstyleChildItem} />
        ),
        cardIcon: (
          <ManageAccountsIcon style={globalStyles.menu.iconstyleChildItem} />
        ),
        path: "manageMSPEmployees",
        permissions: ["mspsAdministration.ReadWrite"],
        renderOnHomePage: true,
        renderInMenu: true,
        children: [
          {
            label: "Mitarbeiter eingliedern",
            menuIcon: (
              <UserAddOutlined style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <UserAddOutlined style={globalStyles.menu.iconstyleChildItem} />
            ),
            path: paths.administration.provision.msps.employees,
            permissions: ["mspsAdministration.ReadWrite"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Mitarbeiter ausgliedern",
            menuIcon: (
              <UserDeleteOutlined
                style={globalStyles.menu.iconstyleChildItem}
              />
            ),
            cardIcon: (
              <UserDeleteOutlined
                style={globalStyles.menu.iconstyleChildItem}
              />
            ),
            path: paths.administration.deprovision.msps.employees,
            permissions: ["mspsAdministration.ReadWrite"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Abteilungswechsel",
            menuIcon: (
              <UserSwitchOutlined
                style={globalStyles.menu.iconstyleChildItem}
              />
            ),
            cardIcon: (
              <UserSwitchOutlined
                style={globalStyles.menu.iconstyleChildItem}
              />
            ),
            permissions: ["mspsAdministration.ReadWrite"],
            path: paths.administration.changeDepartment.msps,
            renderOnHomePage: true,
            renderInMenu: true,
          },
        ],
      },
    ],
  },
  {
    label: "Mein einsnulleins Standort",
    path: "/own",
    menuIcon: (
      <Tooltip title="Mein Standort" placement="right">
        <div>
          <LocationOnIcon style={globalStyles.menu.iconstyleParentItem} />
        </div>
      </Tooltip>
    ),
    distributor_only: false,
    permissions: [
      "ownData.ReadWrite",
      "ownData.Read",
    ],
    renderOnHomePage: true,
    renderInMenu: true,
    children: [
      {
        label: "Zuweisungen",
        path: "own/data",
        menuIcon: <StorageIcon style={globalStyles.menu.iconstyleChildItem} />,
        cardIcon: <StorageIcon style={globalStyles.card.icon} />,
        permissions: [
          "ownData.ReadWrite",
          "ownData.Read",
        ],
        renderOnHomePage: true,
        renderInMenu: true,
        children: [
          {
            label: "Abteilungen",
            menuIcon: (
              <BusinessIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <BusinessIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            path: paths.data.departments.own,
            permissions: [
              "ownData.ReadWrite",
              "ownData.Read",
            ],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "M365-Gruppen",
            menuIcon: (
              <GroupsIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <GroupsIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            permissions: [
              "ownData.ReadWrite",
              "ownData.Read",
            ],
            path: paths.data.m365Groups.own,
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Geteilte Postfächer",
            menuIcon: (
              <EmailIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <EmailIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            permissions: [
              "ownData.ReadWrite",
              "ownData.Read",
            ],
            path: paths.data.sharedMailboxes.own,
            renderOnHomePage: true,
            renderInMenu: true,
          },

          {
            label: "Keeper-Teams",
            menuIcon: (
              <GroupsIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <GroupsIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            permissions: [
              "ownData.ReadWrite",
              "ownData.Read",
            ],
            path: paths.data.keeperTeams.own,
            renderOnHomePage: true,
            renderInMenu: true,
          },
        ],
      },
      {
        label: "Lizenzen",
        path: "own/licenses",
        menuIcon: (
          <SummarizeIcon style={globalStyles.menu.iconstyleChildItem} />
        ),
        cardIcon: <SummarizeIcon style={globalStyles.card.icon} />,
        permissions: ["licenseData.Read"],
        renderOnHomePage: true,
        renderInMenu: true,
        children: [
          {
            label: "Kumulierte Lizenzkosten",
            menuIcon: (
              <AttachMoneyIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <AttachMoneyIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            path: paths.licenses.own.fees,
            permissions: ["licenseData.Read"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Lizenzen pro Mitarbeiter",
            menuIcon: (
              <PersonSearchIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <PersonSearchIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            path: paths.licenses.own.employeeLicenses,
            permissions: ["licenseData.Read"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Accounts pro Software",
            menuIcon: (
              <TerminalIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: (
              <TerminalIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            permissions: ["licenseData.Read"],
            path: paths.licenses.own.softwareAccounts,
            renderOnHomePage: true,
            renderInMenu: true,
          },
        ],
      },
      
      {
            label: "Dashboards",
            path: paths.controlling.own.powerBI.dashboards,
            menuIcon: (
              <BarChartIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: <BarChartIcon style={globalStyles.card.icon} />,
            permissions: ["ownDashboards.Read"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
    ],
  },
  {
    label: "Controlling",
    path: "msps/controlling",
    menuIcon: <BarChartIcon style={globalStyles.menu.iconstyleParentItem} />,
    cardIcon: <BarChartIcon style={globalStyles.card.icon} />,
    permissions: ["controllingData.Read"],
    renderOnHomePage: true,
    renderInMenu: true,
    children: [
      {
        label: "Power BI",
        path: "powerbi",
        menuIcon: <SiPowerbi style={globalStyles.menu.iconstyleChildItem} />,
        cardIcon: <AiOutlineDashboard style={globalStyles.card.icon} />,
        permissions: ["controllingPowerBI.Read", "controllingPowerBI.ReadWrite"],
        renderOnHomePage: true,
        renderInMenu: true,
        children: [
          {
            label: "Dashboards",
            path: paths.controlling.msps.powerBI.dashboards,
            menuIcon: (
              <BarChartIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: <BarChartIcon style={globalStyles.card.icon} />,
            permissions: ["controllingPowerBI.Read", "controllingPowerBI.ReadWrite"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Berichte verwalten",
            path: paths.controlling.msps.powerBI.manageReports,
            menuIcon: (
              <EngineeringIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            permissions: ["controllingPowerBI.ReadWrite"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
        ],
      },
      {
        label: "Berichte",
        path: "controlling/reports",
        menuIcon: <LuFileSearch style={globalStyles.menu.iconstyleParentItem} />,
        cardIcon: <BarChartIcon style={globalStyles.card.icon} />,
        permissions: ["customersReports.Read"],
        renderOnHomePage: true,
        renderInMenu: true,
        children: [
          {
            label: "Prüfung Kundenverträge",
            path: paths.reports.msps.contracts,
            menuIcon: <LuFileSearch style={globalStyles.menu.iconstyleChildItem} />,
            cardIcon: <BarChartIcon style={globalStyles.card.icon} />,
            permissions: ["customersReports.Read"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
           {
            label: "Prüfung Active Directories",
            path: paths.reports.msps.activeDirectoryData,
            menuIcon: <MicrosoftIcon style={globalStyles.menu.iconstyleChildItem} />,
            cardIcon: <BarChartIcon style={globalStyles.card.icon} />,
            permissions: ["customersReports.Read"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Prüfung Geräte",
            path: paths.reports.msps.devices,
            menuIcon: <DevicesIcon style={globalStyles.menu.iconstyleChildItem} />,
            cardIcon: <BarChartIcon style={globalStyles.card.icon} />,
            permissions: ["customersReports.Read"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
        ]
      },

    ],
  },
  
  {
    label: "Vertrieb",
    path: "/sales",
    menuIcon: (
      <Tooltip title="Vertrieb" placement="right">
        <div>
          <AttachMoneyIcon style={globalStyles.menu.iconstyleParentItem} />
        </div>
      </Tooltip>
    ),
    distributor_only: false,
    permissions: ["sales_data_read_write", "sales_data_read"],
    renderOnHomePage: true,
    renderInMenu: true,
    children: [
      {
        label: "Kundenakte",
        menuIcon: (
          <MdOutlineBusinessCenter
            style={globalStyles.menu.iconstyleParentItem}
          />
        ),
        cardIcon: (
          <MdOutlineBusinessCenter style={globalStyles.card.iconLarge} />
        ),
        path: paths.sales.customers,
        permissions: ["sales_data_read_write", "sales_data_read"],
        renderOnHomePage: true,
        renderInMenu: true,
      },
      {
        label: "Offene Pipedrive Deals",
        path: paths.sales.deals,
        menuIcon: <BsFire style={globalStyles.menu.iconstyleParentItem} />,
        cardIcon: <BsFire style={globalStyles.card.iconLarge} />,
        permissions: ["sales_data_read_write", "sales_data_read"],
        renderOnHomePage: true,
        renderInMenu: true,
      },
      {
        label: "Tickets",
        path: "sales/tickets",
        menuIcon: (
          <ConfirmationNumberOutlined
            style={globalStyles.menu.iconstyleParentItem}
          />
        ),
        cardIcon: (
          <ConfirmationNumberOutlined style={globalStyles.card.iconLarge} />
        ),
        permissions: ["sales_data_read_write", "sales_data_read"],
        renderOnHomePage: true,
        renderInMenu: true,
        children: [
          {
            label: "Ticket eröffnen",
            path: paths.sales.tickets.create,
            menuIcon: <AddIcon style={globalStyles.menu.iconstyleChildItem} />,
            cardIcon: <AddIcon style={globalStyles.card.iconLarge} />,
            permissions: ["sales_data_read_write", "sales_data_read"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
          {
            label: "Offene Tickets",
            path: paths.sales.tickets.open,
            menuIcon: (
              <ListAltIcon style={globalStyles.menu.iconstyleChildItem} />
            ),
            cardIcon: <ListAltIcon style={globalStyles.card.iconLarge} />,
            permissions: ["sales_data_read_write", "sales_data_read"],
            renderOnHomePage: true,
            renderInMenu: true,
          },
        ],
      },
      {
        label: "Kalkulator",
        path: paths.sales.calculator,
        menuIcon: (
          <CalculateIcon style={globalStyles.menu.iconstyleParentItem} />
        ),
        cardIcon: <CalculateIcon style={globalStyles.card.iconLarge} />,
        permissions: ["sales_data_read_write", "sales_data_read"],
        renderOnHomePage: true,
        renderInMenu: true,
      },
      {
        label: "Besuchsbericht schreiben",
        path: paths.sales.visitreports.create,
        menuIcon: <TfiWrite style={globalStyles.menu.iconstyleParentItem} />,
        cardIcon: <TfiWrite style={globalStyles.card.iconLarge} />,
        permissions: ["sales_data_read_write", "sales_data_read"],
        renderOnHomePage: true,
        renderInMenu: true,
      },
    ],
  },
  {
    label: "Protokolle",
    path: "/logs",
    menuIcon: (
      <Tooltip title="Protokolle" placement="right">
        <div>
          <AuditOutlined style={globalStyles.menu.iconstyleParentItem} />
        </div>
      </Tooltip>
    ),
    distributor_only: false,
    permissions: ["userAdministrationLogs.Read", "activityLogs.Read", "sapLogs.Read"],
    renderOnHomePage: true,
    renderInMenu: true,
    children: [
      {
        label: "Aktivitätsprotokoll",
        path: paths.logs.activity,
        menuIcon: <ListAltIcon style={globalStyles.menu.iconstyleParentItem} />,
        cardIcon: <ListAltIcon style={globalStyles.card.iconLarge} />,
        permissions: ["activityLogs.Read"],
        renderOnHomePage: true,
        renderInMenu: true,
      },
      {
        label: "Protokoll Mitarbeiterverwaltung",
        path: paths.logs.administration,
        menuIcon: (
          <ManageAccountsIcon style={globalStyles.menu.iconstyleParentItem} />
        ),
        cardIcon: <ManageAccountsIcon style={globalStyles.card.iconLarge} />,
        permissions: ["userAdministrationLogs.Read"],
        renderOnHomePage: true,
        renderInMenu: true,
      },
      {
        label: "Background-Jobs",
        path: paths.logs.backgroundjobs,
        menuIcon: (
          <AccessTimeIcon style={globalStyles.menu.iconstyleParentItem} />
        ),
        cardIcon: <AccessTimeIcon style={globalStyles.card.iconLarge} />,
        permissions: ["activityLogs.Read"],
        renderOnHomePage: true,
        renderInMenu: true,
      },
            {
        label: "SAP Berichte Franchisepartner",
        path: paths.controlling.msps.sapReports,
        menuIcon: <ListAltIcon style={globalStyles.menu.iconstyleParentItem} />,
        permissions: ["sapLogs.Read"],
        renderOnHomePage: true,
        renderInMenu: true,
      },
    ],
  },
];
