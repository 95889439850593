import React, { useState, useEffect, useRef } from "react";
import api from "../utils/useAxios";
import "../App.css";
import {
  Button,
  Table,
  Space,
  Form,
  Spin,
  message,
  Select,
  Typography,
  Popconfirm,
  Modal,
} from "antd";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import { useProvisioningData } from "../store/context";
import { paths } from "../static/paths";
const { Text, Title } = Typography;

const UserAdminReports = () => {
  const [rows, setRows] = useState([]);
  const [rowsModal, setRowsModal] = useState([]);
  const [company, setCompany] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [retrievingData, setRetrievingData] = useState(false);
  const [CSVData, setCSVData] = useState({ headers: [], data: [] });
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { setProvisioningData } = useProvisioningData();
  const [rowData, setRowData] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  const columns = [
    {
      title: "Art des Vorgangs",
      dataIndex: "operation",
      filterSearch: true,
      onFilter: (value, record) => record.operation.startsWith(value),
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.operation.localeCompare(b.operation),
    },
    {
      title: "Name des Mitarbeiters",
      dataIndex: "name",
      filterSearch: true,
      onFilter: (value, record) => record.name.startsWith(value),
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Ticketnummer",
      dataIndex: "ticketnumber",
      filterSearch: true,
      onFilter: (value, record) => record.ticketnumber.startsWith(value),
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.ticketnumber.localeCompare(b.ticketnumber),
    },
    {
      title: "Abgeschlossen",
      dataIndex: "finished",
      filterSearch: true,
      sorter: (a, b) => a.date - b.date,
      render: (text, record) =>
        record.finished ? (
          <span className="center-icon-cell">
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: "24px" }}
            />
          </span>
        ) : (
          <span className="center-icon-cell">
            <CloseCircleTwoTone
              twoToneColor="#ff0000"
              style={{ fontSize: "24px" }}
            />
          </span>
        ),
    },
    {
      title: "Fehlerfrei",
      dataIndex: "errors",
      sorter: (a, b) => a.date - b.date,
      render: (text, record) =>
        record.errors ? (
          <span className="center-icon-cell">
            <CloseCircleTwoTone
              twoToneColor="#ff0000"
              style={{ fontSize: "24px", cursor: "pointer" }}
            />
          </span>
        ) : (
          <span className="center-icon-cell">
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: "24px" }}
            />
          </span>
        ),
    },
    {
      title: "Durchgeführt von",
      dataIndex: "user",
      filterSearch: true,
      onFilter: (value, record) => record.user.startsWith(value),
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.user.localeCompare(b.user),
    },
    {
      title: "Durchgeführt am",
      dataIndex: "date",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: (text, record) => (
        <span>{new Date(record.date).toLocaleString()}</span>
      ),
    },
    {
      title: "Details",
      dataIndex: "",
      key: "action",
      render: (text, record) => (
        <span className="center-icon-cell">
          <Button
            ghost={true}
            icon={
              <InfoCircleTwoTone
                twoToneColor="#6c9aff"
                style={{ fontSize: "24px" }}
              />
            }
            onClick={() => {
              checkIfFinished(record.ticketnumber);
              setRowData(record);
              populateModalTable(record);
            }}
          />
        </span>
      ),
      width: 100,
    },
  ];

  const modalColumns = [
    {
      title: <strong>Attribut</strong>,
      dataIndex: "field",
      key: "field",
    },
    {
      title: <strong>Wert</strong>,
      dataIndex: "value",
      key: "value",
    },
  ];
  const checkIfFinished = (ticketnumber) => {
    const finished = rows.find(
      (row) =>
        row.ticketnumber === ticketnumber &&
        row.finished &&
        row.failed_tasks.length === 0 &&
        row.planned_tasks.length === 0
    );
    if (finished) {
      setFinished(true);
    } else {
      setFinished(false);
    }
  };

  const populateModalTable = (data) => {
    const paragraphStyle = {
      marginBottom: "4px",
    };
    const completedTasks =
      data.completed_tasks.length > 0
        ? data.completed_tasks.map((line) => (
            <p key={line.key} style={paragraphStyle}>
              {line.label} <CheckCircleTwoTone twoToneColor="#52c41a" />
            </p>
          ))
        : "Keine abgeschlossenen Vorgänge";
    const failedTasks =
      data.failed_tasks.length > 0
        ? data.failed_tasks.map((line) => (
            <p key={line.key} style={paragraphStyle}>
              {line.label} <CloseCircleTwoTone twoToneColor="#ff0000" />
            </p>
          ))
        : "Keine fehlgeschlagenen Vorgänge";
    const plannedTasks =
      data.planned_tasks.length > 0
        ? data.planned_tasks.map((line) => (
            <p key={line.key} style={paragraphStyle}>
              {line.label} <InfoCircleTwoTone twoToneColor="#f5d902" />
            </p>
          ))
        : "Keine ausstehenden Vorgänge";

    const keeperTeams = data.employeeAttributes.keeper_teams?.map(
      (line, index) => (
        <p key={index} style={paragraphStyle}>
          {line}
        </p>
      )
    );
    const sharedMailboxesPresent = data.employeeAttributes.shared_mailboxes;
    const sharedMailboxes =
      data.employeeAttributes.shared_mailboxes?.length > 0
        ? data.employeeAttributes.shared_mailboxes?.map((line, index) => (
            <p key={index} style={paragraphStyle}>
              {line}
            </p>
          ))
        : "Keine geteilten Postfächer zugewiesen";
    const m365Licenses = data.employeeAttributes.m365_licenses?.map(
      (line, index) => (
        <p key={index} style={paragraphStyle}>
          {line}
        </p>
      )
    );
    let elements = data.employeeAttributes.systems
      ? data.employeeAttributes.systems
      : data.employeeAttributes.deprovisioningAccounts;
    let systems = elements.map((line, index) => (
      <p key={index} style={paragraphStyle}>
        {line}
      </p>
    ));

    const company = data.employeeAttributes.company;
    const finished = data.finished ? (
      <span className="center-icon-cell">
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          style={{ fontSize: "24px" }}
        />
      </span>
    ) : (
      <span className="center-icon-cell">
        <CloseCircleTwoTone
          twoToneColor="#ff0000"
          style={{ fontSize: "24px" }}
        />
      </span>
    );
    let rows = [
      { key: "1", field: <strong>Vorgang</strong>, value: data.operation },
      {
        key: "2",
        field: <strong>Ticketnummer</strong>,
        value: data.ticketnumber,
      },
      // Inserting the new element after key "2"
      {
        key: "3",
        field: <strong>Durchgeführt am</strong>,
        value: new Date(data.date).toLocaleString(),
      },
      {
        key: "7",
        field: <strong>Durchgeführt von</strong>,
        value: data.user,
      },
      { key: "4", field: <strong>Abgeschlossen</strong>, value: finished },
      {
        key: "5",
        field: <strong>Name</strong>,
        value: `${data.employeeAttributes.first_name} ${data.employeeAttributes.last_name}`,
      },
      { key: "6", field: <strong>Standort</strong>, value: company },
      { key: "8", field: <strong>Systemzugriffe</strong>, value: systems },

      {
        key: "12",
        field: <strong>Durchgeführt</strong>,
        value: completedTasks,
      },
      {
        key: "13",
        field: <strong>Fehlgeschlagen</strong>,
        value: failedTasks,
      },
      {
        key: "14",
        field: <strong>Ausstehend</strong>,
        value: plannedTasks,
      },
    ];
    if (keeperTeams) {
      rows.splice(6, 0, {
        key: "keeper_teams",
        field: <strong>Keeper Teams</strong>,
        value: keeperTeams,
      });
    }
    if (m365Licenses) {
      rows.splice(8, 0, {
        key: "m365_licenses",
        field: <strong>M365 Lizenzen</strong>,
        value: m365Licenses,
      });
    }
    if (sharedMailboxesPresent) {
      rows.splice(9, 0, {
        key: "shared_mailboxes",
        field: <strong>Geteilte Postfächer</strong>,
        value: sharedMailboxes,
      });
    }

    setRowsModal(rows);
    setOpenModal(true);
  };

  const prepareCSVData = (data) => {
    const headers = [
      { key: "operation", label: "Art des Vorgangs" },
      { key: "ticketnumber", label: "Ticketnummer" },
      { key: "date", label: "Durchgeführt am" },
      { key: "user", label: "Durchgeführt von" },
      { key: "finished", label: "Abgeschlossen" },
      { key: "failedTasks", label: "Fehlgeschlagene Vorgänge" },
      { key: "completedTasks", label: "Durchgeführte Vorgänge" },
      { key: "plannedTasks", label: "Ausstehende Vorgänge" },
    ];

    let csvdata = data.map((obj) => ({
      operation: obj.operation,
      ticketnumber: obj.ticketnumber,
      operation: obj.operation,
      date: new Date(obj.date).toLocaleDateString(),
      user: obj.user,
      finished: obj.finished,
      failedTasks: obj.failed_tasks.map((line) => line.label).join(","),
      completedTasks: obj.completed_tasks.map((line) => line.label).join(","),
      plannedTasks: obj.planned_tasks.map((line) => line.label).join(","),
    }));
    setCSVData({ headers: headers, data: csvdata });
  };
  const navigateToAdministration = () => {
    setProvisioningData({
      ticketnumber: rowData.ticketnumber,
      action: rowData.type,
      employeeAttributes: rowData.employeeAttributes,
      plannedTasks: rowData.planned_tasks.concat(rowData.failed_tasks),
      completedTasks: rowData.completed_tasks,
      step: 3,
      status: "pending",
    });
    if (rowData.type === "provisioning") {
      navigate(paths.administration.provision.msps.employees);
    }
    if (rowData.type === "deprovisioning") {
      navigate(paths.administration.deprovision.msps.employees);
    }
    if (rowData.type === "changeDepartment") {
      navigate(paths.administration.changeDepartment.msps.employees);
    }
  };

  const getData = async () => {
    setRetrievingData(true);
    try {
      const response = await api.get("reports/useradministration");
      setRows(response.data);
      prepareCSVData(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
      message.error("Fehler beim Abrufen der Daten");
    }
    setRetrievingData(false);

    setDataFetched(true);
  };

  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 500);
  }, []);

  return (
    <>
      <div className="table">
        <Table
          columns={columns}
          dataSource={rows}
          loading={{
            spinning: retrievingData,
            indicator: <LoadingOutlined className="spinner-style" />,
          }}
          bordered
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title
                level={3}
                style={{
                  marginTop: -10,
                  marginBottom: 10,
                }}
              >
                Berichte Benutzerverwaltung
              </Title>{" "}
              <Form
                size="large"
                style={{
                  margin: 10,
                }}
              >
                <Form.Item>
                  {contextHolder}
                  <Button className="btn-primary" disabled={!dataFetched}>
                    <CSVLink
                      data={CSVData.data}
                      headers={CSVData.headers}
                      target="_blank"
                      filename={"reports_useradministration.csv"}
                    >
                      <DownloadIcon />
                    </CSVLink>
                  </Button>
                </Form.Item>
              </Form>{" "}
            </div>
          )}
          pagination={true}
        />
      </div>
      <Modal
        title={`Details zu Vorgang mit Ticketnummer ${rowData?.ticketnumber}`}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        width={700}
        confirmLoading={confirmLoading}
        footer={
          <>
            <Button danger onClick={() => setOpenModal(false)}>
              Schließen
            </Button>
            {!finished && (
              <Button onClick={navigateToAdministration}>
                {`${rowData?.operation} fortsetzen`}
              </Button>
            )}
          </>
        }
      >
        <Table columns={modalColumns} dataSource={rowsModal} bordered />
      </Modal>
    </>
  );
};

export default UserAdminReports;
