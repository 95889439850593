import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  message,
  Table,
  Typography,
  Modal,
  Popconfirm,
  Divider,
  Form,
  Space,
  Spin,
} from "antd";
import {
  CopyOutlined,
  DeleteFilled,
  DeleteOutlined,
  LinkOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import axios from "axios";
import api from "../utils/useAxios";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { labels } from "../static/labels";
import { useAuthContext } from "../auth/AuthContext";
import CreateEntraApplication from "./CreateEntraApplication";
import { render } from "@testing-library/react";
import dayjs from "dayjs";
import useAuthorized from "../auth/useAuthorized";
const { Title } = Typography;

const Connections = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [showNewConnectionModal, setShowNewconnectionModal] = useState(false);
  const [showConnectionModal, setShowConnectionModal] = useState(false);
  const { currentTenant } = useAuthContext();
  const [rows, setRows] = useState([]);
  const [retrievingData, setRetrievingData] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [form] = Form.useForm();
  const canCreateConnection = useAuthorized(["customersConnections.ReadWrite"]);

  const onRowSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const closeNewConnectionModal = () => {
    setShowNewconnectionModal(false);
    setResetKey((prevKey) => prevKey + 1);
  };
  const closeModalAndRefreshConnections = () => {
    setShowNewconnectionModal(false);
    setResetKey((prevKey) => prevKey + 1);
    getConnections();
  };

  const openNewConnectionModal = () => {
    setShowNewconnectionModal(true);
    setResetKey((prevKey) => prevKey + 1);
  };

  const openConnectionModal = (connection) => {
    getConnectionDetails(connection);
    setShowConnectionModal(true);
  };

  const closeConnectionModal = () => {
    setSelectedConnection(null);
    setShowConnectionModal(false);
  };

  const getConnectionDetails = async (connection) => {
    setRetrievingData(true);
    try {
      const response = await api.get(
        "customers/data/connection/" + connection.id
      );
      const data = await response.data;
      setSelectedConnection(data);
    } catch (error) {
      console.error("Error getting connections:", error);
    }
    setRetrievingData(false);
  };

  const getConnections = async () => {
    setRetrievingData(true);
    try {
      const response = await api.get("customers/data/connections");
      const data = response.data;
      setRows(data);
    } catch (error) {
      console.error("Error getting connections:", error);
    }
    setRetrievingData(false);
  };

  const deleteCredentials = async (credentialIDs = null) => {
    setRetrievingData(true);
    try {
      const params = credentialIDs
        ? { credentials: JSON.stringify(credentialIDs) }
        : { credentials: JSON.stringify(selectedRowKeys) };
      await api.delete("customers/data/connections", {
        params: params,
      });
      getConnections();
      messageApi.success("Verbindung(en) erfolgreich gelöscht.");
    } catch (error) {
      console.error("Error deleting credentials:", error);
      messageApi.error("Fehler beim Löschen der Verbindung(en).");
    }
    setRetrievingData(false);
  };

  const columns = [
    {
      title: "Kunde",
      dataIndex: ["company", "name"],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.company.name.includes(value),
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.company.name.localeCompare(b.company.name),
      render: (text, record) => (
        <a onClick={() => openConnectionModal(record)}>{text}</a>
      ),
    },
    {
      title: "System",
      dataIndex: ["type", "system", "name"],
    },
    {
      title: "Verbindungstyp",
      dataIndex: ["type", "display_name"],
    },
    {
      title: "Anwendungstyp",
      dataIndex: ["entra_application", "display_name"],
    },
    {
      title: "Keeper UID",
      dataIndex: "keeper_uid",
    },
    {
      title: "Gültig bis",
      dataIndex: "expires",
      render: (expires) => {
        if (expires) {
          const expiresOn = dayjs(expires); // Replace expiryDate with your actual expiry date variable
          const date = expiresOn.format("DD.MM.YYYY HH:MM"); // Format the expiry date as needed
          if (dayjs().isAfter(expiresOn)) {
            return (
              <Typography.Text type="danger">
                <strong>ABGELAUFEN!</strong>
              </Typography.Text>
            );
          } else if (expiresOn.diff(dayjs(), "day") < 30) {
            return (
              <Typography.Text style={{ color: "orange" }}>
                <strong>{date} Uhr</strong>
              </Typography.Text>
            );
          } else {
            return <Typography.Text>{date} Uhr</Typography.Text>;
          }
        }
        return "Niemals";
      },
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: onRowSelectChange,
  };

  useEffect(() => {
    getConnections();
  }, []);

  return (
    <div>
      {contextHolder}
      <div className="table">
        <Table
          columns={columns}
          rowSelection={rowSelection}
          loading={{
            spinning: retrievingData,
            indicator: <LoadingOutlined className="spinner-style" />,
          }}
          dataSource={rows}
          pagination={{
            total: rows.length || 0,
            showTotal: (total) => `${total} Objekte`,
            defaultPageSize: 10,
            defaultCurrent: 1,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          bordered
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title
                level={3}
                style={{
                  marginTop: -10,
                  marginBottom: 10,
                }}
              >
                {labels.connections.customers.title}
              </Title>
              <Form
                size="large"
                style={{
                  margin: 10,
                }}
              >
                <Form.Item>
                  <Space direction="horizontal">
                    <Button
                      className="btn-primary"
                      disabled={!canCreateConnection}
                      onClick={openNewConnectionModal}
                      icon={<LinkOutlined />}
                    >
                      Verbindung hinzufügen
                    </Button>
                    <Popconfirm
                      title="Verbindungen löschen"
                      description={`Bist du sicher, dass du die markierten Verbindungen löschen möchtest?`}
                      okText="Ja, endgültig löschen"
                      cancelText="Nicht löschen"
                      onConfirm={() => deleteCredentials()}
                    >
                      <Button
                        type="primary"
                        danger
                        disabled={selectedRowKeys.length === 0 || !canCreateConnection}
                        icon={<DeleteFilled />}
                        style={{ marginLeft: 10 }}
                      />
                    </Popconfirm>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          )}
        />
      </div>

      <Modal
        title={
          <Title
            level={3}
            style={{
              marginTop: -5,
            }}
          >
            Verbindungsdetails
          </Title>
        }
        open={showConnectionModal}
        maskClosable={true}
        onCancel={closeConnectionModal}
        footer={
          <Space>
            <Popconfirm
              title="Verbindung löschen"
              description={`Bist du sicher, dass du diese Verbindung löschen möchtest?`}
              okText="Ja, endgültig löschen"
              cancelText="Nicht löschen"
              onConfirm={() => {
                deleteCredentials([selectedConnection.id]);
                closeConnectionModal();
              }}
            >
              <Button
                type="primary"
                danger
                loading={retrievingData}
                icon={<DeleteFilled />}
                style={{ marginLeft: 10 }}
              >
                Löschen
              </Button>
            </Popconfirm>
            <Button onClick={closeConnectionModal}>Schließen</Button>
          </Space>
        }
        width="auto"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin
          spinning={retrievingData}
          className="spinner-style"
          indicator={<LoadingOutlined className="spinner-style" />}
        >
          <Divider />
          <div
            style={{
              display: "flex",
              padding: 15,
            }}
          >
            {selectedConnection ? (
              <Form
                size="large"
                form={form}
                initialValues={{
                  company: selectedConnection.company.name,
                  connectionType: selectedConnection.type?.display_name,
                  apptype: selectedConnection.entra_application.display_name,
                  system: selectedConnection.type.system.name,
                  keeper_uid: selectedConnection.keeper_uid,
                  expires: dayjs(selectedConnection.expires).format(
                    "DD.MM.YYYY HH:MM"
                  ),
                  tenantId: selectedConnection.secret.tenant_id,
                  clientId: selectedConnection.secret.client_id,
                  clientSecret: selectedConnection.secret.client_secret,
                }}
                layout="horizontal"
              >
                <Space direction="vertical" size={1}>
                  <Form.Item
                    name="company"
                    label="Unternehmen"
                    labelCol={{ style: { width: "150px" } }}
                    wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                    labelAlign="left"
                  >
                    <Input disabled style={{ width: 350 }} />
                  </Form.Item>
                  <Form.Item
                    name="connectionType"
                    label="Verbindungstyp"
                    labelCol={{ style: { width: "150px" } }}
                    wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                    labelAlign="left"
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name="apptype"
                    label="Anwendungstyp"
                    labelCol={{ style: { width: "150px" } }}
                    wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                    labelAlign="left"
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name="system"
                    label="System"
                    labelCol={{ style: { width: "150px" } }}
                    wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                    labelAlign="left"
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name="keeper_uid"
                    label="Keeper UID"
                    labelCol={{ style: { width: "150px" } }}
                    wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                    labelAlign="left"
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name="expires"
                    label="Gültig bis"
                    labelCol={{ style: { width: "150px" } }}
                    wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                    labelAlign="left"
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name="tenantId"
                    label="Tenant ID"
                    labelCol={{ style: { width: "150px" } }}
                    wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                    labelAlign="left"
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name="clientId"
                    label="Client ID"
                    labelCol={{ style: { width: "150px" } }}
                    wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                    labelAlign="left"
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name="clientSecret"
                    label="Client Secret"
                    labelCol={{ style: { width: "150px" } }}
                    wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
                    labelAlign="left"
                  >
                    <Input disabled />
                  </Form.Item>
                </Space>
              </Form>
            ) : null}
          </div>
        </Spin>
      </Modal>
      <Modal
        title={
          <Title
            level={3}
            style={{
              marginTop: -5,
            }}
          >
            Verbindung hinzufügen
          </Title>
        }
        open={showNewConnectionModal}
        maskClosable={false}
        onCancel={closeNewConnectionModal}
        footer={null}
        width="auto"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CreateEntraApplication
          key={resetKey}
          companySelectOptions={currentTenant?.managed_companies}
          closeModal={closeModalAndRefreshConnections}
        />
      </Modal>
    </div>
  );
};

export default Connections;
