import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import {
  Button,
  Table,
  Space,
  Form,
  Spin,
  message,
  Select,
  Typography,
  Input,
  Popconfirm,
  Modal,
} from "antd";
import api from "../utils/useAxios";
import AddIcon from "@mui/icons-material/Add";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
const { Text, Title } = Typography;


const SAPReportsAdmin = () => {
  const [rows, setRows] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();




  const columns = [
    {
      title: "Berichtsdatum",
      dataIndex: "report_month",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.report_month) - new Date(b.report_month),
      render: (text, record) => (
        <span>
          {new Date(record.report_month).toLocaleString("de-DE", {
            month: "long",
            year: "numeric",
          })}
        </span>
      ),
    },
    {
      title: "Durchführungsdatum",
      dataIndex: "date",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: (text, record) => (
        <span>{new Date(record.date).toLocaleString()}</span>
      ),
    },
    {
      title: "Erfolgreich",
      dataIndex: "reports_created",
      sorter: (a, b) => a.reports_created.localeCompare(b.reports_created),
      render: (text, record) =>
        record.reports_created ? (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleOutlined style={{ color: "green" }} />
          </span>
        ) : (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CloseCircleOutlined style={{ color: "red" }} />
          </span>
        ),
    },
    {
      title: "Durchgeführt von",
      dataIndex: "user",
      sorter: (a, b) => a.user.localeCompare(b.user),
    },
  ];

  useEffect(() => {
    getLogs();
  }, []);


  const getLogs = async () => {
    try {
      const response = await api.get("sap/logs");
      const data = await response.data;
      const values = data.map((row) => {
        return { ...row, key: row.id };
      });
      setRows(values);
    } catch (error) {
      console.log(error);
      messageApi.error("Fehler beim Abrufen der Daten");
    }
  };
  return (
    <div>
      {contextHolder}
      <div className="table">
        <Table
          title={() => <Title level={3}>SAP Berichte - Protokoll</Title>}
          columns={columns}
          dataSource={rows}
          bordered
        />
      </div>
    </div>
  );
};

export default SAPReportsAdmin;
