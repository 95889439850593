import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Steps,
  Tag,
  message,
  Typography,
  Divider,
  Result,
} from "antd";
import React, { useState, useEffect, form } from "react";
import api from "../utils/useAxios";
import {
  AuditOutlined,
  CheckCircleOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  CopyOutlined,
  EditOutlined,
  FileSearchOutlined,
  InfoOutlined,
  LinkOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CreateIcon from "@mui/icons-material/Create";
import { jwtDecode } from "jwt-decode";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import InfoIcon from "@mui/icons-material/Info";
import { CheckCircle } from "@mui/icons-material";
const { Title, Text } = Typography;

const CreateEntraApplication = ({ companySelectOptions, closeModal }) => {
  const [userCode, setUserCode] = useState("");
  const [deviceCode, setDeviceCode] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [accessToken, setAccessToken] = useState("");
  const [appName, setAppName] = useState("einsnulleins API");
  const [appType, setAppType] = useState(null);
  const [appTypeOptions, setAppTypeOptions] = useState(null);
  const [appCreated, setAppCreated] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [company, setCompany] = useState(null);
  const [configurationForm] = Form.useForm();
  const [appForm] = Form.useForm();
  const [delegatedUser, setDelegatedUser] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  const [appCreationError, setAppCreationError] = useState("");
  const [creatingApp, setCreatingApp] = useState(false);
  const [adminConsentUrl, setAdminConsentUrl] = useState(null);
  const [entraAppValid, setEntraAppValid] = useState(null);

  const getApplicationTypes = async () => {
    try {
      const response = await api.get("m365/entra-application");
      const data = response.data;
      setAppTypeOptions(data);
    } catch (error) {
      console.error("Error getting application types:", error);
    }
  };

  const getCodes = async () => {
    try {
      const response = await api.get("m365/devicetoken");
      const data = response.data;
      setUserCode(data.user_code);
      setDeviceCode(data.device_code);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const checkAuthorization = async () => {
    try {
      const response = await api.post("m365/device-authorized", {
        device_code: deviceCode,
      });
      if (response.data.access_token) {
        setAccessToken(response.data.access_token);
        setDelegatedUser(jwtDecode(response.data.access_token).upn);
        setTenantId(jwtDecode(response.data.access_token).tid);
        message.success("Verbindung erfolgreich hergestellt!");
      }
    } catch (error) {
      console.error("Fehler beim Prüfen der Verbindung", error);
    }
  };

  const createApplication = async () => {
    message.loading("Erstelle Verbindung...");
    try {
      const response = await api.post("m365/entra-application", {
        appName: appName,
        accessToken: accessToken,
        appType: appType,
        company: company,
        tenantId: tenantId,
      });
      const clientId = response.data.client_id;
      setAdminConsentUrl(
        `https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${clientId}&state=${appName}`
      );
      setAppCreated(true);
      setCurrentStep(3);
    } catch (error) {
      message.destroy();
      setAppCreationError(error.response.data.message);
      message.error("Fehler beim Erstellen der Anwendung!");
      setCurrentStep(3);
    }
    message.destroy();
  };

  useEffect(() => {
    if (deviceCode && !accessToken) {
      const interval = setInterval(() => {
        checkAuthorization();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [deviceCode, accessToken]);

  useEffect(() => {
    if (currentStep === 1 && !userCode) {
      getCodes();
    }
  }, [currentStep]);

  const handleSelectChange = (value) => {
    setCompany(value);
  };

  const Configuration = () => {
    const onFormFinish = async (values) => {
      const response = await api.post("credentials/check", {
        company: values.company,
        appType: values.appType,
      });
      if (response.data.exists) {
        const appTypeValue = appTypeOptions.find(
          (option) => option.value === values.appType
        ).display_name;
        const companyValue = companySelectOptions.find(
          (option) => option.value === values.company
        ).name;
        message.error(
          `Es existiert bereits eine Verbindung des Typs "${appTypeValue}" für ${companyValue}!`
        );
        return;
      }

      setAppName(values.appName);
      setAppType(values.appType);
      setCompany(values.company);
      setCurrentStep(1);
    };

    const onFormFinishFailed = (errorInfo) => {
      message.error("Bitte fülle alle Felder aus!");
    };

    return (
      <div>
        <Title
          level={4}
          style={{
            marginTop: -20,
            marginBottom: 5,
          }}
        >
          {steps[0].title}
        </Title>
        <Divider />
        <Form
          size="large"
          form={configurationForm}
          initialValues={{ appName: appName }}
          layout="horizontal"
          onFinish={onFormFinish}
          onFinishFailed={onFormFinishFailed}
        >
          <Space direction="vertical">
            <Form.Item
              name="company"
              label="Unternehmen"
              labelCol={{ style: { width: "150px" } }}
              wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Bitte wähle ein Unternehmen aus!",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Unternehmen auswählen"
                onChange={handleSelectChange}
                style={{
                  width: "100%",
                }}
                options={companySelectOptions}
              />
            </Form.Item>
            <Form.Item
              name="appType"
              label="Anwendungstyp"
              labelCol={{ style: { width: "150px" } }}
              wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Bitte wähle einen Anwendungstyp aus!",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Anwendungstyp auswählen"
                onChange={handleSelectChange}
                style={{
                  width: 500,
                }}
                options={appTypeOptions}
              />
            </Form.Item>

            <Form.Item
              name="appName"
              defaultValue="einsnulleins API"
              label="Anwendungsname"
              labelCol={{ style: { width: "150px" } }}
              wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Bitte gib einen Anwendungsnamen an!",
                },
              ]}
            >
              <Input placeholder="Anwendungsname" style={{ width: "100%" }} />
            </Form.Item>
          </Space>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button type="primary" htmlType="submit">
              Weiter
            </Button>
          </div>
        </Form>
      </div>
    );
  };

  const AdminConsent = () => {
    const [tagText, setTagText] = useState("Verbindung noch nicht getestet...");
    const [tagIcon, setTagIcon] = useState(
      <LoadingOutlined className="spinner-style" />
    );
    const [tagColor, setTagColor] = useState("info");

    const checkAdminConsent = async () => {
      message.loading("Prüfe Verbindung...");
      try {
        const response = await api.post("check-status", {
          toCheck: appType,
          company: company,
        });
        if (response.data.valid) {
          message.destroy();
          message.success("Verbindung validiert!");
          setEntraAppValid(true);
        } else {
          message.destroy();
          message.error("Validierung fehlgeschlagen! Bitte erneut versuchen");
          setEntraAppValid(false);
        }
      } catch (error) {
        console.error("Fehler beim Prüfen der Verbindung", error);
      }
    };

    const TagIcon = () => {
      if (entraAppValid === true) {
        return <CheckCircleIcon style={{ color: "green" }} />;
      } else if (entraAppValid === false) {
        return <InfoIcon style={{ color: "red" }} />;
      } else {
        return <LoadingOutlined className="spinner-style" />;
      }
    };

    useEffect(() => {
      if (entraAppValid === true) {
        setTagText("Verbindung erfolgreich getestet!");
        setTagIcon(
          <CheckCircleIcon
            style={{ color: "green", fontSize: 25, margin: 5 }}
          />
        );
        setTagColor("success");
      } else if (entraAppValid === false) {
        setTagText(
          <div>
            {" "}
            <p>
              Verbindungstest fehlgeschlagen! Es kann manchmal etwas dauern, bis
              der Test erfolgreich ist.
            </p>
            <p>
              Sollte der Test nach 5 Minuten immer noch fehlschlagen, starte den
              gesamten Vorgang erneut.
            </p>
          </div>
        );
        setTagIcon(
          <CloseCircleFilled
            style={{ color: "red", fontSize: 25, margin: 5 }}
          />
        );
        setTagColor("error");
      } else {
        setTagText(
          <div>
            {" "}
            <p style={{ color: "black" }}>Noch nicht getestet...</p>
          </div>
        );
        setTagIcon(
          <LoadingOutlined
            className="spinner-style"
            style={{ fontSize: 25, margin: 5 }}
          />
        );
        setTagColor("info");
      }
    }, [entraAppValid]);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Title
          level={4}
          style={{
            marginTop: -20,
            marginBottom: 0,
          }}
        >
          {steps[3].title}
        </Title>
        <Divider />
        <Space direction="vertical">
          <p style={{ maxWidth: 550 }}>
            Die Anwendung wurde erfolgreich im Tenant des Kunden erstellt. Bitte
            führe die unten aufgeführten Schritte durch, sodass die Anwendung
            verwendet werden kann
          </p>
          <ol style={{ maxWidth: 550, marginLeft: 0, paddingLeft: 20 }}>
            <li>
              Öffne <a href={adminConsentUrl}>diesen Link</a> in dem gleichen
              Browser, in dem du mit dem Kunden-Admin eingeloggt bist
            </li>
            <li>Stimme den Berechtigungen zu</li>
            <li>Klicke auf Verbindung testen</li>
            <li>
              Sobald die Verbindung erfolgreich getestet ist, kannst du auf
              Weiter klicken
            </li>
          </ol>
          <Tag
            icon={tagIcon}
            color={tagColor}
            style={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "normal",
              wordWrap: "break-word",
              overflow: "hidden",
              margin: 5,
            }}
          >
            {tagText}
          </Tag>
        </Space>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <Button
            className="btn-primary"
            onClick={() => checkAdminConsent()}
            style={{ marginRight: 10 }}
          >
            Verbindung testen
          </Button>
          <Button
            className="btn-primary"
            onClick={() => onStepChange(currentStep + 1)}
            disabled={entraAppValid !== true}
          >
            Weiter
          </Button>
        </div>
      </div>
    );
  };

  const Authentication = () => {
    const copyToClipboard = () => {
      navigator.clipboard.writeText(userCode).then(
        () => {
          message.success("Code kopiert!");
        },
        (err) => {
          message.error("Code konnte nicht kopiert werden!");
        }
      );
    };

    const getNewCodes = () => {
      setAccessToken("");
      getCodes();
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Title
          level={4}
          style={{
            marginTop: -20,
            marginBottom: 0,
          }}
        >
          {steps[1].title}
        </Title>
        <Divider />
        <Space direction="vertical">
          <ol style={{ maxWidth: 550, marginLeft: 0, paddingLeft: 20 }}>
            <li>
              Öffne{" "}
              <a href="https://microsoft.com/devicelogin">
                https://microsoft.com/devicelogin
              </a>{" "}
              in einem Inkognito Fenster
            </li>
            <li>Gib den unten angezeigten Authentifizierungs-Code ein</li>
            <li>
              Melde dich mit einem globalen Administrator-Konto des ausgewählten
              Kunden an.
            </li>
            <li>
              Stimme den Berechtigungen zu und setze den Haken bei "Für gesamte
              Organisation zulassen"
            </li>
            <li>
              Warte, bis die Autorisierung abgeschlossen ist und klicke auf
              Weiter
            </li>
          </ol>

          <Form.Item label="Authentifizierungs-Code" layout="inline">
            <Space.Compact style={{ width: 150, marginRight: 10 }}>
              <Input value={userCode} disabled />
              <Button icon={<CopyOutlined />} onClick={copyToClipboard} />
            </Space.Compact>
            <Button onClick={getNewCodes}>Neuen Code anfordern</Button>
          </Form.Item>
          <Form.Item label={<strong>Status</strong>} layout="inline">
            {accessToken ? (
              <Space>
                <Text>Gerät authentifiziert!</Text>
                <CheckCircleIcon style={{ color: "green" }} />
              </Space>
            ) : (
              <Space>
                <Text>Warte auf Autorisierung...</Text>
                <LoadingOutlined className="spinner-style" />
              </Space>
            )}
          </Form.Item>
        </Space>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <Button
            className="btn-primary"
            onClick={() => onStepChange(currentStep + 1)}
          >
            Weiter
          </Button>
        </div>
      </div>
    );
  };

  const CreateApplication = () => {
    const companyValue = companySelectOptions.find(
      (option) => option.value === company
    ).name;
    const appTypeValue = appTypeOptions.find(
      (option) => option.value === appType
    ).display_name;

    return (
      <div>
        <Title
          level={4}
          style={{
            marginTop: -20,
            marginBottom: 5,
          }}
        >
          {steps[2].title}
        </Title>
        <Divider />
        <Form
          size="large"
          form={appForm}
          initialValues={{
            appName: appName,
            appType: appTypeValue,
            company: companyValue,
          }}
          layout="horizontal"
          title="Konfiguration"
        >
          <Space direction="vertical">
            <Form.Item
              name="company"
              label="Unternehmen"
              labelCol={{ style: { width: "150px" } }}
              wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
              labelAlign="left"
            >
              <Space.Compact style={{ width: "100%", marginRight: 10 }}>
                <Input disabled value={companyValue} />
                <Button
                  icon={<EditOutlined />}
                  onClick={() => setCurrentStep(0)}
                />
              </Space.Compact>
            </Form.Item>
            <Form.Item
              name="appType"
              label="Anwendungstyp"
              labelCol={{ style: { width: "150px" } }}
              wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
              labelAlign="left"
            >
              <Space.Compact style={{ width: "100%", marginRight: 10 }}>
                <Input disabled value={appTypeValue} />
                <Button
                  icon={<EditOutlined />}
                  onClick={() => setCurrentStep(0)}
                />
              </Space.Compact>
            </Form.Item>

            <Form.Item
              name="appName"
              defaultValue="einsnulleins API"
              label="Anwendungsname"
              labelCol={{ style: { width: "150px" } }}
              wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
              labelAlign="left"
            >
              <Space.Compact style={{ width: "100%", marginRight: 10 }}>
                <Input disabled value={appName} />
                <Button
                  icon={<EditOutlined />}
                  onClick={() => setCurrentStep(0)}
                />
              </Space.Compact>
            </Form.Item>
            <Form.Item
              name="delegatedUser"
              label="Delegierter Benutzer"
              labelCol={{ style: { width: "150px" } }}
              wrapperCol={{ style: { width: "calc(100% - 150px)" } }}
              labelAlign="left"
            >
              <Space.Compact style={{ width: "100%" }}>
                <Input
                  disabled
                  value={delegatedUser}
                  style={{ width: "400px" }}
                />
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    setCurrentStep(1);
                  }}
                />
              </Space.Compact>
            </Form.Item>
          </Space>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button className="btn-primary" onClick={createApplication}>
              Verbindung erstellen
            </Button>
          </div>
        </Form>
      </div>
    );
  };

  const ResultStep = () => {
    const companyValue = companySelectOptions.find(
      (option) => option.value === company
    ).name;
    let subtitle = "";
    if (appCreated) {
      if (appType === "it_laeuft_application") {
        subtitle = (
          <p>
            Die Erstellung einer App-Registrierung im Microsoft-Tenant von{" "}
            {companyValue} war erfolgreich.
            <br />
            <br />
            Die Credentials wurden verschlüsselt im Keeper-Passworttresor
            gespeichert.
            <br />
            Die Daten für die Prüfung der IT.Läuft. Verträge können nun
            automatisch abgerufen werden.
            <br />
            <br />
          </p>
        );
      } else if (appType === "user_administration") {
        subtitle = (
          <p>
            Die Erstellung einer App-Registrierung im Microsoft-Tenant von{" "}
            {companyValue} war erfolgreich.
            <br />
            <br />
            Die Credentials wurden verschlüsselt im Keeper-Passworttresor
            gespeichert.
            <br />
            Die Vorraussetzungen für die automatische Benutzerverwaltung sind
            nun erfüllt.
            <br />
            Bitte wende dich an das Team Technik Franchise für das weitere
            Vorgehen.
            <br />
            <br />
          </p>
        );
      }
    } else if (appCreationError !== "") {
      subtitle = (
        <p>
          Es ist ein Fehler beim Erstellen der Anwendung aufgetreten:
          <br />
          <strong>{appCreationError}</strong>
        </p>
      );
    }
    return (
      <Result
        status={appCreated ? "success" : "error"}
        title={
          appCreated
            ? "Verbindung erfolgreich erstellt!"
            : "Fehler beim Erstellen der Verbindung!"
        }
        subTitle={subtitle}
        extra={[
          <Space direction="vertical" key="extraSpace">
            <Button
              className="btn-primary"
              onClick={closeModal}
              key="extraSpaceFinishButton"
            >
              Abschließen
            </Button>
            <Button onClick={resetProcess} key="extraSpaceResetButton">
              Weitere Verbindung hinzufügen
            </Button>
          </Space>,
        ]}
      />
    );
  };

  useEffect(() => {
    getApplicationTypes();
  }, []);

  const steps = [
    {
      title: "Konfiguration",
      content: <Configuration />,
      icon:
        appName && company && appType ? (
          <CheckCircleOutlined />
        ) : (
          <CreateIcon />
        ),
    },
    {
      title: "Autorisierung",
      content: <Authentication />,
      icon: accessToken ? <CheckCircleOutlined /> : <LinkOutlined />,
    },
    {
      title: "Überprüfen und erstellen",
      content: <CreateApplication />,
      icon: appCreated ? <CheckCircleOutlined /> : <FileSearchOutlined />,
    },
    appType === "it_laeuft_application" && {
      title: "Administrator-Einwilligung erteilen ",
      content: <AdminConsent />,
      icon: entraAppValid ? <CheckCircleOutlined /> : <FactCheckIcon />,
    },
    {
      title: "Abschluss",
      content: <ResultStep />,
      icon: appCreated ? (
        appCreationError ? (
          <CloseCircleOutlined color={{ color: "red" }} />
        ) : (
          <CheckCircleOutlined style={{ color: "green" }} />
        )
      ) : (
        <AuditOutlined />
      ),
    },
  ];

  const onStepChange = (nextStep) => {
    if (nextStep === 1 && (!appName || !company || !appType)) {
      message.error("Bitte fülle zunächst alle Felder aus!");
      return;
    }
    if (nextStep === 2 && !accessToken) {
      message.error("Bitte warte auf die Autorisierung!");
      return;
    }
    if (nextStep === 3) {
      if (appType === "it_laeuft_application" && entraAppValid !== true) {
        message.error("Bitte prüfe die Verbindung zunächst!");
        return;
      }

      if (appCreated || appCreationError !== "") {
        setCurrentStep(nextStep);
        return;
      }
      message.warning("Bitte schließe den Vorgang zunächst ab!");
      return;
    }
    if (
      appType === "it_laeuft_application" &&
      entraAppValid !== true &&
      nextStep === 4
    ) {
      message.error("Bitte prüfe die Verbindung zunächst!");
      return;
    }
    setCurrentStep(nextStep);
  };

  const resetProcess = () => {
    setCurrentStep(0);
    setAppName("einsnulleins API");
    setAppType(null);
    setCompany(null);
    setAppCreated(false);
    setAppCreationError("");
    setAccessToken("");
    setDelegatedUser(null);
    setTenantId(null);
    setDeviceCode("");
    setAdminConsentUrl(null);
    setUserCode("");
    configurationForm.resetFields();
    appForm.resetFields();
  };

  return (
    <div>
      <Divider />
      <div
        style={{
          display: "flex",
          padding: 15,
        }}
      >
        {contextHolder}
        <div style={{ flexShrink: 0 }}>
          <Steps
            direction="vertical"
            items={steps}
            current={currentStep}
            onChange={onStepChange}
            style={{ marginRight: "20px" }}
          />
        </div>
        <div
          style={{
            flexGrow: 1,
            flexDirection: "column",
          }}
        >
          {steps[currentStep].content}
        </div>
      </div>
    </div>
  );
};

export default CreateEntraApplication;
