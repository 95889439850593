import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useAuthContext } from "./auth/AuthContext";
import MainMenu from "./components/MainMenu";
import MyRoutes from "./auth/MyRoutes";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
function App() {
  const { currentTenant } = useAuthContext();
  return (
    <Router>
      <Spin
        spinning={currentTenant ? false : true}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)", // Center the spinner
        }}
        indicator={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <LoadingOutlined
              className="spinner-style"
              style={{ fontSize: 100 }}
            />
            <h2 className="spinner-style" style={{ whiteSpace: "nowrap" }}>
              Lade Daten...
            </h2>
          </div>
        }
      >
        <MainMenu>
          <MyRoutes />
        </MainMenu>
      </Spin>
    </Router>
  );
}

export default App;
