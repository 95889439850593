import "../App.css";
import axiosInstance from "../utils/useAxios";
import React, { useEffect, useState } from "react";
import { useProvisioningData } from "../store/context";
import {
  Form,
  Input,
  Typography,
  Col,
  Row,
  Select,
  Button,
  Checkbox,
  DatePicker,
} from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/de_DE";
import dayjs from "dayjs";
const { Title } = Typography;


const DeprovisionUserForm = () => {
  const { ProvisioningData, setProvisioningData } = useProvisioningData();
  const [formOptions, setFormOptions] = useState([]);
  const [transferMailBoxAccess, setTransferMailBoxAccess] = useState(
    ProvisioningData.employeeAttributes.mailBoxAccess
  );
  const [form] = Form.useForm();

  const getInitialValues = () => {
    let initialValues = {};
    if (ProvisioningData.employeeAttributes) {
      const termination_date = dayjs(
        ProvisioningData.employeeAttributes.termination_date,
        "YYYY-MM-DD"
      );
      const transferMailBoxAccess =
        ProvisioningData.employeeAttributes.mailBoxAccess;
      initialValues = {
        first_name: ProvisioningData.employeeAttributes.first_name,
        last_name: ProvisioningData.employeeAttributes.last_name,
        email: ProvisioningData.employeeAttributes.email,
        company: ProvisioningData.employeeAttributes.company,
        termination_date: termination_date,
        deprovisioningAccounts:
          ProvisioningData.employeeAttributes.deprovisioningAccounts,
        mailBoxAccess: transferMailBoxAccess,
        transferMailBoxAccessUser:
          ProvisioningData.employeeAttributes.transferMailBoxAccessUser,
      };
    }
    setTransferMailBoxAccess(transferMailBoxAccess);
    form.setFieldsValue(initialValues);
  };

  const handleMailboxChange = (e) => {
    if (transferMailBoxAccess) {
      form.setFieldsValue({ transferMailBoxAccessUser: "" });
    } else {
      form.setFieldsValue({
        transferMailBoxAccessUser:
          ProvisioningData.employeeAttributes.transferMailBoxAccessUser,
      });
    }
    setTransferMailBoxAccess(e);
  };

  const onFormFinish = (values) => {
    setProvisioningData({
      ...ProvisioningData,
      employeeAttributes: values,
      step: 3,
      status: "pending",
      ticketnumber: ProvisioningData.ticketnumber,
    });
  };

  const getFormOptions = async () => {
    const response = await axiosInstance.get("formoptions", {
      params: {
        formType: "deprovisioning",
        company: ProvisioningData.employeeAttributes.company,
      },
    });
    let data = await response.data;

    setFormOptions(data);
  };

  useEffect(() => {
    getFormOptions();
    getInitialValues();
  }, []);

  return (
    <Form layout="horizontal" onFinish={onFormFinish} form={form}>
      <div className="form">
        <Title level={3}>Benutzerausgliederung</Title>
        <Row justify="center" align="top">
          <Col flex="auto" style={{ padding: 10 }}>
            <Form.Item
              label="Vorname"
              name="first_name"
              autoComplete="off"
              rules={[
                { required: true, message: "Bitte gib den Vornamen ein!" },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto" style={{ padding: 10 }}>
            <Form.Item
              label="Nachname"
              name="last_name"
              rules={[
                { required: true, message: "Bitte gib den Nachnamen ein!" },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="top">
          <Col flex="auto" style={{ padding: 10 }}>
            <Form.Item label="E-Mail" name="email" labelCol={{ span: 24 }}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto" style={{ padding: 10 }}>
            <Form.Item
              label="Austrittsdatum"
              name="termination_date"
              labelCol={{ span: 24 }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                fontWeight: "normal",
              }}
              rules={[
                { required: true, message: "Bitte Austrittsdatum angeben!" },
              ]}
            >
              <DatePicker locale={locale} format="DD.MM.YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-evenly" align="top">
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              name="company"
              label="Standort"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              className="form-item"
            >
              <Form.Item
                name="company"
                label="Standort"
                
                labelCol={{ span: 24 }}
                style={{ fontWeight: "bold" }}
                rules={[{ required: true, message: "Bitte Standort angeben!" }]}
              >
                <Select
                  showSearch
                  disabled={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={formOptions?.companies}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: 10 }}>
            <div className="form-item">
              <Form.Item
                label="Zugriff auf Mail-Postfach"
                name="mailBoxAccess"
                labelCol={{ span: 24 }}
                style={{ fontWeight: "bold" }}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={(e) => {
                    handleMailboxChange(e.target.checked);
                  }}
                  style={{ fontWeight: "normal" }}
                >
                  Zugriff auf Mail-Postfach erteilen
                </Checkbox>
              </Form.Item>
              <Form.Item
                label="Mail-Adresse des neuen Besitzers"
                name="transferMailBoxAccessUser"
                rules={[
                  {
                    required: transferMailBoxAccess,
                    message: "Bitte gib die Mail-Adresse ein!",
                  },
                ]}
                labelCol={{ span: 24 }}
              >
                <Input
                  disabled={!transferMailBoxAccess}
                  label="Mail-Adresse des neuen Besitzers"
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={8} style={{ padding: 10 }}>
            <Form.Item
              label="Auszugliedernde Benutzerkonten"
              name="deprovisioningAccounts"
              labelCol={{ span: 24 }}
              style={{ fontWeight: "bold" }}
              className="form-item"
            >
              <Select
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={formOptions?.systems}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-primary"
          size="large"
        >
          Weiter
        </Button>
      </div>
    </Form>
  );
};

export default DeprovisionUserForm;
