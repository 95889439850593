import React, { useState, useRef } from "react";
import { Button, Modal } from "antd";
import { useProvisioningData } from "../store/context";

const ButtonWithModal = ({ content, children }) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText] = useState(content);
  const { ProvisioningData, setProvisioningData } = useProvisioningData();
  let abortFlag = useRef(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
      const updatedData = {
        ...ProvisioningData,
        status: "confirmed",
      };
      if (!abortFlag.current) {
        setProvisioningData(updatedData);
      }
    }, 2000);
  };
  const handleCancel = () => {
    abortFlag.current = true;
    setProvisioningData({
      ...ProvisioningData,
      status: "dataentry",
      step: 2,
    });
    setOpen(false);
  };
  return (
    <>
      <div autoFocus>
        <Button className="btn-primary" type="primary" onClick={showModal}>
          {children}
        </Button>
      </div>
      <Modal
        title="Überprüfe die eingegebenen Daten"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={700}
      >
        {modalText}
      </Modal>
    </>
  );
};

export default ButtonWithModal;
