export const labels = {
  data: {
    m365_group: {
      formTitleCreate: "M365-Gruppe hinzufügen",
      formTitlePatch: "M365-Gruppe bearbeiten",
      confirmContent: {
        title: "M365-Gruppe löschen",
        content: "die M365-Gruppe",
      },
      msps: {
        placeholder: "Wähle einen Franchise Standort aus",
        title: "Franchise Standorte - M365-Gruppen",
      },
      customers: {
        placeholder: "Wähle einen Kunden aus",
        title: "Verwaltete Kunden - M365-Gruppen",
      },
      own: {
        placeholder: null,
        title: "Meine M365-Gruppen",
      },
    },
    shared_mailbox: {
      formTitleCreate: "Geteiltes Postfach hinzufügen",
      formTitlePatch: "Geteiltes Postfach bearbeiten",
      confirmContent: {
        title: "Geteiltes Postfach löschen",
        content:
          "das geteilte Postfach",
      },
      msps: {
        placeholder: "Wähle einen Franchise Standort aus",
        title: "Franchise Standorte - Geteilte Postfächer",
      },
      customers: {
        placeholder: "Wähle einen Kunden aus",
        title: "Verwaltete Kunden - Geteilte Postfächer",
      },
      own: {
        placeholder: null,
        title: "Meine Geteilten Postfächer",
      },
    },
    keeper_team: {
      formTitleCreate: "Keeper Team hinzufügen",
      formTitlePatch: "Keeper Team bearbeiten",
      confirmContent: {
        title: "Keeper Team löschen",
        content: "das Keeper Team",
      },
      msps: {
        placeholder: "Wähle einen Franchise Standort aus",
        title: "Franchise Standorte - Keeper-Teams",
      },
      customers: {
        placeholder: "Wähle einen Kunden aus",
        title: "Verwaltete Kunden - Keeper-Teams",
      },
      own: {
        placeholder: null,
        title: "Meine Keeper-Teams",
      },
    },
    keeperMSPCustomers: {
      formTitleCreate: "Keeper MSP Kunden hinzufügen",
      formTitlePatch: "Keeper MSP Kunden bearbeiten",
      confirmContent: {
        title: "Keeper MSP Kunden löschen",
        content:
          "Bist du sicher, dass du diesen Keeper MSP Kunden löschen möchtest?",
      },
      msps: {
        placeholder: "Wähle einen Franchise Standort aus",
        title: "Franchise Standorte - Keeper MSP Kunden",
      },
      own: {
        placeholder: null,
        title: "Meine Keeper MSP Kunden",
      },
    },
    departments: {
      formTitleCreate: "Abteilung hinzufügen",
      formTitlePatch: "Abteilung bearbeiten",
      confirmContent: {
        title: "Abteilung löschen",
        content: "Bist du sicher, dass du diese Abteilung löschen möchtest?",
      },
      msps: {
        placeholder: "Wähle einen Franchise Standort aus",
        title: "Franchise Standorte - Abteilungen",
      },
      customers: {
        placeholder: "Wähle einen Kunden aus",
        title: "Verwaltete Kunden - Abteilungen",
      },
      own: {
        placeholder: null,
        title: "Meine Abteilungen",
      },
    },
  },
  licenses: {
    fees: {
      msps: {
        placeholder: "Wähle einen Franchise Standort aus",
        title: "Franchise Standorte - Lizenzgebühren",
      },
      own: {
        placeholder: null,
        title: "Meine Lizenzgebühren",
      },
    },
    accountLicenses: {
      msps: {
        placeholder: "Wähle einen Franchise Standort aus",
        title: "Franchise Standorte - Lizenzen pro Account",
      },
      own: {
        placeholder: null,
        title: "Lizenzen pro Account",
      },
    },
    softwareAccounts: {
      msps: {
        placeholder: "Wähle einen Franchise Standort aus",
        title: "Franchise Standorte - Accounts pro Software",
      },
      own: {
        placeholder: null,
        title: "Accounts pro Software",
      },
    },
  },
  keeperMSPCustomers: {
    overview: {
      msps: {
        placeholder: "Wähle einen Franchise Standort aus",
        title: "Keeper MSP Kunden",
      },
      own: {
        placeholder: null,
        title: "Meine Keeper MSP Kunden",
      },
    },
  },
  controlling: {
    powerBI: {
      title: "Power BI Dashboards",
      reportSelect: "Bericht auswählen",
      reportTypeSelect: "Berichtskategorie auswählen",
      dateSelect: "Berichtszeitraum auswählen",
      tableHeader: "Power BI Berichte",
    },
    msps: {
      placeholder: "Wähle einen Franchise Standort aus",
      contracts: {
        check: {
          title: "Franchise Standorte - Überprüfung Kundenverträge",
        },
      },
    },
    own: {
      placeholder: null,
      contracts: {
        check: {
          title: "Überprüfung Kundenverträge",
        },
      },
    },
  },
  connections: {
    customers: {
      title: "Verwaltete Kunden - System-Verbindungen",
    },
    msps: {
      title: "Franchise Standorte - System-Verbindungen",

    },
  },
  adData: {
    customers: {
      title: "Verwaltete Kunden - Verwaltung Active Directory Daten",
    },
    msps: {
      title: "Franchise Standorte - Verwaltung Active Directory Daten",
    },
  },
  reports: {
    customers: {
      contractChecks: {
        title: "Verwaltete Kunden - Vertragsprüfungen",
        placeholder: {
          companies: "Wähle einen Kunden aus",
          contractType: "Wähle einen Vertragstyp aus",
        }
      },
      activeDirectoryData: {
        title: "Verwaltete Kunden - Prüfung IT.Läuft. Active Directory Daten",
      },
      deviceReports: {
        title: "Verwaltete Kunden - Geräteberichte",
      },
    },
    msps: {
      contractChecks: {
        title: "Franchise-Standorte - Vertragsprüfungen",
        placeholder: {
          companies: "Wähle einen Standort aus",
          contractType: "Wähle einen Vertragstyp aus",
        }
      },
      activeDirectoryData: {
        title: "Franchise-Standorte - Prüfung IT.Läuft. Active Directory Daten",
      },
      deviceReports: {
        title: "Franchise-Standorte - Geräteberichte",
      },
    },
    own: {
      placeholder: null,
      title: "Meine Berichte",
    },
  },
};
