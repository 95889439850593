import React, { useState } from "react";
import api from "../utils/useAxios";
import { Button, Spin, message } from "antd";

const TestPage = () => {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const syncData = async (e) => {
    setLoading(true);
    try {
      let response = await api.post("test");
      let synced = await response.data;
      if (synced.success === true) {
        messageApi.open({
          type: "success",
          content: "Daten wurden erfolgreich synchronisiert",
        });
      } else {
        messageApi.open({
          type: "error",
          content: "Daten konnten nicht synchronisiert werden!",
        });
      }
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Daten konnten nicht synchronisiert werden!",
      });
    }

    setLoading(false);
  };
  return (
    <Spin spinning={loading}>
      {contextHolder}
      <Button onClick={syncData}>Sync</Button>
    </Spin>
  );
};

export default TestPage;
