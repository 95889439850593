import "../App.css";
import React, { useRef, useEffect, useState } from "react";
import { Button, Timeline } from "antd";
import axiosInstance from "../utils/useAxios";
import DangerousIcon from "@mui/icons-material/Dangerous";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useProvisioningData } from "../store/context";

const UserProvisioningProgress = () => {
  const [steps, setSteps] = useState([]);
  const [pending, setPending] = useState(null);
  const abortFlag = useRef();
  const failedTasks = useRef([]);
  const completedTasks = useRef([]);
  const plannedTasks = useRef([]);
  const plannedTasksArray = useRef([]);
  const mailboxConverted = useRef(false);
  const { ProvisioningData, setProvisioningData } = useProvisioningData();
  const [tasksPlanned, setTasksPlanned] = useState(false);

  const addNewSuccess = (newSuccessElement) => {
    plannedTasks.current = plannedTasks.current.filter(
      (task) => task.key !== newSuccessElement.key
    );
    completedTasks.current = [...completedTasks.current, newSuccessElement];
  };

  const addNewError = (newErrorElement) => {
    plannedTasks.current = plannedTasks.current.filter(
      (task) => task.key !== newErrorElement.key
    );
    failedTasks.current = [...failedTasks.current, newErrorElement];
  };

  const setNewStep = (label, content) => {
    if (content.error) {
      const newStep = {
        label: <strong>{label}</strong>,
        children: content.error,
        color: "red",
        dot: <DangerousIcon sx={{ color: "red" }} />,
      };
      setSteps((prevSteps) => [...prevSteps, newStep]);
    } else if (content.warning) {
      const newStep = {
        label: <strong>{label}</strong>,
        children: content.warning,
        color: "yellow",
        dot: <WarningIcon sx={{ color: "#ffe600" }} />,
      };
      setSteps((prevSteps) => [...prevSteps, newStep]);
    } else if (content.success_message) {
      const newStep = {
        label: <strong>{label}</strong>,
        children: content.success_message,
        color: "yellow",
        dot: <CheckCircleIcon sx={{ color: "green" }} />,
      };
      setSteps((prevSteps) => [...prevSteps, newStep]);
    }
  };

  const setPlannedTasks = () => {
    if (ProvisioningData.plannedTasks) {
      console.log(ProvisioningData.completedTasks);
      plannedTasks.current = ProvisioningData.plannedTasks;
      completedTasks.current = ProvisioningData.completedTasks;
      const shortList = ProvisioningData.plannedTasks.map((task) => task.key);
      plannedTasksArray.current = shortList;
      return true;
    }
    const convertMailbox = ProvisioningData.employeeAttributes.mailboxAccess;
    let initialPlannedTasks = [
      {
        key: "update_database",
        label: "Verknüpfungen zu Lizenzen in der Datenbank löschen",
      },
      {
        key: "confluence_user",
        label: "Confluence User deaktivieren",
      },
      {
        key: "keeper_user",
        label: "Keeper User löschen",
      },
      {
        key: "autotask_ticket",
        label: "Autotask Ticket aktualisieren",
      },
      {
        key: "email_marketing",
        label: "Benachrichtigung an Marketing & Franchisemanagement versenden",
      },
    ];
    let deprovisioningAccounts =
      ProvisioningData.employeeAttributes.deprovisioningAccounts;
    if (!Array.isArray(deprovisioningAccounts)) {
      deprovisioningAccounts = JSON.parse(deprovisioningAccounts);
    }
    if (deprovisioningAccounts.includes("Pipedrive (Vertrieb)")) {
      initialPlannedTasks.push({
        key: "pipedrive_sales_user",
        label: "Pipedrive User im Tenant Vertrieb deaktivieren",
      });
    }
    if (deprovisioningAccounts.includes("Pipedrive (Franchisemanagement)")) {
      initialPlannedTasks.push({
        key: "pipedrive_franchise_user",
        label: "Pipedrive User im Tenant Franchisemanagement deaktivieren",
      });
    }
    if (deprovisioningAccounts.includes("Google Workspace")) {
      initialPlannedTasks.push({
        key: "google_user",
        label: "Google User archivieren",
      });
    }
    if (deprovisioningAccounts.includes("SAP Business One")) {
      initialPlannedTasks.push({
        key: "sap_user",
        label: "Deaktivierung SAP User anfragen",
      });
    }
    if (deprovisioningAccounts.includes("RMM Dashboard")) {
      initialPlannedTasks.push({
        key: "rmm_user",
        label: "Deaktivierung RMM User anfragen",
      });
    }
    if (convertMailbox) {
      initialPlannedTasks.push({
        key: "convert_m365_user_mailbox",
        label: "User in geteiltes Postfach umwandeln",
      });
      initialPlannedTasks.push({
        key: "m365_licenses",
        label: "M365 Lizenzen entziehen",
      });
    }
    if (!convertMailbox) {
      initialPlannedTasks.push({
        key: "m365_user",
        label: "M365 User löschen",
      });
    }
    const company = ProvisioningData.employeeAttributes.company;
    if (company === "einsnulleins Schloß Holte-Stukenbrock") {
      initialPlannedTasks.push({
        key: "email_ITOS",
        label: "Benachrichtigung an ITOS versenden",
      });
    }
    const shortList = initialPlannedTasks.map((task) => task.key);
    plannedTasksArray.current = shortList;
    plannedTasks.current = initialPlannedTasks;
    setTasksPlanned(true);
    return true;
  };

  const saveReport = () => {
    const protocol = {
      type: "deprovisioning",
      ticketnumber: ProvisioningData.ticketnumber,
      finished: !abortFlag.current,
      employeeAttributes: ProvisioningData.employeeAttributes,
      plannedTasks: plannedTasks.current,
      completedTasks: completedTasks.current,
      failedTasks: failedTasks.current,
    };
    axiosInstance.post("reports/useradministration", { data: protocol });
  };

  const convertM365UserMailbox = async () => {
    try {
      if (
        !abortFlag.current &&
        plannedTasksArray.current.includes("convert_m365_user_mailbox")
      ) {
        setPending("Wandle M365 Benutzer in Shared Mailbox um ...");
        const data = {
          username: ProvisioningData.employeeAttributes.email,
          tenant: ProvisioningData.employeeAttributes.company,
          newOwner:
            ProvisioningData.employeeAttributes.transferMailBoxAccessUser,
        };
        let response = await axiosInstance.post(
          "m365/user/convertMailbox",
          data
        );
        let result = await response.data;
        setNewStep("M365 Benutzer in Shared Mailbox umwandeln", result);
        if (result.error) {
          addNewError({
            key: "convert_m365_user_mailbox",
            label: "M365 Benutzer in Shared Mailbox umwandeln",
          });
          return false;
        } else {
          mailboxConverted.current = true;
          addNewSuccess({
            key: "convert_m365_user_mailbox",
            label: "M365 Benutzer in Shared Mailbox umwandeln",
          });
          return true;
        }
      }
    } catch (error) {
      addNewError({
        key: "convert_m365_user_mailbox",
        label: "M365 Benutzer in Shared Mailbox umwandeln",
      });
      return false;
    }
  };
  const removeM365Licenses = async () => {
    try {
      if (
        !abortFlag.current &&
        plannedTasksArray.current.includes("m365_licenses")
      ) {
        setPending("Entferne M365 Lizenzen");
        const data = {
          username: ProvisioningData.employeeAttributes.email,
          tenant: ProvisioningData.employeeAttributes.company,
        };

        let response = await axiosInstance.post(
          "m365/user/removeLicenses",
          data
        );
        let result = await response.data;
        setNewStep("M365 Lizenzen entfernen", result);
        if (result.error) {
          addNewError({
            key: "m365_licenses",
            label: "M365 Lizenzen entfernen",
          });
          return false;
        } else {
          mailboxConverted.current = true;
          addNewSuccess({
            key: "m365_licenses",
            label: "M365 Lizenzen entfernen",
          });
          return true;
        }
      }
    } catch (error) {
      addNewError({ key: "m365_licenses", label: "M365 Lizenzen entfernen" });
      return false;
    }
  };

  const deprovisionM365User = async () => {
    if (!abortFlag.current && plannedTasksArray.current.includes("m365_user")) {
      setPending("Lösche M365 Benutzer...");
      const data = {
        username: ProvisioningData.employeeAttributes.email,
        tenant: ProvisioningData.employeeAttributes.company,
      };

      let response = await axiosInstance.delete("m365/user", {
        params: data,
      });

      let result = await response.data;
      setNewStep("M365 Benutzer löschen", result);
      if (result.error) {
        addNewError({ key: "m365_user", label: "M365 Benutzer löschen" });
        return false;
      } else {
        addNewSuccess({ key: "m365_user", label: "M365 Benutzer löschen" });
        return true;
      }
    }
  };

  const deprovisionGoogleUser = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("google_user")
    ) {
      setPending("Archiviere Google Benutzer...");
      const data = {
        username: ProvisioningData.employeeAttributes.email,
      };

      let response = await axiosInstance.delete("google/user", {
        params: data,
      });

      let result = await response.data;
      setNewStep("Google Benutzer archiviren", result);
      if (result.error) {
        addNewError({ key: "google_user", label: "Google Benutzer löschen" });
        return false;
      } else {
        addNewSuccess({
          key: "google_user",
          label: "Google User archivieren",
        });
        return true;
      }
    }
  };

  const deprovisionConfluenceUser = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("confluence_user")
    ) {
      setPending("Lösche Confluence User...");
      const data = {
        email: ProvisioningData.employeeAttributes.email,
      };

      let response = await axiosInstance.delete("confluence/user", {
        params: data,
      });
      let result = await response.data;
      setNewStep("Confluence Benutzer löschen", result);
      if (result.error) {
        addNewError({
          key: "confluence_user",
          label: "Confluence Benutzer löschen",
        });
      } else {
        addNewSuccess({
          key: "confluence_user",
          label: "Confluence Benutzer löschen",
        });
      }
      return true;
    }
  };

  const deprovisionKeeperUser = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("keeper_user")
    ) {
      setPending("Lösche Keeper User...");
      const data = {
        email: ProvisioningData.employeeAttributes.email,
      };

      let response = await axiosInstance.delete("keeper/user", {
        params: data,
      });
      let result = await response.data;
      if (result.error) {
        addNewError({ key: "keeper_user", label: "Keeper Benutzer löschen" });
      } else {
        addNewSuccess({ key: "keeper_user", label: "Keeper Benutzer löschen" });
      }
      setNewStep("Keeper User löschen", result);
      return true;
    }
  };

  const deprovisionSalesPipedriveUser = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("pipedrive_sales_user")
    ) {
      setPending("Lösche Pipedrive User im Tenant Vertrieb...");
      const data = {
        email: ProvisioningData.employeeAttributes.email,
        tenant: "sales",
      };

      let response = await axiosInstance.delete("pipedrive/user", {
        params: data,
      });
      let result = await response.data;
      setNewStep("Pipedrive User im Tenant Vertrieb löschen", result);
      if (result.error) {
        addNewError({
          key: "pipedrive_sales_user",
          label: "Pipedrive Benutzer im Tenant Vertrieb löschen",
        });
      } else {
        addNewSuccess({
          key: "pipedrive_sales_user",
          label: "Pipedrive Benutzer im Tenant Vertrieb löschen",
        });
      }
      return true;
    }
  };

  const deprovisionFranchisePipedriveUser = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("pipedrive_franchise_user")
    ) {
      setPending("Lösche Pipedrive User im Tenant Franchisemanagement...");
      const data = {
        email: ProvisioningData.employeeAttributes.email,
        tenant: "franchise",
      };

      let response = await axiosInstance.delete("pipedrive/user", {
        params: data,
      });
      let result = await response.data;
      setNewStep(
        "Pipedrive User im Tenant Franchisemanagement löschen",
        result
      );
      if (result.error) {
        addNewError({
          key: "pipedrive_franchise_user",
          label: "Pipedrive Benutzer im Tenant Franchisemanagement löschen",
        });
      } else {
        addNewSuccess({
          key: "pipedrive_franchise_user",
          label: "Pipedrive Benutzer im Tenant Franchisemanagement löschen",
        });
      }
      return true;
    }
  };

  const deprovisionSAPUser = async () => {
    if (!abortFlag.current && plannedTasksArray.current.includes("sap_user")) {
      setPending("Frage Deaktivierung von User beim SAP Support Team an...");
      const data = {
        email: ProvisioningData.employeeAttributes.email,
        company: ProvisioningData.employeeAttributes.company,
        ticketnumber: ProvisioningData.ticketnumber,
      };

      let response = await axiosInstance.delete("sap/users", {
        params: data,
      });
      let result = await response.data;
      setNewStep("Deaktivierung von SAP User beantragen", result);
      if (result.error) {
        addNewError({
          key: "sap_user",
          label: "Deaktivierung SAP User beantragen",
        });
      } else {
        addNewSuccess({
          key: "sap_user",
          label: "Deaktivierung SAP User beantragen",
        });
      }
      return true;
    }
  };

  const deprovisionRMMUser = async () => {
    if (!abortFlag.current && plannedTasksArray.current.includes("rmm_user")) {
      const data = {
        email: ProvisioningData.employeeAttributes.email,
        company: ProvisioningData.employeeAttributes.company,
      };

      let response = await axiosInstance.delete("notifications/rmm/users", {
        params: data,
      });
      let result = await response.data;
      setNewStep("Deaktivierung von RMM User anfragen", result);
      if (result.error) {
        addNewError({
          key: "rmm_user",
          label: "Deaktivierung RMM User anfragen",
        });
      } else {
        addNewSuccess({
          key: "rmm_user",
          label: "Deaktivierung RMM User anfragen",
        });
      }
      return true;
    }
  };
  const notifyMarketing = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("email_marketing")
    ) {
      setPending(
        "Versende Benachrichtigung an Marketing & Franchisemenagement..."
      );
      const name =
        ProvisioningData.employeeAttributes.first_name +
        " " +
        ProvisioningData.employeeAttributes.last_name;

      const data = {
        name: name,
        tenant: ProvisioningData.employeeAttributes.company,
        department: null,
        date: ProvisioningData.employeeAttributes.termination_date.format(
          "DD.MM.YYYY"
        ),
        ticket: ProvisioningData.ticketnumber,
        type: "deprovisioning",
      };
      let response = await axiosInstance.post("notifyMarketing", data);
      let result = await response.data;
      setNewStep(
        "Benachrichtigung an Marketing & Franchisemenagement versenden",
        result
      );

      if (result.error) {
        addNewError({
          key: "email_marketing",
          label:
            "Benachrichtigung an Marketing & Franchisemanagement versenden",
        });
      } else {
        addNewSuccess({
          key: "email_marketing",
          label:
            "Benachrichtigung an Marketing & Franchisemanagement versenden",
        });
      }
    }
  };

  const updateAutotaskTicket = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("autotask_ticket")
    ) {
      setPending("Aktualisiere Autotask Ticket...");
      let succesfulTaskKeys = completedTasks.current.map((task) => task.key);
      let succesfulTaskLabels = completedTasks.current.map(
        (task) => task.label
      );
      let failedTaskLabels = failedTasks.current.map((task) => task.label);

      const data = {
        ticketnumber: ProvisioningData.ticketnumber,
        completedTaskKeys: succesfulTaskKeys,
        completedTaskLabels: succesfulTaskLabels,
        failedTaskLabels: failedTaskLabels,
      };
      let response = await axiosInstance.patch("autotask/ticket", data);
      let result = await response.data;
      if (result.error) {
        addNewError({
          key: "autotask_ticket",
          label: "Autotask Ticket aktualisieren",
        });
      } else {
        addNewSuccess({
          key: "autotask_ticket",
          label: "Autotask Ticket aktualisieren",
        });
      }
      setNewStep("Autotask Ticket aktualisieren", result);
    }
  };

  const notifyITOS = async () => {
    const company = ProvisioningData.employeeAttributes.company;
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("email_ITOS")
    ) {
      setPending("Versende Benachrichtigung an ITOS...");
      const name =
        ProvisioningData.employeeAttributes.first_name +
        " " +
        ProvisioningData.employeeAttributes.last_name;
      const data = {
        name: name,
        email: ProvisioningData.employeeAttributes.email,
        termination_date:
          ProvisioningData.employeeAttributes.termination_date.format(
            "DD.MM.YYYY"
          ),
        ticket: ProvisioningData.ticketnumber,
      };
      let response = await axiosInstance.post("notifyITOS", data);
      let result = await response.data;
      setNewStep("Benachrichtigung an ITOS versenden", result);
      if (result.error) {
        addNewError({
          key: "email_ITOS",
          label: "Benachrichtigung an ITOS versenden",
        });
      } else {
        addNewSuccess({
          key: "email_ITOS",
          label: "Benachrichtigung an ITOS versenden",
        });
      }
    }
  };
  const updateDatabase = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("update_database") &&
      failedTasks.current.length === 0
    ) {
      setPending("Lösche Lizenz-Verknüpfungen in der Datenbank...");
      const data = {
        email: ProvisioningData.employeeAttributes.email,
      };

      let response = await axiosInstance.post("delinkAccountLicenses", data);
      let result = await response.data;
      setNewStep("Verknüpfungen zu Lizenzen löschen", result);
      if (result.error) {
        addNewError({
          key: "update_database",
          label: "Verknüpfungen zu Lizenzen in der Datenbank löschen",
        });
      } else {
        addNewSuccess({
          key: "update_database",
          label: "Verknüpfungen zu Lizenzen in der Datenbank löschen",
        });
      }
      return true;
    }
  };

  const deprovisionUser = async () => {
    setProvisioningData({
      ...ProvisioningData,
      status: "running",
    });
    setNewStep("Benutzerausgliederung starten", {
      success_message: "Benutzerausgliederung gestartet",
    });

    for (const func of [
      convertM365UserMailbox,
      removeM365Licenses,
      deprovisionM365User,
      deprovisionGoogleUser,
      deprovisionConfluenceUser,
      deprovisionKeeperUser,
      deprovisionSalesPipedriveUser,
      deprovisionFranchisePipedriveUser,
      deprovisionSAPUser,
      deprovisionRMMUser,
      updateDatabase,
      notifyMarketing,
      notifyITOS,
      updateAutotaskTicket,
    ]) {
      await func();
    }
    setPending(null);

    if (abortFlag.current) {
      setProvisioningData({ ...ProvisioningData, status: "aborted" });
      setPending(null);
      setNewStep(<strong>Benutzerausgliederung abgebrochen</strong>, {
        error: <strong>Die Benutzerausgliederung wurde abgebrochen</strong>,
      });
      saveReport();
      return;
    } else {
      setProvisioningData({
        ...ProvisioningData,
        status: "finished",
      });
      saveReport();
    }
  };

  const handleAbortClick = () => {
    abortFlag.current = true;
    setPending("Ausgliederung wird abgebrochen...");
  };

  const handleFinishClick = () => {
    setProvisioningData({
      ...ProvisioningData,
      employeeAttributes: "new",
      step: 1,
      status: "new",
    });
  };

  useEffect(() => {
    if (!tasksPlanned) {
      setPlannedTasks();
    }
    try {
      if (
        ProvisioningData.status === "confirmed" &&
        ProvisioningData.action === "deprovisioning"
      ) {
        deprovisionUser();
      }
    } catch (error) {
      console.log(error);
    }
  }, [ProvisioningData]);

  return (
    <>
      <Timeline
        style={{ padding: 20 }}
        mode="left"
        pending={pending}
        items={steps}
      />

      {ProvisioningData.status === "running" &&
        ProvisioningData.action === "deprovisioning" && (
          <Button type="primary" danger onClick={handleAbortClick}>
            Benutzerausgliederung abbrechen
          </Button>
        )}
      {ProvisioningData.status === "finished" &&
        ProvisioningData.action === "deprovisioning" && (
          <Button
            className="btn-primary"
            type="primary"
            onClick={handleFinishClick}
            autoFocus
          >
            Ausgliederung abschließen
          </Button>
        )}
    </>
  );
};

export default UserProvisioningProgress;
