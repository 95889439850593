import "../App.css";
import React, { useRef, useEffect, useState } from "react";
import { Button, Space, Timeline } from "antd";
import api from "../utils/useAxios";
import DangerousIcon from "@mui/icons-material/Dangerous";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useProvisioningData } from "../store/context";

const ChangeDepartmentProgress = () => {
  const [steps, setSteps] = useState([]);
  const [pending, setPending] = useState(null);
  const abortFlag = useRef();
  const failedTasks = useRef([]);
  const licenseAssigned = useRef(true);
  const keeperUserCreated = useRef(false);
  const completedTasks = useRef([]);
  const plannedTasks = useRef([]);
  const plannedTasksArray = useRef([]);
  const { ProvisioningData, setProvisioningData } = useProvisioningData();
  const [tasksPlanned, setTasksPlanned] = useState(false);
  const [retryFlag, setRetryFlag] = useState(false);
  const [einsnulleinsMail, setEinsnulleinsMail] = useState("");

  const setPlannedTasks = () => {
    if (retryFlag) {
      return;
    }
    if (ProvisioningData.plannedTasks) {
      plannedTasks.current = ProvisioningData.plannedTasks;
      completedTasks.current = ProvisioningData.completedTasks;
      const shortList = ProvisioningData.plannedTasks.map((task) => task.key);
      plannedTasksArray.current = shortList;
      return true;
    }
    const userOptions = ProvisioningData.employeeAttributes.user_options;
    let initialPlannedTasks = [];
    if (userOptions === "deprovision_einsnulleins") {
      initialPlannedTasks = [
        {
          key: "update_database",
          label: "Verknüpfungen zu Lizenzen in der Datenbank löschen",
        },
        {
          key: "delete_confluence_user",
          label: "Confluence User deaktivieren",
        },
        {
          key: "autotask_ticket",
          label: "Autotask Ticket aktualisieren",
        },
        {
          key: "delete_m365_user",
          label: "M365 User löschen",
        },
      ];
    } else if (userOptions === "provision_einsnulleins") {
      initialPlannedTasks = [
        {
          key: "update_aad_properties",
          label:
            "Benutzereigenschaften im Azure Active Directory aktualisieren",
        },
        {
          key: "m365_license",
          label: "M365 Business Standard Lizenz zuweisen",
        },
        {
          key: "m365_calendar_visibility",
          label: "M365 Kalendarsichtbarkeit anpassen",
        },
        {
          key: "confluence_user",
          label: "Confluence User erstellen",
        },
        {
          key: "confluence_group",
          label: "Confluence Standort Gruppe zuweisen",
        },
        {
          key: "autotask_ticket",
          label: "Autotask Ticket aktualisieren",
        },
      ];
    } else {
      initialPlannedTasks = [
        {
          key: "update_aad_properties",
          label:
            "Benutzereigenschaften im Azure Active Directory aktualisieren",
        },
        {
          key: "autotask_ticket",
          label: "Autotask Ticket aktualisieren",
        },
      ];
    }
    const keeperTeams = ProvisioningData.employeeAttributes.keeper_teams;
    if (!Array.isArray(keeperTeams)) {
      keeperTeams = JSON.parse(keeperTeams);
    }
    if (keeperTeams.length > 0) {
      initialPlannedTasks.push({
        key: "keeper_teams",
        label: "Keeper Teams zuweisen",
      });
      for (const keeperTeam of keeperTeams) {
        initialPlannedTasks.push({
          key: `keeper_team_${keeperTeam}`,
          label: `Keeper Team ${keeperTeam} zuweisen`,
        });
      }
    }

    const plannedM365Groups = ProvisioningData.employeeAttributes.m365_groups;
    if (!Array.isArray(plannedM365Groups)) {
      plannedM365Groups = JSON.parse(plannedM365Groups);
    }
    if (plannedM365Groups.length > 0) {
      initialPlannedTasks.push({
        key: "m365_groups",
        label: "M365 Gruppen zuweisen",
      });
      for (const group of plannedM365Groups) {
        initialPlannedTasks.push({
          key: `m365_group_${group}`,
          label: `M365 Gruppe ${group} zuweisen`,
        });
      }
    }

    let systems = ProvisioningData.employeeAttributes.systems;

    if (!Array.isArray(systems)) {
      systems = JSON.parse(systems);
    }
    if (systems.includes("SAP Business One")) {
      initialPlannedTasks.push({
        key: "sap_user",
        label: "SAP User anfragen",
      });
    }
    if (systems.includes("RMM Dashboard")) {
      initialPlannedTasks.push({
        key: "rmm_user",
        label: "RMM User anfragen",
      });
    }
    if (systems.includes("M365")) {
      initialPlannedTasks.push(
        {
          key: "m365_user",
          label: "M365 User erstellen",
        },
        {
          key: "m365_calendar_visibility",
          label: "Kalendarsichtbarkeit anpassen",
        }
      );
    }
    if (userOptions !== "deprovision_einsnulleins") {
      const sharedMailboxes =
        ProvisioningData.employeeAttributes.shared_mailboxes;
      if (!Array.isArray(sharedMailboxes)) {
        sharedMailboxes = JSON.parse(sharedMailboxes);
      }

      if (sharedMailboxes.length > 0) {
        initialPlannedTasks.push({
          key: "m365_shared_mailboxes",
          label: "Berechtigungen für geteilte Postfächer erteilen",
        });
        for (const mailbox of sharedMailboxes) {
          initialPlannedTasks.push({
            key: "m365_shared_mailbox_" + mailbox,
            label: `Berechtigung für geteiltes Postfach ${mailbox} erteilen`,
          });
        }
      }
      const toBeRemovedM365Groups =
        ProvisioningData.employeeAttributes.to_be_removed_m365_groups;
      if (!Array.isArray(toBeRemovedM365Groups)) {
        sharedMailboxes = JSON.parse(sharedMailboxes);
      }
      if (toBeRemovedM365Groups.length > 0) {
        initialPlannedTasks.push({
          key: "m365_remove_groups",
          label: "Zugriff auf M365 Gruppen entfernen",
        });
        for (const group of toBeRemovedM365Groups) {
          initialPlannedTasks.push({
            key: "m365_remove_group_" + group,
            label: `Zugriff auf M365 Gruppe ${group} entfernen`,
          });
        }
      }
      const toBeRemovedSharedMailboxes =
        ProvisioningData.employeeAttributes.to_be_removed_shared_mailboxes;
      if (!Array.isArray(toBeRemovedSharedMailboxes)) {
        toBeRemovedSharedMailboxes = JSON.parse(toBeRemovedSharedMailboxes);
      }
      if (toBeRemovedSharedMailboxes.length > 0) {
        initialPlannedTasks.push({
          key: "m365_remove_shared_mailboxes",
          label: "Berechtigungen für geteilte Postfächer entfernen",
        });
        for (const mailbox of toBeRemovedSharedMailboxes) {
          initialPlannedTasks.push({
            key: "m365_remove_shared_mailbox_" + mailbox,
            label: `Berechtigungen für geteiltes Postfach ${mailbox} entfernen`,
          });
        }
      }
    }
    let toBeRemovedSystems =
      ProvisioningData.employeeAttributes.to_be_removed_systems;
    if (!Array.isArray(toBeRemovedSystems)) {
      toBeRemovedSystems = JSON.parse(toBeRemovedSystems);
    }
    if (toBeRemovedSystems.includes("SAP Business One")) {
      initialPlannedTasks.push({
        key: "delete_sap_user",
        label: "Deaktivierung SAP User anfragen",
      });
    }
    if (toBeRemovedSystems.includes("RMM Dashboard")) {
      initialPlannedTasks.push({
        key: "delete_rmm_user",
        label: "Deaktivierung RMM User anfragen",
      });
    }
    if (toBeRemovedSystems.includes("Keeper")) {
      initialPlannedTasks.push({
        key: "delete_keeper_user",
        label: "Keeper User löschen und Tresor übertragen",
      });
    }
    if (!toBeRemovedSystems.includes("Keeper")) {
      const toBeRemovedKeeperTeams =
        ProvisioningData.employeeAttributes.to_be_removed_keeper_teams;
      if (!Array.isArray(toBeRemovedKeeperTeams)) {
        toBeRemovedKeeperTeams = JSON.parse(toBeRemovedKeeperTeams);
      }
      if (toBeRemovedKeeperTeams.length > 0) {
        initialPlannedTasks.push({
          key: "keeper_remove_teams",
          label: "Zugriff auf Keeper Teams entfernen",
        });
        for (const team of toBeRemovedKeeperTeams) {
          initialPlannedTasks.push({
            key: "keeper_remove_team_" + team,
            label: `Zugriff auf Keeper Team ${team} entfernen`,
          });
        }
      }
    }

    const shortList = initialPlannedTasks.map((task) => task.key);
    plannedTasksArray.current = shortList;
    plannedTasks.current = initialPlannedTasks;
    setTasksPlanned(true);
    return true;
  };

  const addNewSuccess = (newSuccessElement) => {
    plannedTasks.current = plannedTasks.current.filter(
      (task) => task.key !== newSuccessElement.key
    );
    plannedTasksArray.current = plannedTasksArray.current.filter(
      (task) => task !== newSuccessElement.key
    );
    completedTasks.current = [...completedTasks.current, newSuccessElement];
  };

  const addNewError = (newErrorElement) => {
    plannedTasks.current = plannedTasks.current.filter(
      (task) => task.key !== newErrorElement.key
    );
    plannedTasksArray.current = plannedTasksArray.current.filter(
      (task) => task !== newErrorElement.key
    );
    failedTasks.current = [...failedTasks.current, newErrorElement];
  };

  const saveReport = () => {
    let finished = true;
    if (plannedTasks.current.length > 0) {
      finished = false;
    }
    const protocol = {
      type: "changeDepartment",
      ticketnumber: ProvisioningData.ticketnumber,
      finished: finished,
      employeeAttributes: ProvisioningData.employeeAttributes,
      plannedTasks: plannedTasks.current,
      completedTasks: completedTasks.current,
      failedTasks: failedTasks.current,
    };
    api.post("reports/useradministration", { data: protocol });
  };

  const constructEinsnulleinsEmail = () => {
    const email = ProvisioningData.employeeAttributes.email;
    if (email.includes("synaxon")) {
      const parts = email.split("@");
      const newEmail = parts[0] + "@einsnulleins.de";
      setEinsnulleinsMail(newEmail);
    } else {
      setEinsnulleinsMail(email);
    }
  };

  const setNewStep = (label, content) => {
    if (content.error) {
      const newStep = {
        label: <strong>{label}</strong>,
        children: content.error,
        color: "red",
        dot: <DangerousIcon sx={{ color: "red" }} />,
      };
      setSteps((prevSteps) => [...prevSteps, newStep]);
    } else if (content.warning) {
      const newStep = {
        label: <strong>{label}</strong>,
        children: content.warning,
        color: "yellow",
        dot: <WarningIcon sx={{ color: "#ffe600" }} />,
      };
      setSteps((prevSteps) => [...prevSteps, newStep]);
    } else if (content.success_message) {
      const newStep = {
        label: <strong>{label}</strong>,
        children: content.success_message,
        color: "yellow",
        dot: <CheckCircleIcon sx={{ color: "green" }} />,
      };
      setSteps((prevSteps) => [...prevSteps, newStep]);
    }
  };

  const dummyStep = (pending, label, content) => {
    setPending(pending);
    return new Promise((resolve) => {
      setTimeout(() => {
        setNewStep(label, content);
        addNewSuccess({ key: "dummy", label: "dummy" });
        resolve(true);
      }, 1000);
    });
  };
  const provisionM365User = async () => {
    if (!abortFlag.current && plannedTasksArray.current.includes("m365_user")) {
      setPending("Erstelle M365 Benutzer...");
      const attributes = {
        email: einsnulleinsMail,
        first_name: ProvisioningData.employeeAttributes.first_name,
        last_name: ProvisioningData.employeeAttributes.last_name,
        company: "einsnulleins Schloß Holte-Stukenbrock",
      };
      let response = await api.post("m365/user", {
        params: attributes,
        ticketnumber: ProvisioningData.ticketnumber,
      });
      let result = await response.data;
      setNewStep("M365 Benutzer erstellen", result);
      if (result.error) {
        abortFlag.current = true;
        addNewError({ key: "m365_user", label: "M365 User erstellen" });
        setPending(null);
        setProvisioningData({ ...ProvisioningData, status: "error" });
        setNewStep(<strong>Benutzereingliederung abgebrochen!!!</strong>, {
          error: (
            <strong>
              Da die Erstellung des M365 Users fehlgeschlagen ist, wird die
              Benutzereingliederung abgebrochen
            </strong>
          ),
        });
        return false;
      } else {
        addNewSuccess({ key: "m365_user", label: "M365 User erstellen" });
        return true;
      }
    }
  };
  const assignM365Licenses = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("m365_license")
    ) {
      try {
        setPending(`Weise M365 Business Standard Lizenz zu...`);
        if (abortFlag.current) {
          return;
        }
        const params = {
          params: {
            email: einsnulleinsMail,
            license: "M365 Business Standard",
            tenant: "einsnulleins Schloß Holte-Stukenbrock",
          },
        };
        let result = await api.post("m365/user/licenses", params);
        let data = await result.data;
        setNewStep(`M365 Business Standard Lizenz zuweisen`, data);

        if (data.error) {
          addNewError({
            key: "m365_license",
            label: "M365 Business Standard Lizenz zuweisen",
          });
        } else {
          addNewSuccess({
            key: "m365_license",
            label: "M365 Business Standard Lizenz zuweisen",
          });
        }
      } catch (error) {
        console.error("error in assigning licenses", error);
        addNewError({
          key: "m365_license",
          label: "M365 Business Standard Lizenz zuweisen",
        });
        return error;
      }
    }
  };

  const updateAADproperties = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("update_aad_properties")
    ) {
      try {
        setPending(
          `Aktualisiere Eigenschaften von ${einsnulleinsMail} im Azure Active Directory...`
        );
        if (abortFlag.current) {
          return;
        }
        const params = {
          username: einsnulleinsMail,
          department: ProvisioningData.employeeAttributes.future_department,
          manager: ProvisioningData.employeeAttributes.manager,
          tenant: "einsnulleins Schloß Holte-Stukenbrock",
        };
        let result = await api.patch("m365/user", params);
        let data = await result.data;
        setNewStep(
          `Benutzereigenschaften im Azure Active Directory aktualisieren`,
          data
        );

        if (data.error) {
          addNewError({
            key: "update_aad_properties",
            label:
              "Benutzereigenschaften im Azure Active Directory aktualisieren",
          });
        } else {
          addNewSuccess({
            key: "update_aad_properties",
            label:
              "Benutzereigenschaften im Azure Active Directory aktualisieren",
          });
        }
      } catch (error) {
        console.error("error in updating properties in aad", error);
        addNewError({
          key: "update_aad_properties",
          label:
            "Benutzereigenschaften im Azure Active Directory aktualisieren",
        });
        return error;
      }
    }
  };

  const deprovisionM365User = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("delete_m365_user")
    ) {
      setPending("Lösche M365 Benutzer...");
      const data = {
        username: einsnulleinsMail,
        tenant: "einsnulleins Schloß Holte-Stukenbrock",
      };

      let response = await api.delete("m365/user", {
        params: data,
      });

      let result = await response.data;
      setNewStep("M365 Benutzer löschen", result);
      if (result.error) {
        addNewError({
          key: "delete_m365_user",
          label: "M365 Benutzer löschen",
        });
        return false;
      } else {
        addNewSuccess({
          key: "delete_m365_user",
          label: "M365 Benutzer löschen",
        });
        return true;
      }
    }
  };

  const assignM365Groups = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("m365_groups")
    ) {
      try {
        setPending("Weise M365 Gruppen zu...");
        let error = false;
        let groups = ProvisioningData.employeeAttributes.m365_groups;
        for (const group of groups) {
          if (!plannedTasksArray.current.includes(`m365_group_${group}`)) {
            continue;
          }
          setPending(`Weise M365 Gruppe "${group}" zu...`);
          if (abortFlag.current) {
            break;
          }
          const data = {
            groupName: group,
            email: einsnulleinsMail,
            tenant: "einsnulleins Schloß Holte-Stukenbrock",
          };
          let result = await api.post("m365/user/groups", data);
          let responseData = await result.data;
          setNewStep(`Gruppe ${group} zuweisen`, responseData);
          if (responseData.error) {
            error = true;
            addNewError({
              key: `m365_group_${group}`,
              label: `M365 Gruppe ${group} zuweisen`,
            });
          } else {
            addNewSuccess({
              key: `m365_group_${group}`,
              label: `M365 Gruppe ${group} zuweisen`,
            });
          }
        }
        if (error) {
          addNewError({ key: "m365_groups", label: "M365 Gruppen zuweisen" });
        } else {
          addNewSuccess({
            key: "m365_groups",
            label: "M365 Gruppen zuweisen",
          });
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    } else {
      console.log("no m365 groups to assign");
    }
  };

  const removefromM365Groups = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("m365_remove_groups")
    ) {
      setPending("Entferne Zugriff auf M365 Gruppen...");
      try {
        let error = false;
        let groups =
          ProvisioningData.employeeAttributes.to_be_removed_m365_groups;
        for (const group of groups) {
          if (
            !plannedTasksArray.current.includes(`m365_remove_group_${group}`)
          ) {
            continue;
          }
          setPending(`Entferne Zugriff auf M365 Gruppe "${group}"...`);
          if (abortFlag.current) {
            break;
          }
          const data = {
            groupName: group,
            email: einsnulleinsMail,
            tenant: "einsnulleins Schloß Holte-Stukenbrock",
          };
          console.log("remove m365 group", data);
          let result = await api.delete("m365/user/groups", {
            params: data,
          });
          let responseData = await result.data;
          setNewStep(
            `Zugriff auf M365 Gruppe ${group} entfernen`,
            responseData
          );
          if (responseData.error) {
            error = true;
            addNewError({
              key: `m365_remove_group_${group}`,
              label: `Zugriff auf M365 Gruppe ${group} entfernen`,
            });
          } else {
            addNewSuccess({
              key: `m365_remove_group_${group}`,
              label: `Zugriff auf M365 Gruppe ${group} entfernen`,
            });
          }
        }
        if (error) {
          addNewError({
            key: "m365_remove_groups",
            label: "Zugriff auf M365 Gruppen entfernen",
          });
        } else {
          addNewSuccess({
            key: "m365_remove_groups",
            label: "Zugriff auf M365 Gruppen entfernen",
          });
        }
      } catch (error) {
        addNewError({
          key: "m365_remove_groups",
          label: "Zugriff auf M365 Gruppen entfernen",
        });
        return error;
      }
    }
  };
  const setCalendarVisibility = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("m365_calendar_visibility")
    ) {
      try {
        if (!licenseAssigned.current) {
          addNewError({
            key: "M365 Calendar Visibility",
            label: "Kalendarsichtbarkeit anpassen",
          });
          setNewStep("Kalendarsichtbarkeit anpassen", {
            error:
              "Da keine M365 Lizenz zugewiesen werden konnte, kann die Kalendarsichtbarkeit nicht angepasst werden.",
          });
          return;
        }
        setPending("Passe Kalendarsichtbarkeit an..");
        const data = {
          tenant: "einsnulleins Schloß Holte-Stukenbrock",
          user: einsnulleinsMail,
        };
        let groupIdsResponse = await api.post(
          "m365/user/calendarSettings",
          data
        );
        let responseData = await groupIdsResponse.data;
        setNewStep("Kalendarsichtbarkeit anpassen", responseData);
        if (responseData.error) {
          addNewError({
            key: "m365_calendar_visibility",
            label: "Kalendarsichtbarkeit anpassen",
          });
        } else {
          addNewSuccess({
            key: "m365_calendar_visibility",
            label: "Kalendarsichtbarkeit anpassen",
          });
        }
      } catch (error) {
        return error;
      }
    }
  };

  const assignM365Mailboxes = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("m365_shared_mailboxes")
    ) {
      try {
        if (!licenseAssigned.current) {
          addNewError({
            key: "m365_shared_mailboxes",
            label: "Berechtigung für geteilte Postfächer erteilen",
          });
          setNewStep("Berechtigung für geteilte Postfächer erteilen", {
            error:
              "Da keine M365 Lizenz zugewiesen werden konnte, können auch keine Berechtigungen für geteilte Postfächer erteilt werden.",
          });
          return;
        }
        let error = false;
        let shared_mailboxes =
          ProvisioningData.employeeAttributes.shared_mailboxes;
        if (!Array.isArray(shared_mailboxes)) {
          shared_mailboxes = JSON.parse(shared_mailboxes);
        }
        for (const mailGroup of shared_mailboxes) {
          if (
            !plannedTasksArray.current.includes(
              `m365_shared_mailbox_${mailGroup}`
            )
          ) {
            continue;
          }
          if (abortFlag.current) {
            return;
          }
          setPending(
            `Berechtigung für geteiltes Postfach ${mailGroup} erteilen...`
          );
          const data = {
            tenant: "einsnulleins Schloß Holte-Stukenbrock",
            user: einsnulleinsMail,
            mailGroup: mailGroup,
          };
          let groupIdsResponse = await api.post("m365/user/mailgroups", data);
          let responseData = await groupIdsResponse.data;
          setNewStep(
            `Berechtigung für geteiltes Postfach ${mailGroup} erteilen`,
            responseData
          );

          if (responseData.error) {
            error = true;
            addNewError({
              key: `m365_shared_mailbox_${mailGroup}`,
              label: `Berechtigung für geteiltes Postfach ${mailGroup} erteilen`,
            });
          } else {
            addNewSuccess({
              key: `m365_shared_mailbox_${mailGroup}`,
              label: `Berechtigung für geteiltes Postfach ${mailGroup} erteilen`,
            });
          }
        }
        if (error) {
          addNewError({
            key: "m365_shared_mailboxes",
            label: "Berechtigung für geteilte Postfächer erteilen",
          });
        } else {
          addNewSuccess({
            key: "m365_shared_mailboxes",
            label: "Berechtigung für geteilte Postfächer erteilen",
          });
        }
      } catch (error) {
        return error;
      }
    }
  };
  const deprovisionConfluenceUser = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("delete_confluence_user")
    ) {
      setPending("Lösche Confluence User...");
      const data = {
        email: einsnulleinsMail,
      };

      let response = await api.delete("confluence/user", {
        params: data,
      });
      let result = await response.data;
      setNewStep("Confluence Benutzer löschen", result);
      if (result.error) {
        addNewError({
          key: "delete_confluence_user",
          label: "Confluence Benutzer löschen",
        });
      } else {
        addNewSuccess({
          key: "delete_confluence_user",
          label: "Confluence Benutzer löschen",
        });
      }
      return true;
    }
  };

  const removeFromM365Mailboxes = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("m365_remove_shared_mailboxes")
    ) {
      try {
        let error = false;
        let shared_mailboxes =
          ProvisioningData.employeeAttributes.to_be_removed_shared_mailboxes;
        if (!Array.isArray(shared_mailboxes)) {
          shared_mailboxes = JSON.parse(shared_mailboxes);
        }
        for (const mailGroup of shared_mailboxes) {
          if (
            !plannedTasksArray.current.includes(
              `m365_remove_shared_mailbox_${mailGroup}`
            )
          ) {
            continue;
          }
          if (abortFlag.current) {
            return;
          }
          setPending(
            `Entferne Berechtigung für geteiltes Postfach ${mailGroup}...`
          );
          const data = {
            user: einsnulleinsMail,
            mailGroup: mailGroup,
          };
          let groupIdsResponse = await api.delete("m365/user/mailgroups", {
            params: data,
          });
          let responseData = await groupIdsResponse.data;
          setNewStep(
            `Entferne Berechtigung für geteiltes Postfach ${mailGroup}`,
            responseData
          );
          if (responseData.error) {
            error = true;
            addNewError({
              key: `m365_remove_shared_mailbox_${mailGroup}`,
              label: `Entferne Berechtigung für geteiltes Postfach ${mailGroup}`,
            });
          } else {
            addNewSuccess({
              key: `m365_remove_shared_mailbox_${mailGroup}`,
              label: `Entferne Berechtigung für geteiltes Postfach ${mailGroup}`,
            });
          }
        }
        if (error) {
          addNewError({
            key: "m365_remove_shared_mailboxes",
            label: "Geteilte Postfächer entfernen",
          });
        } else {
          addNewSuccess({
            key: "m365_remove_shared_mailboxes",
            label: "Geteilte Postfächer entfernen",
          });
        }
      } catch (error) {
        return error;
      }
    }
  };

  const assignKeeperTeams = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("keeper_teams")
    ) {
      try {
        let errors = false;
        let keeperTeams = ProvisioningData.employeeAttributes.keeper_teams;
        if (!Array.isArray(keeperTeams)) {
          keeperTeams = JSON.parse(keeperTeams);
        }
        for (const keeperTeam of keeperTeams) {
          if (abortFlag.current) {
            return;
          }
          if (
            !plannedTasksArray.current.includes(`keeper_team_${keeperTeam}`)
          ) {
            continue;
          }
          setPending(`Weise Keeper Team "${keeperTeam}" zu...`);
          const data = {
            tenant: ProvisioningData.employeeAttributes.location,
            user: ProvisioningData.employeeAttributes.email,
            team: keeperTeam,
          };
          let groupIdsResponse = await api.post("keeper/user/teams", data);
          let responseData = await groupIdsResponse.data;
          setNewStep(`Keeper Team ${keeperTeam} zuweisen`, responseData);
          if (responseData.error) {
            errors = true;
            addNewError({
              key: `keeper_team_${keeperTeam}`,
              label: `Keeper Team ${keeperTeam} zuweisen`,
            });
          } else {
            addNewSuccess({
              key: `keeper_team_${keeperTeam}`,
              label: `Keeper Team ${keeperTeam} zuweisen`,
            });
          }
        }
        if (errors) {
          addNewError({ key: "keeper_teams", label: "Keeper Teams zuweisen" });
        } else {
          addNewSuccess({
            key: "keeper_teams",
            label: "Keeper Teams zuweisen",
          });
        }
      } catch (error) {
        addNewError({ key: "keeper_teams", label: "Keeper Teams zuweisen" });
      }
    }
  };

  const removeFromKeeperTeams = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("keeper_remove_teams")
    ) {
      try {
        let errors = false;
        let keeperTeams =
          ProvisioningData.employeeAttributes.to_be_removed_keeper_teams;
        if (!Array.isArray(keeperTeams)) {
          keeperTeams = JSON.parse(keeperTeams);
        }
        console.log(keeperTeams);
        for (const keeperTeam of keeperTeams) {
          console.log(keeperTeam);
          if (abortFlag.current) {
            return;
          }
          if (
            !plannedTasksArray.current.includes(
              `keeper_remove_team_${keeperTeam}`
            )
          ) {
            continue;
          }
          setPending(`Entferne Zugriff auf Keeper Team "${keeperTeam}"...`);
          const data = {
            user: ProvisioningData.employeeAttributes.email,
            team: keeperTeam,
          };
          let groupIdsResponse = await api.delete("keeper/user/teams", {
            params: data,
          });
          let responseData = await groupIdsResponse.data;
          setNewStep(`Keeper Team ${keeperTeam} zuweisen`, responseData);
          if (responseData.error) {
            errors = true;
            addNewError({
              key: `keeper_remove_team_${keeperTeam}`,
              label: `Zugruff auf Keeper Team ${keeperTeam} entfernen`,
            });
          } else {
            addNewSuccess({
              key: `keeper_remove_team_${keeperTeam}`,
              label: `Zugriff auf Keeper Team ${keeperTeam} entfernen`,
            });
          }
        }
        if (errors) {
          addNewError({
            key: "keeper_remove_teams",
            label: "Zugriff auf Keeper Teams entfernen",
          });
        } else {
          addNewSuccess({
            key: "keeper_remove_teams",
            label: "Zugriff auf Keeper Teams entfernen",
          });
        }
      } catch (error) {
        addNewError({
          key: "keeper_remove_teams",
          label: "Zugriff auf Keeper Teams entfernen",
        });
      }
    }
  };

  const deprovisionKeeperUser = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("delete_keeper_user")
    ) {
      setPending("Lösche Keeper User...");
      const data = {
        email: einsnulleinsMail,
      };

      let response = await api.delete("keeper/user", {
        params: data,
      });
      let result = await response.data;
      if (result.error) {
        addNewError({
          key: "delete_keeper_user",
          label: "Keeper Benutzer löschen",
        });
      } else {
        addNewSuccess({
          key: "delete_keeper_user",
          label: "Keeper Benutzer löschen",
        });
      }
      setNewStep("Keeper User löschen", result);
      return true;
    }
  };

  const provisionConfluenceUser = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("confluence_user")
    ) {
      setPending("Erstelle Confluence User...");
      const data = {
        email: einsnulleinsMail,
      };
      let response = await api.post("confluence/user", data);
      let result = await response.data;
      setNewStep("Confluence Benutzer erstellen", result);
      if (result.error) {
        addNewError({
          key: "confluence_user",
          label: "Confluence User erstellen",
        });
      } else {
        addNewSuccess({
          key: "confluence_user",
          label: "Confluence User erstellen",
        });
      }
      return true;
    }
  };

  const assignConfluenceGroup = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("confluence_group")
    ) {
      setPending("Weise Confluence Standort Gruppe zu...");
      const data = {
        company: "einsnulleins Schloß Holte-Stukenbrock",
        email: einsnulleinsMail,
      };
      let response = await api.post("confluence/groups", data);
      let result = await response.data;
      setNewStep("Confluence Standort Gruppe zuweisen", result);
      if (result.error) {
        addNewError({
          key: "confluence_group",
          label: "Confluence Standort Gruppe zuweisen",
        });
      } else {
        addNewSuccess({
          key: "confluence_group",
          label: "Confluence Standort Gruppe zuweisen",
        });
      }
      return true;
    }
  };

  const provisionSAPUser = async () => {
    if (!abortFlag.current && plannedTasksArray.current.includes("sap_user")) {
      setPending("Frage User beim SAP Support Team an...");
      const data = {
        email: einsnulleinsMail,
        company: "einsnulleins Schloß Holte-Stukenbrock",
        ticketnumber: ProvisioningData.ticketnumber,
      };
      let response = await api.post("sap/users", data);
      let result = await response.data;
      setNewStep("SAP User anfragen", result);
      if (result.error) {
        addNewError({ key: "sap_user", label: "SAP User anfragen" });
      } else {
        addNewSuccess({ key: "sap_user", label: "SAP User anfragen" });
      }
      return true;
    }
  };
  const deprovisionSAPUser = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("delete_sap_user")
    ) {
      setPending("Frage Deaktivierung von User beim SAP Support Team an...");
      const data = {
        email: einsnulleinsMail,
        company: "einsnulleins Schloß Holte-Stukenbrock",
        ticketnumber: ProvisioningData.ticketnumber,
      };

      let response = await api.delete("sap/users", {
        params: data,
      });
      let result = await response.data;
      setNewStep("Deaktivierung von SAP User beantragen", result);
      if (result.error) {
        addNewError({
          key: "delete_sap_user",
          label: "Deaktivierung SAP User beantragen",
        });
      } else {
        addNewSuccess({
          key: "delete_sap_user",
          label: "Deaktivierung SAP User beantragen",
        });
      }
      return true;
    }
  };

  const provisionRMMUser = async () => {
    if (!abortFlag.current && plannedTasksArray.current.includes("rmm_user")) {
      setPending("Frage RMM User beim SMS Support Team an...");
      const data = {
        email: einsnulleinsMail,
        company: "einsnulleins Schloß Holte-Stukenbrock",
        ref_employee: "Keine Referenz vorhanden",
      };
      let response = await api.post("notifications/rmm/users", data);
      let result = await response.data;
      setNewStep("RMM User anfragen", result);
      if (result.error) {
        addNewError({ key: "rmm_user", label: "RMM User anfragen" });
      } else {
        addNewSuccess({ key: "rmm_user", label: "RMM User anfragen" });
      }
      return true;
    }
  };
  const deprovisionRMMUser = async () => {
    if (!abortFlag.current && plannedTasksArray.current.includes("rmm_user")) {
      const data = {
        email: einsnulleinsMail,
        company: "einsnulleins Schloß Holte-Stukenbrock",
      };

      let response = await api.delete("notifications/rmm/users", {
        params: data,
      });
      let result = await response.data;
      setNewStep("Deaktivierung von RMM User anfragen", result);
      if (result.error) {
        addNewError({
          key: "rmm_user",
          label: "Deaktivierung RMM User anfragen",
        });
      } else {
        addNewSuccess({
          key: "rmm_user",
          label: "Deaktivierung RMM User anfragen",
        });
      }
      return true;
    }
  };
  const updateDatabase = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("update_database") &&
      failedTasks.current.length === 0
    ) {
      setPending("Lösche Lizenz-Verknüpfungen in der Datenbank...");
      const data = {
        email: einsnulleinsMail,
      };

      let response = await api.post("delinkAccountLicenses", data);
      let result = await response.data;
      setNewStep("Verknüpfungen zu Lizenzen löschen", result);
      if (result.error) {
        addNewError({
          key: "update_database",
          label: "Verknüpfungen zu Lizenzen in der Datenbank löschen",
        });
      } else {
        addNewSuccess({
          key: "update_database",
          label: "Verknüpfungen zu Lizenzen in der Datenbank löschen",
        });
      }
      return true;
    }
  };

  const updateAutotaskTicket = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("autotask_ticket")
    ) {
      setPending("Aktualisiere Autotask Ticket...");
      let succesfulTaskKeys = completedTasks.current.map((task) => task.key);
      let succesfulTaskLabels = completedTasks.current.map(
        (task) => task.label
      );
      let failedTaskLabels = failedTasks.current.map((task) => task.label);

      const data = {
        ticketnumber: ProvisioningData.ticketnumber,
        completedTaskKeys: succesfulTaskKeys,
        completedTaskLabels: succesfulTaskLabels,
        failedTaskLabels: failedTaskLabels,
      };
      console.log("update autotask ticket", data);
      let response = await api.patch("autotask/ticket", data);
      let result = await response.data;
      if (result.error) {
        addNewError({
          key: "autotask_ticket",
          label: "Autotask Ticket aktualisieren",
        });
      } else {
        addNewSuccess({
          key: "autotask_ticket",
          label: "Autotask Ticket aktualisieren",
        });
      }
      setNewStep("Autotask Ticket aktualisieren", result);
    }
  };

  const startChangeDepartmentTasks = async () => {
    setProvisioningData({ ...ProvisioningData, status: "running" });
    setNewStep("Abteilungswechsel starten", {
      success_message: "Abteilungswechsel gestartet",
    });
    console.log("planned tasks", plannedTasksArray.current);
    for (const func of [
      provisionM365User,
      assignM365Licenses,
      updateAADproperties,
      deprovisionM365User,
      assignM365Groups,
      removefromM365Groups,
      setCalendarVisibility,
      assignM365Mailboxes,
      provisionConfluenceUser,
      assignConfluenceGroup,
      deprovisionConfluenceUser,
      removeFromM365Mailboxes,
      assignKeeperTeams,
      removeFromKeeperTeams,
      deprovisionKeeperUser,
      provisionSAPUser,
      provisionRMMUser,
      deprovisionSAPUser,
      deprovisionRMMUser,
      updateAutotaskTicket,
      updateDatabase,
    ]) {
      await func();
    }
    setPending(null);

    if (abortFlag.current) {
      //saveReport();
      setProvisioningData({ ...ProvisioningData, status: "aborted" });
      setPending(null);
      setNewStep(<strong>Abteilungswechsel abgebrochen</strong>, {
        error: <strong>Der Abteilungswechsel wurde abgebrochen</strong>,
      });

      return;
    } else {
      if (failedTasks.current.length > 0) {
        setNewStep(<strong>Abteilungswechsel abgeschlossen</strong>, {
          success_message: <strong>Der Abteilungswechsel ist beendet</strong>,
        });
      } else {
        setNewStep(<strong>Abteilungswechsel abgeschlossen</strong>, {
          success_message: (
            <strong>
              Der Abteilungswechsel wurde erfolgreich abgeschlossen
            </strong>
          ),
        });
      }
      saveReport();
      setProvisioningData({ ...ProvisioningData, status: "finished" });
    }
  };

  const handleAbortClick = () => {
    abortFlag.current = true;
    setPending("Eingliederung wird abgebrochen...");
  };

  const handleFinishClick = () => {
    setProvisioningData({
      ...ProvisioningData,
      employeeAttributes: "new",
      step: 1,
      status: "new",
    });
  };

  const handleRetryClick = () => {
    plannedTasksArray.current = failedTasks.current.map((task) => task.key);
    abortFlag.current = false;
    setProvisioningData({
      ...ProvisioningData,
      status: "confirmed",
    });
  };

  useEffect(() => {
    if (!tasksPlanned) {
      setPlannedTasks();
      constructEinsnulleinsEmail();
    }

    if (
      ProvisioningData.status === "confirmed" &&
      ProvisioningData.action === "changeDepartment"
    ) {
      startChangeDepartmentTasks();
    }
  }, [ProvisioningData.status]);

  return (
    <>
      <Timeline
        style={{ padding: 20 }}
        mode="left"
        pending={pending}
        items={steps}
      />

      {ProvisioningData.status === "running" &&
        ProvisioningData.action === "changeDepartment" && (
          <Button type="primary" danger onClick={handleAbortClick}>
            Abteilungswechsel abbrechen
          </Button>
        )}
      {ProvisioningData.status === "finished" &&
        ProvisioningData.action === "changeDepartment" &&
        failedTasks.current.length === 0 && (
          <Button
            className="btn-primary"
            type="primary"
            onClick={handleFinishClick}
            autoFocus={true}
          >
            Abteilungswechsel abschließen
          </Button>
        )}
      {(ProvisioningData.status === "finished" ||
        ProvisioningData.status === "aborted") &&
        ProvisioningData.action === "changeDepartment" &&
        failedTasks.current.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "15%",
                }}
              >
                <Button
                  className="btn-primary"
                  type="primary"
                  onClick={handleFinishClick}
                  style={{ marginBottom: "15px" }}
                >
                  Abteilungswechsel zunächst abschließen
                </Button>
                <Button onClick={handleRetryClick}>
                  Fehler erneut versuchen
                </Button>
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default ChangeDepartmentProgress;
