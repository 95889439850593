import Keycloak from "keycloak-js";
import api from "../utils/useAxios";
export const authURL = "https://auth.einsnulleins.de/";

const keycloakInstance = new Keycloak({
  url: authURL,
  realm: "einsnulleins-hub",
  clientId: "einsnulleins-hub-frontend",
});

const initKeycloak = (onAuthenticatedCallback) => {
  keycloakInstance
    .init({
      checkLoginIframe: false,
      onLoad: "check-sso",
    })
    .then((authenticated) => {
      if (!authenticated) {
        keycloakInstance.login();
      }
      onAuthenticatedCallback();
    })
    .catch((error) => console.log("Error initializing Keycloak: ", error));
};

const doLogin = keycloakInstance.login;

const getParsedToken = () => keycloakInstance.tokenParsed;

const getToken = () => keycloakInstance.token;

const isLoggedIn = () => !!keycloakInstance.token;

const doLogout = () => keycloakInstance.logout();

const updateToken = (successCallback) =>
  keycloakInstance.updateToken(5).then(successCallback).catch(doLogin);
const getLoginUrl = () => keycloakInstance.createLoginUrl();
const getLogoutUrl = () => keycloakInstance.createLogoutUrl();
const getAccountUrl = () => keycloakInstance.createAccountUrl();
const getUserProfile = () => keycloakInstance.loadUserProfile();
const expireSession = () => keycloakInstance.clearToken();
const onTokenRefresh = () => keycloakInstance.onAuthRefreshSuccess;

const AuthenticationService = {
  initKeycloak,
  doLogin,
  isLoggedIn,
  getParsedToken,
  getToken,
  updateToken,
  getLoginUrl,
  getLogoutUrl,
  getAccountUrl,
  getUserProfile,
  doLogout,
  expireSession,
  onTokenRefresh,
  keycloakInstance,
};

export default AuthenticationService;
