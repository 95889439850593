import React from "react";
import { useState, useEffect } from "react";
import { Button, Steps, Divider, Typography } from "antd";
import RetrieveFormData from "../components/RetrieveFormData";
import { useProvisioningData } from "../store/context";
import DeprovisionUserForm from "../components/DeprovisionUserForm";
import UserDeprovisioning from "../components/UserDeprovisioning";

const DeprovisionUserPage = () => {
  const { Title } = Typography;
  const { ProvisioningData } = useProvisioningData(null);
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: "Formulardaten abrufen",
      content: (
        <div className="container-border" style={{width:"20%"}}>
          <RetrieveFormData formType="deprovisioning" />
        </div>
      ),
    },
    {
      title: "Daten überprüfen",
      content: <DeprovisionUserForm />,
    },
    {
      title: "Benutzer ausgliedern",
      content: <UserDeprovisioning />,
    },
  ];
  useEffect(() => {
  
      if (!ProvisioningData || ProvisioningData.step === 1) {
        setCurrent(0);
      } else if (ProvisioningData.step === 2 && ProvisioningData.action === "deprovisioning") {
        setCurrent(1);
      } else if (ProvisioningData.step === 3 && ProvisioningData.action === "deprovisioning") {
        setCurrent(2);
      }
      
    
  }, [ProvisioningData]);

  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    marginTop: 16,
  };
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Title level={2}>Benutzerausgliederung</Title>
      </div>
      <Divider />
    
      {current > 0 &&
        (ProvisioningData.status !== "running" || ProvisioningData.status !== "finished" ) && (
          <>
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Zurück
            </Button>
          </>
        )}

      {current > 0 && <Divider />}
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      ></div>
    </>
  );
};

export default DeprovisionUserPage;
