import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { FaTools } from "react-icons/fa";
import "../App.css";
const DummyPage = () => {
  const navigate = useNavigate();
  return (
    <Result
      title="An dieser Seite wird noch gearbeitet!"
      icon={<FaTools style={{ fontSize: "4rem" }} />}
      extra={
        <Button
          type="primary"
          onClick={() => navigate("/")}
          className="btn-primary"
        >
          Zurück zur Homepage
        </Button>
      }
    />
  );
};

export default DummyPage;
