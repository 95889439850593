export const globalStyles = {
  card: {
    body: {
      backgroundColor: "#F5F5F5",
    },
    general: {
      margin: 10,
    },
    grid: {
      width: "50%",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      cursor: "pointer",
      background: "#F5F5F5",
    },
    icon: {
      color: "#4ab8a8",
    },
    iconLarge: {
      color: "#4ab8a8",
      fontSize: "25px",
    },

    antdIcon: {
      color: "#4ab8a8",
      fontSize: "20px",
    },
    header: {
      background: "#4ab8a863",
    },
    span: {
      marginTop: "8px",
      padding: 10,
    },
  },
  menu: {
    iconstyleChildItem: {
      fontSize: 18,
    },
    iconstyleParentItem: {
      fontSize: 20,
    },
  },
};
