import React from "react";
import "../App.css";
import { Carousel, Card, Typography } from "antd";
const { Text, Title } = Typography;

const NewHomePage = () => {
  const contentStyle = {
    height: "250px",
    color: "#fff",
    lineHeight: "250px",
    textAlign: "center",
    background: "#669ca3ff",
    margin: "0 8px",
    borderRadius: 4,
  };
  return (
    <div>
      <div className="home-header">
        <Title level={1} style={{ marginTop: -5 }}>
          Herzlich Willkommen im einsnulleins Hub
        </Title>
        <Title level={4} style={{ marginTop: -10 }}>
          Das zentrale Tool des einsnulleins Franchise für Administration,
          Verwaltung, Vertrieb und vieles mehr
        </Title>
      </div>
      <Card
        title="Implementierte und geplante Features"
        className="home-card"
        styles={{
          header: {
            background: "#b9e4dfff",
          },
        }}
      >
        <Carousel
          autoplay
          autoplaySpeed={4000}
          speed={1500}
          style={{ borderRadius: 10 }}
        >
          <div>
            <h2 style={contentStyle}>
              Automatisierte Ein- und Ausgliederungen für Mitarbeiter
              durchführen
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Zentrale Zuweisung von M365 Gruppen, Postfächern und Keeper-Teams
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Lizenzkosten aller einsnulleins Standorte einsehen
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Automatisches Updaten von Lizenzkosten pro Standort
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Automatische Prüfung IT.Läuft. Verträge
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Monatliche Meldung aktueller Franchise-Partner an einsnulleins
              Lieferanten
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Software-Accounts von Mitarbeitern prüfen
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>Microsoft Power BI Dasboards</h2>
          </div>
          <div>
            <h2 style={contentStyle}>Administration von Microsoft M365</h2>
          </div>
          <div>
            <h2 style={contentStyle}>Verwaltung von Keeper MSP</h2>
          </div>
          <div>
            <h2 style={contentStyle}>Mandantenfähigkeit</h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Geplant: Einsehen von offenen Pipedrive-Deals
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Geplant: Pipedrive-Besuchsberichte schreiben
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Geplant: Offene Autotask-Tickets einsehen
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>Geplant: Autotask-Ticket erstellen</h2>
          </div>
          <div>
            <h2 style={contentStyle}>Geplant: Managed-Service Kalkulator</h2>
          </div>

          <div>
            <h2 style={contentStyle}>
              Geplant: Offene Rechnungen & Angebote aus SAP
            </h2>
          </div>
        </Carousel>
      </Card>
    </div>
  );
};

export default NewHomePage;
