import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/useAxios";
import "../App.css";
import {
  Button,
  Table,
  Space,
  Form,
  Spin,
  message,
  Select,
  Typography,
  DatePicker,
  Popconfirm,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { useAuthContext } from "../auth/AuthContext";
import { useLocation } from "react-router-dom";
import { labels } from "../static/labels";
import api from "../utils/useAxios";
import locale from "antd/es/date-picker/locale/de_DE";
import dayjs from "dayjs";
const { Text, Title } = Typography;

const CompanyLicenses = () => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [subRows, setSubRows] = useState([]); // [
  const [company, setCompany] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [filters, setFilters] = useState([]);
  const [retrievingData, setRetrievingData] = useState(false);
  const [CSVData, setCSVData] = useState({
    headers: [],
    data: [],
    filename: "",
  });
  const { currentAccount, currentTenant } = useAuthContext();
  const [companies, setCompanies] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scope = searchParams.get("scope");

  const determineCompanies = () => {
    if (scope === "customers") {
      setCompanies(currentTenant.managed_companies);
      setCompany(null);
    } else if (scope === "msps") {
      setCompanies(
        currentTenant.managed_msps.filter(
          (msp) => msp.id !== currentTenant.own_company.id
        )
      );
      setCompany(null);
    } else {
      setCompany(currentTenant.own_company);
    }
  };

  const getData = async () => {
    if (!company || !selectedDate) {
      return;
    }
    setRetrievingData(true);
    try {
      let response = await api.get("companies/licenses/reports", {
        params: {
          company: company.id,
          date: selectedDate.format("YYYY-MM-DD"),
        },
      });
      let data = await response.data;
      setRows(data);
    } catch (error) {
      console.error(error);
    }
    setRetrievingData(false);
  };

  const handleCompanyChange = (value) => {
    const selectedCompany = companies.find((company) => company.id === value);
    setCompany(selectedCompany);
  };
  useEffect(() => {
    if (currentTenant) {
      determineCompanies();
      setSelectedDate(dayjs());
      setRows([]);
    }
  }, [currentTenant, scope]);

  useEffect(() => {
    if (company && selectedDate) {
      getData();
    }
  }, [company, selectedDate]);
  const columns = [
    {
      title: "Lizenz",
      dataIndex: ["license", "name"],
      filters: filters,
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.license.name.startsWith(value),
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.license.name.localeCompare(b.license.name),
    },
    {
      title: "Anzahl",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Einzelkosten",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return parseFloat(value).toFixed(2).replace(".", ",") + " €";
      },
    },
    {
      title: "Gesamtkosten",
      dataIndex: "total_price",
      sorter: (a, b) => a.total_price - b.total_price,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return parseFloat(value).toFixed(2).replace(".", ",") + " €";
      },
    },
  ];

  const onDateChange = (date, dateString) => {
    setSelectedDate(date);
  };
  return (
    <>
      <div className="table">
        <Table
          columns={columns}
          dataSource={rows}
          bordered
          scroll={{
            y: 600,
          }}
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title
                level={3}
                style={{
                  marginTop: -10,
                  marginBottom: 10,
                }}
              >
                {labels.licenses.fees[scope].title}
              </Title>
              <Form
                size="large"
                style={{
                  margin: 10,
                }}
              >
                <Form.Item>
                  <Space>
                    {scope !== "own" && (
                      <Select
                        autoFocus={true}
                        showSearch
                        placeholder={labels.licenses.fees[scope].placeholder}
                        onChange={handleCompanyChange}
                        optionFilterProp="children"
                        style={{
                          width: 320,
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={companies}
                      />
                    )}

                    {contextHolder}
                    <DatePicker
                      onChange={onDateChange}
                      picker="month"
                      locale={locale}
                      format={"MMMM YYYY"}
                      style={{ width: 200 }}
                      value={selectedDate}
                    />
                  </Space>
                </Form.Item>
              </Form>
            </div>
          )}
          pagination={false}
          loading={{
            spinning: retrievingData,
            indicator: <LoadingOutlined className="spinner-style" />,
          }}
          summary={(pageData) => {
            let totalPrice = 0;
            pageData.forEach(({ total_price }) => {
              totalPrice += parseFloat(total_price);
            });
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={3}>
                    <Text strong>TOTAL</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong>
                      {parseFloat(totalPrice).toFixed(2).replace(".", ",") +
                        " €"}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </div>
    </>
  );
};

export default CompanyLicenses;
