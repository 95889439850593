import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Result } from "antd";
import "../App.css";
import { useAuthContext } from "./AuthContext";

const RenderByRole = ({ authorizedRoles, children, childrender = false }) => {
  const [finished, setFinished] = useState(false);
  const { currentAccount } = useAuthContext();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (currentAccount) {
      if (authorizedRoles.length === 0) {
        return;
      }
      const authorized = currentAccount.roles.some((userPermission) =>
        authorizedRoles.includes(userPermission)
      );
      setIsAuthorized(authorized);
      setFinished(true);
    }
  }, [currentAccount, authorizedRoles]);

  if (!finished) {
    return null;
  } else if (isAuthorized) {
    return childrender ? children : <Outlet />;
  } else {
    return (
      <Result
        status="403"
        title="Kein Zugriff!"
        subTitle="Sorry, deine Berechtigungen reichen nicht aus, um diese Seite zu sehen."
      />
    );
  }
};

export default RenderByRole;
