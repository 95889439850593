import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../App.css";
import { useAuthContext } from "../auth/AuthContext";
import { useLocation } from "react-router-dom";
import {
  Form,
  message,
  Space,
  Select,
  DatePicker,
  Collapse,
  Modal,
  Result,
  Button,
  Typography,
} from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { labels } from "../static/labels";
import { Empty } from "antd";
import locale from "antd/es/date-picker/locale/de_DE";
import api from "../utils/useAxios";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LinkOffIcon from "@mui/icons-material/LinkOff";
const { Title } = Typography;
const PowerBI = () => {
  const { PowerBiReports, msalInstance, powerBiToken, setPowerBiToken } =
    useAuthContext();
  const [messageApi, contextHolder] = message.useMessage();
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [myForm] = Form.useForm();
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedType, setSelectedType] = useState();
  const [reportTypes, setReportTypes] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [PowerBIAuthError, setPowerBIAuthError] = useState(false);
  const [openPopUpModal, setOpenPopUpModal] = useState(false);

  const getReports = async () => {
    try {
      const response = await api.get("powerbi/reports");
      const data = await response.data;
      setReports(data);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
  };
  const getReportTypes = async () => {
    try {
      const response = await api.get("powerbi/reports/types");
      const data = await response.data;
      const values = data.map((row) => {
        return { ...row, key: row.id };
      });
      if (values.length === 1) {
        setSelectedType(values[0]?.id);
        myForm.setFieldsValue({
          type: values[0].id,
        });
      }
      setReportTypes(values);
    } catch (error) {
      console.error(error);
      messageApi.error("Fehler beim Laden der PowerBI Reports");
    }
  };

  const filterReports = () => {
    if (!selectedType || !selectedDate) return;
    const filteredReports = reports.filter(
      (report) =>
        report.type?.id === selectedType &&
        (report.date ? dayjs(report.date).year() === selectedDate.year() : true)
    );
    setFilteredReports(filteredReports);
  };

  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error("Failed to decode token:", error);
      return true;
    }
  };

  useEffect(() => {
    filterReports();
  }, [selectedType, selectedDate]);

  const checkToken = async () => {
    if (!powerBiToken) {
      const savedToken = localStorage.getItem("powerBiToken");
      if (savedToken) {
        if (isTokenExpired(savedToken)) {
          getPowerBiToken();
        } else {
          setPowerBiToken(savedToken);
        }
      } else {
        getPowerBiToken();
      }
    } else {
      if (isTokenExpired(powerBiToken)) {
        getPowerBiToken();
      }
    }
  };

  useEffect(() => {
    getReportTypes();
    if (reports.length === 0) {
      getReports();
    }
  }, []);

  const savePowerBiTokenToLocalStorage = (token) => {
    localStorage.setItem("powerBiToken", token);
  };

  const doMicrosoftLogin = async () => {
    try {
      const accessTokenRequest = {
        scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
      };
      msalInstance
        .loginPopup(accessTokenRequest)
        .then((accessTokenResponse) => {
          setPowerBiToken(accessTokenResponse.accessToken);
          setPowerBIAuthError(false);
          savePowerBiTokenToLocalStorage(accessTokenResponse.accessToken);
        })
        .catch((error) => {
          console.log("Error authenticating against Power BI", error);
          if (error.errorMessage) {
            messageApi.error(
              "Das hat leider nicht funktioniert. Bitte versuche es erneut oder kontaktiere den Support."
            );
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const getPowerBiToken = async () => {
    const accessTokenRequest = {
      scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
    };
    try {
      msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          setPowerBIAuthError(false);
          setPowerBiToken(accessTokenResponse.accessToken);
          savePowerBiTokenToLocalStorage(accessTokenResponse.accessToken);
        })
        .catch((error) => {
          setPowerBIAuthError(true);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const onReportChange = (value, record) => {
    checkToken();
    setSelectedReport(record);
  };

  const onReportTypeChange = (value) => {
    myForm.resetFields(["reportID"]);
    setSelectedType(value);
  };
  const onDateChange = (date, dateString) => {
    myForm.resetFields(["reportID"]);
    setSelectedDate(date);
  };
  const collapseItems = [
    {
      key: "1",
      label: "Auswahl",
      children: (
        <Form
          size="large"
          form={myForm}
          id="myForm"
          layout="vertical"
          style={{ margin: 15 }}
          initialValues={{ date: selectedDate, reportID: null }}
        >
          <Form.Item name="type" label="Kategorie">
            <Select
              disabled={reportTypes?.length === 1}
              autoFocus={true}
              placeholder={labels.controlling.powerBI.reportTypeSelect}
              onChange={onReportTypeChange}
              style={{
                width: 320,
              }}
              options={reportTypes}
            />
          </Form.Item>
          <Form.Item name="date" label="Zeitraum">
            <DatePicker
              picker="year"
              placeholder={labels.controlling.powerBI.dateSelect}
              locale={locale}
              format={"YYYY"}
              onChange={onDateChange}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item name="reportID" label="Dashboard">
            <Select
              autoFocus={true}
              showSearch
              disabled={!selectedType || !selectedDate}
              placeholder={labels.controlling.powerBI.reportSelect}
              optionFilterProp="children"
              onChange={onReportChange}
              style={{
                width: 320,
              }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={filteredReports}
            />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={() => setOpenModal(true)}
              className="btn-primary"
              style={{ width: "100%" }}
              icon={<HelpOutlineOutlinedIcon />}
              iconPosition="end"
              disabled={selectedReport && selectedDate ? false : true}
            >
              Informationen zum Dashboard
            </Button>
          </Form.Item>
        </Form>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        {contextHolder}
        <Collapse
          defaultActiveKey={["1"]}
          style={{ flex: "0 0 auto", marginRight: 10 }}
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? <DoubleLeftOutlined /> : <DoubleRightOutlined />
          }
          items={collapseItems}
        ></Collapse>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            border: "1px solid #d9d9d9",
            borderRadius: 5,
          }}
        >
          {selectedReport && powerBiToken && !PowerBIAuthError ? (
            <PowerBIEmbed
              key={selectedReport?.report_id}
              embedConfig={{
                type: "report",
                id: selectedReport?.report_id,
                embedUrl: PowerBiReports[selectedReport],
                accessToken: powerBiToken,
                tokenType: models.TokenType.Aad,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                },
              }}
              cssClassName="power_bi_container"
            />
          ) : PowerBIAuthError ? (
            <Result
              icon={<LinkOffIcon style={{ color: "#f5222d", fontSize: 75 }} />}
              title="Kein Microsoft Account verknüpft"
              style={{ height: "100%" }}
              subTitle={
                <div
                  style={{
                    display: "flex", // Use flexbox for centering
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "70%",
                    margin: "0 auto", // Center the container itself
                  }}
                >
                  <p>
                    Der Zugriff auf Power BI erfolgt über deinen Microsoft
                    Account.
                  </p>
                  <p>
                    Durch den Klick auf den unteren Button öffnet sich ein
                    Pop-Up Fenster. In der Regel wird automatisch dein Microsoft
                    Account ausgewählt, mit dem du dich im einsnulleins Hub
                    authentifiziert hast und das Pop-Up schließt sich innerhalb
                    weniger Sekunden wieder. <br />
                    Sollte dies nicht der Fall sein, wähle bitte den Account
                    aus, mit dem du auf Power BI zugreifen möchtest und
                    bestätige die Auswahl.
                    <br />{" "}
                    <strong>
                      Bitte beachte, dass der ausgewählte Account mindestens
                      eine Power BI Pro Lizenz zugewiesen und von der Zentrale
                      auf die Dashboards freigegegeben sein muss.
                    </strong>
                  </p>
                  <p>
                    Diesen Vorgang solltest du nur einmalig durchführen müssen,
                    in Zukunft wird der Zugriff automatisch erfolgen, solange
                    eine gültige Microsoft-Session in deinem Browser besteht.
                  </p>
                </div>
              }
              extra={[
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  key="login"
                >
                  <Button onClick={doMicrosoftLogin} className="btn-primary">
                    Anmelden
                  </Button>
                  <a
                    onClick={() => setOpenPopUpModal(true)}
                    style={{ marginTop: 10 }}
                  >
                    Es öffnet sich kein Pop-Up?
                  </a>
                </div>,
                ,
              ]}
            />
          ) : (
            <Empty description="Bitte wähle einen Bericht aus" />
          )}
        </div>
      </div>
      <Modal
        title={<Title level={3}>{selectedReport?.name}</Title>}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        width="auto"
        style={{ maxWidth: "50%" }}
        footer={[
          <Button
            key="back"
            onClick={() => setOpenModal(false)}
            className="btn-primary"
          >
            Schließen
          </Button>,
        ]}
      >
        <p style={{ whiteSpace: "pre-wrap", fontSize: 18 }}>
          {selectedReport?.description}
        </p>
      </Modal>
      <Modal
        title="Pop-Ups zulassen"
        open={openPopUpModal}
        onCancel={() => setOpenPopUpModal(false)}
        width="auto"
        style={{ maxWidth: "35%" }}
        footer={[
          <Button
            key="back"
            onClick={() => setOpenPopUpModal(false)}
            className="btn-primary"
          >
            Schließen
          </Button>,
        ]}
      >
        <div>
          <p>
            Sollte sich kein Pop-Up öffnen, überprüfe bitte, ob dein Browser
            Pop-Ups blockiert. <br />
            Bitte lasse Pop-Ups für die Seite "https://hub.einsnulleins.de" zu
            und versuche es erneut. <br /> Hier sind Anleitungen für einige
            gängige Browser:
          </p>
          <ul>
            <li>
              <a
                href="https://support.google.com/chrome/answer/95472?hl=de&co=GENIE.Platform%3DDesktop"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Chrome
              </a>
            </li>
            <li>
              <a
                href="https://support.mozilla.org/de/kb/pop-blocker-einstellungen-ausnahmen-problemloesung"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mozilla Firefox
              </a>
            </li>
            <li>
              <a
                href="https://support.microsoft.com/de-de/microsoft-edge/blockieren-von-popups-in-microsoft-edge-1d8ba4f8-f385-9a0b-e944-aa47339b6bb5"
                target="_blank"
                rel="noopener noreferrer"
              >
                Microsoft Edge
              </a>
            </li>
            <li>
              <a
                href="https://support.brave.com/hc/en-us/articles/360018205431-How-do-I-change-site-permissions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Brave
              </a>
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default PowerBI;
