import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import api from "../utils/useAxios";
import { useProvisioningData } from "../store/context";
import moment from "moment";

const RetrieveFormData = ({ formType }) => {
  const { setProvisioningData } = useProvisioningData();
  const [retrievingData, setRetrievingData] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [formData, setFormData] = useState({
    basic_formID: "",
    basic_ticketnumber: "",
  });
  const displayLoadingMessage = () => {
    messageApi.open({
      type: "loading",
      content: "Rufe Daten ab...",
      duration: 0,
    });
  };
  const displayErrorMessage = (message) => {
    messageApi.open({
      type: "error",
      content: message,
      duration: 2,
    });
  };
  useEffect(() => {
    if (retrievingData === true) {
      displayLoadingMessage();
    }
  }, [retrievingData]);

  const onFinish = async (e) => {
    setRetrievingData(true);
    try {
      let response = await api.get("formData", {
        params: {
          ticketnumber: formData.basic_ticketnumber,
          formType: formType,
        },
      });

      if (response.status === 200) {
        const employeeAttributes = await response.data;
        let formattedEmployeeAttributes = null;
        if (formType === "provisioning") {
          let emp_date = employeeAttributes.employment_date;
          let formatted_emp_date = moment(
            emp_date.replace(/-/g, "/"),
            "YYYY/MM/DD"
          );

          formattedEmployeeAttributes = {
            ...employeeAttributes,
            employment_date: formatted_emp_date,
          };
        } else {
          formattedEmployeeAttributes = employeeAttributes;
        }
        setProvisioningData({
          employeeAttributes: employeeAttributes,
          step: 2,
          status: "dataentry",
          ticketnumber: formData.basic_ticketnumber,
          action: formType,
        });
        setRetrievingData(false);
      }
    } catch (error) {
      messageApi.destroy();
      displayErrorMessage(error.response.data);
      setRetrievingData(false);
      return;
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  return (
    <Form
      name="basic"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >

      {contextHolder}
      <Form.Item
        label="Ticketnummer"
        name="ticketnumber" // Set the name attribute for the second input
        onChange={onChange}
        rules={[
          {
            required: true,
            message: "Bitte gib die Ticketnummer ein!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-primary">
          Daten abrufen
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RetrieveFormData;
